import { Button, Form, Input, Select, Spin, theme, Typography, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { supportAction } from "../State/actions";
import { UploadOutlined } from "@ant-design/icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BookingCard from "./BookingCard";
import { v4 as uuidv4 } from "uuid";
import { settings } from "../utils/Arrows";
import Spinner from "../../Spinner/Spinner";
import useWarnings from "../../Common/Components/useWarnings";
const { TextArea } = Input;
const { Title } = Typography;

/**
 * SupportForm component for creating support tickets.
 * This component allows users to select a support reason, provide additional details, select a booking for which they need support, and upload a file if needed.
 * 
 * @description
 * - **Title**: Displays a title for the form.
 * - **Support Reason**: A dropdown to select the reason for support, with an additional text area if "Other" is selected.
 * - **Description**: A text area for describing the support issue.
 * - **Booking Selection**: A slider component displaying booking cards where users can select the relevant booking for support.
 * - **File Upload**: An upload button allowing users to attach a file (only .jpg and .png allowed).
 * - **Submit Button**: A button to submit the form.
 */

const SupportForm = ({ setVisible }) => {
    const dispatch = useDispatch();
    const [selectedSupportReason, setSelectedSupportReason] = useState(null);
    const { success, error, contextHolder } = useWarnings()

    const [form] = Form.useForm();
    const { allBookingsForUserSupport, createTicket, createTicketErr } = useSelector(
        (str) => str.supportReducer
    );
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    const { isLoadingBookingsSupport } = useSelector((str) => str.loadingReducer);
    const handleCardClick = (bookingId) => {
        setSelectedBookingId(bookingId);
        form.setFieldsValue({ bookingId });
    };

    const onFinish = (values) => {
        const { supportReason, ...formData } = values;
        let dataTobeSent = {};
        if (supportReason === "Other") {
            const { otherIssue, ...data } = formData;
            dataTobeSent = { ...data, supportReason: otherIssue }
        } else {
            dataTobeSent = values;
        }
        dispatch(supportAction.createTicketEvent(dataTobeSent));
        // setVisible(false);
    };
    
    const allbookings =
        allBookingsForUserSupport &&
        allBookingsForUserSupport.bookings.concat(
            allBookingsForUserSupport.historyBookings
        );
    const supportOptions = [
        {
            value: 'Payment related issue',
            label: 'Payment related issue',
        },
        {
            value: 'Booking issue',
            label: 'Booking issue',
        },
        {
            value: 'Other',
            label: 'Other',
        },
    ];
    return (
        <>
            {contextHolder}
            <div>
                <Title style={{ fontSize: "1.2em", textAlign: "center" }}>
                    Get in touch
                </Title>
            </div>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item label="supportReason" name={"supportReason"} rules={[{ required: true }]}>
                    <Select options={supportOptions} onChange={(value) => setSelectedSupportReason(value)} />
                </Form.Item>
                {selectedSupportReason === 'Other' && (
                    <Form.Item
                        label="Please specify the issue"
                        name="otherIssue"
                        rules={[{ required: true, message: 'Please specify the issue' }]}
                    >
                        <TextArea rows={1} />
                    </Form.Item>
                )}
                <Form.Item
                    label="Description"
                    name={"description"}
                    rules={[{ required: true }]}
                >
                    <TextArea rows={3} />
                </Form.Item>
                <Form.Item
                    label="Please choose the order you need help with."
                    name={"bookingId"}
                    rules={[{ required: true }]}
                >
                    <Slider {...settings}>
                        {allBookingsForUserSupport &&
                            allbookings?.map((booking) => (
                                <BookingCard
                                    key={uuidv4()}
                                    booking={booking}
                                    isSelected={selectedBookingId === booking.bookingUniqueId}
                                    handleCardClick={handleCardClick}
                                />
                            ))}
                    </Slider>
                </Form.Item>
                <Form.Item name={"file"} extra="Only .jpg, .png are allowed (max 1)">
                    <Upload maxCount={1}>
                        <Button size="small" icon={<UploadOutlined />}>
                            Click to Upload
                        </Button>
                    </Upload>
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                    <Button htmlType="submit" type="primary" size="small" loading={isLoadingBookingsSupport}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default SupportForm;
