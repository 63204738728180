import { Collapse, Row } from "antd";
import React, { useEffect } from "react";
import CreatePlanForm from "./Components/CreatePlanForm";
import { CaretRightOutlined } from "@ant-design/icons";
import CreateStateForm from "./Components/CreateStateForm";
import CreateCityForm from "./Components/CreateCityForm";
import { useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import { useRoute } from "../context/RouteContext";

const CrudPlans = () => {
  const { isLoadingUserDetails } = useSelector(str => str.loadingReducer);
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const { setSelectedKey } = useRoute();

  const items = [
    {
      key: "1",
      label: "Create a plan",
      children: <CreatePlanForm />,
    },
    // userDetails && (userDetails?.currentRole === "SUPER_ADMIN") &&
    // {
    //   key: "2",
    //   label: "Create a State",
    //   children: <CreateStateForm />
    // },
    // userDetails && (userDetails?.currentRole === "STATE_ADMIN" || userDetails?.currentRole === "SUPER_ADMIN") &&
    // {
    //   key: "3",
    //   label: "Create a city",
    //   children: <CreateCityForm />
    // },
  ].filter(Boolean);

  useEffect(() => {
    setSelectedKey('CREATE_PLAN');
  }, []);

  if (isLoadingUserDetails) return <Spinner />

  return (
    // <Row>
    //   <Collapse
    //     size="small"
    //     expandIcon={({ isActive }) => (
    //       <CaretRightOutlined rotate={isActive ? 90 : 0} />
    //     )}
    //     expandIconPosition="end"
    //     style={{ width: "100%" }}
    //     items={items}
    //     defaultActiveKey={"1"}
    //   />
    // </Row>
    <CreatePlanForm />
  );
};

export { CrudPlans };
