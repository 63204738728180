import { Button, Col, Form, Input, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useRoute } from "../../context/RouteContext";
import useWarnings from "../../Common/Components/useWarnings";
import { usersApi } from "../utils/api";
import { useNavigate } from "react-router-dom";
import CommonModal from "../../CommonModal/CommonModal";
const { Text } = Typography;

const CreateUser = () => {
  const [form] = Form.useForm();
  // const [sendLink, setSendLink] = useState(false);
  const { error, success, contextHolder } = useWarnings();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [visible, setVisible] = useState({
    modal: false,
    info: {},
  });

  const onFinish = async (formvalues) => {
    const { phoneNumber, ...formData } = formvalues;

    let signupdata = { ...formData };

    if (phoneNumber && phoneNumber.trim() !== "") {
      const countryCode = phoneNumber.substring(0, phoneNumber.length - 10);
      const formattedPhoneNumber = phoneNumber.substring(
        phoneNumber.length - 10
      );
      signupdata = {
        ...signupdata,
        countryCode,
        phoneNumber: formattedPhoneNumber,
        verification_required: false,
      };
    }
    setLoading(true);
    try {
      const response = await usersApi.createUser(signupdata);
      if (response.data?.info) {
        setVisible({ info: response.data.info, modal: true });
        success("Created user successfully");
        form.resetFields();
        // navigate("/users");
      }
      setLoading(false);
    } catch (err) {
      error(err?.response?.data?.message);
      setLoading(false);
    }
  };

  const { setSelectedKey } = useRoute();
  const validatePhoneNumber = (_, value) => {
    if (!value) {
      return Promise.reject("Please input your phone number");
    }

    // Remove the country code and any non-digit characters
    const phoneNumber = value.slice(2).replace(/\D/g, "");

    if (phoneNumber.length !== 10) {
      return Promise.reject("Phone number must be exactly 10 digits");
    }

    return Promise.resolve();
  };

  useEffect(() => {
    setSelectedKey("GET_ALL_USERS");
  }, []);

  return (
    <Row style={{ alignItems: "center", height: "100%" }}>
      {contextHolder}
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 16, offset: 4 }}
        lg={{ span: 12, offset: 6 }}
        xl={{ span: 10, offset: 7 }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            required
            name={"firstName"}
            rules={[
              {
                required: true,
                message: "Please input firstname!",
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            required
            name={"lastName"}
            rules={[
              {
                required: true,
                message: "Please input your lastname!",
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name={"email"}
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[
              {
                validator: validatePhoneNumber,
              },
            ]}
          >
            <PhoneInput
              countryCodeEditable={false}
              country="in"
              onlyCountries={["in"]}
              inputStyle={{
                width: "100%",
                padding: "8px 11px 8px 48px",
                border: "0.5px solid rgba(0, 0, 0, 0.30)",
                height: "45.45px",
                borderRadius: "2px",
              }}
              buttonStyle={{
                border: "0.5px solid rgba(0, 0, 0, 0.30)",
              }}
            />
          </Form.Item>
          {/* <Form.Item>
                        <Checkbox
                            checked={sendLink}
                            onChange={(e) => setSendLink(e.target.checked)}
                        >
                            Send Verification link to the user
                        </Checkbox>
                    </Form.Item> */}
          <Form.Item>
            <Button
              htmlType="submit"
              style={{
                width: "100%",
                height: "45px",
                backgroundColor: "#3A60A1",
                color: "white",
                fontSize: "18px",
              }}
              loading={loading}
            >
              Create a user
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <CommonModal
        body={() => (
          <>
            <Text style={{ display: "block" }}>
              Username : {visible.info.userName}
            </Text>
            <Text style={{ display: "block" }}>
              Passcode : {visible.info.codeWord}
            </Text>
          </>
        )}
        title={
          <Text
            copyable={{
              text: `UserName: ${visible.info.userName}, Passcode:${visible.info.codeWord} `,
            }}
          >
            User Details
          </Text>
        }
        height={"100px"}
        handleCancel={() => {
          setVisible({ modal: false, info: {} });
          navigate("/users");
        }}
        isModalOpen={visible.modal}
      />
    </Row>
  );
};

export default CreateUser;
