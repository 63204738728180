// import React, { useRef, useState } from "react";
// import { Modal, Space, Select, Input, Tag, Typography, theme, Button, Popconfirm } from "antd";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { services } from "../../Common/Components/Services";
// import useAcceptDecline from "./useAcceptDecline";
// import KennelsScreen from "./KennelsScreen";
// import CommonModal from "../../CommonModal/CommonModal";
// import VerificationStepsForAdmin from "./VerificationStepsForAdmin";
// import { useDispatch, useSelector } from "react-redux";
// import { adminAction } from "../state/actions";
// import { FilterFilled } from "@ant-design/icons";
// import { useSocket } from "../../context/SocketContext";
// import Tracking from "../../Common/Components/Tracking";
// import useFiltering from "../../Common/Components/useFiltering";

// const { Text, Paragraph } = Typography;

// const useColumns = () => {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const { userDetails } = useSelector(str => str.navbarReducer);
//     const { useToken } = theme;
//     const { token } = useToken();
//     const { currentSocket } = useSocket();
//     const bookingIdToTrack = useRef(null);
//     const { type } = useParams();
//     const [modalVisible, setModalVisible] = useState(false);
//     const { getColumnSearchProps, setFiltered, filtered } = useFiltering(
//         search => dispatch(adminAction.getConfirmedBookingDetails(userDetails.boardCenterUniqueId, {
//             boardCenterId: userDetails.boardingCenter, "page": 1, "status": type, search
//         }))
//     );
//     const showModalForTrack = (bookingId) => {
//         bookingIdToTrack.current = bookingId;
//         currentSocket.emit("booking_connected", {
//             bookingUniqueId: bookingId,
//         });
//         setModalVisible(true);
//     }
//     const hideModalForTrack = () => {
//         setModalVisible(false);
//     }

//     const {
//         declineBooking,
//         handleOk,
//         handleCancel,
//         handleAccept,
//         setDeclineBooking,
//         handleSelectChange,
//         showModal,
//         isModalOpen,
//         contextHolder,
//         handleVerifyPet,
//         handleVerifyPetAssignKennel,
//         handleOkAssignKennel, handleCancelAssignKennel, isModalOpenAssignKennel,
//         kennel,
//         handleReceivedPet, showModalVerify,
//         isModalOpenVerify, userTobeVerifiedByAdmin,
//         setIsModalOpen,
//         typeofroute
//     } = useAcceptDecline();

//     const handleClearFilters = (key) => {
//         setFiltered({ id: false, details: false, services: false });
//         setIsModalOpen(false);
//         navigate(`/services/${key}`);
//         dispatch(adminAction.getConfirmedBookingDetails(userDetails.boardCenterUniqueId, { boardCenterId: userDetails.boardingCenter, "page": 1, "status": key }));
//     }

//     const generateColumns = (type) => [
//         {
//             title: "Booking Id",
//             dataIndex: "bookingUniqueId",
//             key: "bookingUniqueId",
//             width: 150,
//             align: "center",
//             fixed: "left",
//             ...getColumnSearchProps("id"),
//             render: (_, { bookingUniqueId }) => (
//                 <>
//                     <Text
//                         style={{ cursor: "pointer", textDecoration: "underline", color: token.appbluecolor }}
//                         onClick={() => navigate(`/userDetails/${bookingUniqueId}`)}
//                     >
//                         {bookingUniqueId}
//                     </Text>
//                 </>
//             ),
//         },
//         {
//             title: "Details",
//             dataIndex: "parentName",
//             key: "parentName",
//             width: 150,
//             align: "center",
//             ...getColumnSearchProps("details"),
//             render: (_, { contact, parentName }) => (
//                 <div>
//                     <Text style={{ display: "block" }}>{parentName}</Text>
//                     <Text style={{ display: "block" }}>{contact.replace(/^(\+)(\d{2})\s(\d{10})$/, "$1($2) ($3)")}</Text>
//                 </div>
//             ),
//         },
//         {
//             title: "Services",
//             dataIndex: "services",
//             key: "services",
//             width: 150,
//             align: "center",
//             filters: services.map(ser => (
//                 { text: ser.name, value: ser.key }
//             )),
//             filterMultiple: false,
//             filterIcon: () => (
//                 <FilterFilled style={{ color: filtered.services ? token.colorPrimary : token.Gray }} />
//             ),
//             render: (_, selectedServices) => (
//                 <>
//                     {selectedServices?.services?.map((service) => (
//                         <Tag key={service}>
//                             {services.find((e) => e.key === service).name}
//                         </Tag>
//                     ))}
//                 </>
//             ),
//         }
//     ]

//     const newAppointmentColumns = [
//         ...generateColumns("newAppointments"),
//         {
//             title: "Appointment Status",
//             dataIndex: "appointmentstatus",
//             key: "appointmentstatus",
//             align: "center",
//             width: 200,
//             render: (_, e) => {
//                 return (
//                     <Space size={"small"} direction="vertical" align="center">
//                         <Tag
//                             style={{
//                                 background: "rgb(90, 123, 203)",
//                                 color: token.White,
//                                 cursor: "pointer",
//                             }}
//                             onClick={() => handleAccept(e)}
//                         >
//                             Accept & Assign
//                         </Tag>
//                         <>
//                             <Tag
//                                 style={{
//                                     background: "grey",
//                                     color: token.White,
//                                     cursor: "pointer",
//                                 }}
//                                 onClick={() => showModal(e)}
//                             >
//                                 Decline
//                             </Tag>
//                             <Modal
//                                 title="Decline reason"
//                                 open={isModalOpen}
//                                 onOk={handleOk}
//                                 onCancel={handleCancel}
//                                 centered
//                             >

//                                 <Select
//                                     defaultValue="Select your reason"
//                                     style={{
//                                         width: "90%",
//                                     }}
//                                     onChange={handleSelectChange}
//                                     options={[
//                                         {
//                                             value: "Select your reason",
//                                             label: "Select your reason",
//                                         },
//                                         {
//                                             value: "Boarding center closed for the day",
//                                             label: "Boarding center closed for the day",
//                                         },
//                                         { value: "other", label: "other" },
//                                     ]}
//                                 />
//                                 <Input
//                                     placeholder="Specify your decline reason"
//                                     value={declineBooking.customReason}
//                                     onChange={(e) =>
//                                         setDeclineBooking({
//                                             ...declineBooking,
//                                             customReason: e.target.value,
//                                         })
//                                     }
//                                     style={{
//                                         marginTop: "1em",
//                                         width: "90%",
//                                         display:
//                                             declineBooking.declineReason === "other"
//                                                 ? "initial"
//                                                 : "none",
//                                     }}
//                                 />
//                             </Modal>
//                         </>
//                     </Space>
//                 );
//             },
//         },
//     ];

//     const declinedAppointmentColumns = [
//         ...generateColumns("declinedAppointments"),
//         {
//             title: "Reason",
//             dataIndex: "reason",
//             key: "reason",
//             width: 200,
//             align: "center",
//             render: (_, e) => (
//                 <Space align="center">
//                     <Paragraph style={{ color: token.Primary }}>
//                         {e.bookingStatus.petBoarding_status.booking_confirmation?.reason}
//                     </Paragraph>
//                 </Space>
//             )
//         },
//     ];

//     const onprocessAppointmentColumns = [
//         ...generateColumns("inProcessAppointments"),
//         {
//             title: "Appointment Status",
//             dataIndex: "appointmentstatus",
//             key: "appointmentstatus",
//             width: 200,
//             align: "center",
//             render: (_, { bookingId, bookingUniqueId, bookingStatus, userId, kennelId, captainId }) => {
//                 const { petPickupAndDrop_status, petBoarding_status } = bookingStatus;
//                 // console.log(bookingStatus?.petBoarding_status?.pet_picked_up_from_boarding_center, "bookingStatus?.petBoarding_status?.pet_picked_up_from_boarding_center")
//                 if (petPickupAndDrop_status?.initiated?.status) {
//                     const { boarding_center_to_parent_location_to_pickup_pet, initiated, boarding_center_to_parent_location_drop_pet } = petPickupAndDrop_status;
//                     if (boarding_center_to_parent_location_drop_pet) {
//                         let { on_the_way_to_parent_location, reached_parent_location, parent_verification_while_releasing_pet, handed_over_the_pet_to_the_parent } = boarding_center_to_parent_location_drop_pet;
//                         if (handed_over_the_pet_to_the_parent?.status === "accept") {
//                             return <Text>Pet handed over to parent by captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link></Text>
//                         } else if (handed_over_the_pet_to_the_parent?.status === "decline") {
//                             return <Text style={{ color: token.Red }}>Pet not handed over to parent by captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>.</Text>
//                         } else if (parent_verification_while_releasing_pet?.status === "accept") {
//                             return <Text>Done with Parent verification while releasing pet.</Text>
//                         } else if (parent_verification_while_releasing_pet?.status === "decline") {
//                             return <Text> Parent verification unsuccessful while releasing pet.</Text>
//                         } else if (reached_parent_location?.status == "accept") {
//                             return <Text>Captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> reached parent location to drop the pet.</Text>
//                         } else if (reached_parent_location?.status == "decline") {
//                             return <Text>Captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> has not reached parent location to drop the pet.</Text>
//                         } else if (on_the_way_to_parent_location?.status === "accept") {
//                             return <>
//                                 <Text style={{ display: "block" }}>Captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> started his ride to drop the pet.</Text>
//                                 <Button type="primary" size="small" onClick={() => showModalForTrack(bookingUniqueId)}>View on map</Button>
//                                 {bookingIdToTrack.current === bookingUniqueId && <CommonModal handleCancel={hideModalForTrack} isModalOpen={modalVisible} body={() => <Tracking showStatus={"showStatus"} bookingIdFromAdmin={bookingUniqueId} />} />}
//                             </>
//                         } else if (on_the_way_to_parent_location?.status === "decline") {
//                             return <Text>Captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> has not started his ride to drop the pet.</Text>
//                         }
//                     }
//                     else if (boarding_center_to_parent_location_to_pickup_pet) {
//                         let { accepts_ride, on_the_way_to_parent_location, reached_parent_location, order_verification, parent_verification, pet_verification_by_captain, pet_on_the_way_to_boarding_center, pet_reached_boarding_center, pet_handed_over_to_the_boarding_center } = boarding_center_to_parent_location_to_pickup_pet
//                         if (accepts_ride?.status === "decline") {
//                             return <Text style={{ color: token.Primary, cursor: "pointer" }} onClick={() => navigate(`/captains/${bookingUniqueId}`)}>Captain  <Link to={`captainsBookings/${captainId}`}>{captainId}</Link> has declined. Please assign another captain!</Text>
//                         } else if (accepts_ride?.status === "in_process") {
//                             return <Text>Captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> has been assigned. Awaiting for the response!</Text>
//                         } else if (accepts_ride?.status === "decline_request") {
//                             return <Text>Decline request has been raised by the captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> for this booking.Check now!</Text>
//                         } else if (accepts_ride?.status === "none") {
//                             return <Text style={{ color: token.appbluecolor, cursor: "pointer" }} onClick={() => navigate(`/captains/${bookingUniqueId}`)}>Board head has cancelled the captain <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>. Reassign now.</Text>
//                         } else if (accepts_ride?.status === "accept") {
//                             if (bookingStatus?.petBoarding_status?.pet_picked_up_from_boarding_center?.status === "accept") {
//                                 return <Text>Captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> picked up pet from boarding center.</Text>
//                             } else if (bookingStatus?.petBoarding_status?.pet_picked_up_from_boarding_center?.status === "decline") {
//                                 return <Text>Captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> did not pick up pet from boarding center.</Text>
//                             } else if (petBoarding_status?.pet_assigned_a_kennel?.status === "accept") {
//                                 return <>
//                                     <Text style={{ color: token.Green }}>Kennel {kennelId} has been assigned.</Text>
//                                     <Button type="link" onClick={() => dispatch(adminAction.removeKennelEvent(userDetails.boardCenterUniqueId, bookingUniqueId, { reason: "repair in kennel" }))}>Remove assigned kennel.</Button>
//                                     <Button type="link" onClick={() => dispatch(adminAction.releaseKennelEvent(bookingUniqueId))}>Release pet.</Button>
//                                 </>
//                             } else if (petBoarding_status?.pet_assigned_a_kennel?.status === "decline") {
//                                 return <Text style={{ color: token.Green }}>Kennel {kennelId} has not been assigned.</Text>
//                             } else if (petBoarding_status?.pet_assigned_a_kennel?.status === "none") {
//                                 return <>
//                                     <Text style={{ color: token.Red, cursor: "pointer" }} onClick={() => handleVerifyPetAssignKennel(bookingUniqueId)}>Kennel {kennelId} has been removed due to {petBoarding_status?.pet_assigned_a_kennel?.reason}. Reassign another kennel</Text>
//                                     {kennel.current === bookingUniqueId && <CommonModal body={() => <KennelsScreen handleOkAssignKennel={handleOkAssignKennel} bookingId={kennel.current} />} handleOk={handleOkAssignKennel} handleCancel={handleCancelAssignKennel} isModalOpen={isModalOpenAssignKennel} />}
//                                 </>
//                             } else if (petBoarding_status?.pet_verification_by_admin?.status === "accept") {
//                                 return <>
//                                     <Button type="link" onClick={() => handleVerifyPetAssignKennel(bookingUniqueId)}>Verified pet. Please assign a kennel.</Button>
//                                     {kennel.current === bookingUniqueId && <CommonModal body={() => <KennelsScreen handleOkAssignKennel={handleOkAssignKennel} bookingId={kennel.current} />} handleOk={handleOkAssignKennel} handleCancel={handleCancelAssignKennel} isModalOpen={isModalOpenAssignKennel} />}
//                                 </>
//                             } else if (petBoarding_status?.pet_verification_by_admin?.status === "decline") {
//                                 return <Text style={{ color: token.Green }}>Pet verification unsuccessful!</Text>
//                             } else if (pet_handed_over_to_the_boarding_center?.status === "accept") {
//                                 return <>
//                                     <Text>Pet handed over to the boardhead by captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>.</Text>
//                                     <Button style={{ border: 'none' }} type="link" onClick={() => handleVerifyPet(bookingId, bookingUniqueId)}>Verify pet</Button>
//                                 </>
//                             } else if (pet_handed_over_to_the_boarding_center?.status === "decline") {
//                                 return <Text style={{ color: token.Red }}>Pet not handed over to the boardhead by captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>.</Text>
//                             } else if (pet_reached_boarding_center?.status === "accept") {
//                                 return <Text>Pet just reached boarding center</Text>
//                             } else if (pet_reached_boarding_center?.status === "decline") {
//                                 return <Text>Pet did not reach boarding center</Text>
//                             } else if (pet_on_the_way_to_boarding_center?.status === "accept") {
//                                 return <>
//                                     <Text style={{ display: "block" }}>Pet picked up and captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> started his ride to boarding center.</Text>
//                                     <Button type="primary" size="small" onClick={() => showModalForTrack(bookingUniqueId)}>View on map</Button>
//                                     {bookingIdToTrack.current === bookingUniqueId && <CommonModal handleCancel={hideModalForTrack} isModalOpen={modalVisible} body={() => <Tracking showStatus={"showStatus"} bookingIdFromAdmin={bookingUniqueId} />} />}
//                                 </>
//                             } else if (pet_on_the_way_to_boarding_center?.status === "decline") {
//                                 return <Text>Pet did not pick up by captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link></Text>
//                             } else if (pet_verification_by_captain?.status === "accept") {
//                                 return <Text>Pet verification successfull!!</Text>
//                             } else if (pet_verification_by_captain?.status === "decline") {
//                                 return <Text style={{ color: token.Red }}>Pet verification unsuccessfull due to {pet_verification_by_captain?.reason}</Text>
//                             } else if (parent_verification?.status === "accept") {
//                                 return <Text>Parent verification successfull!!</Text>
//                             } else if (parent_verification?.status === "decline") {
//                                 return <Text style={{ color: token.Red }}>Parent verification unsuccessfull due to {parent_verification?.reason}</Text>
//                             } else if (order_verification?.status === "accept") {
//                                 return <Text>Order verification successful!!</Text>
//                             } else if (order_verification?.status === "decline") {
//                                 return <Text style={{ color: token.Red }}>Order verification unsuccessful due to {order_verification?.reason}</Text>
//                             } else if (reached_parent_location?.status === "accept") {
//                                 return <Text>Captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> reached parent location</Text>
//                             } else if (on_the_way_to_parent_location?.status === "accept") {
//                                 return <>
//                                     <Text style={{ display: "block" }}>Captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> is on the way to parent location</Text>
//                                     <Button type="primary" size="small" onClick={() => showModalForTrack(bookingUniqueId)}>View on map</Button>
//                                     {bookingIdToTrack.current === bookingUniqueId && <CommonModal handleCancel={hideModalForTrack} isModalOpen={modalVisible} body={() => <Tracking showStatus={"showStatus"} bookingIdFromAdmin={bookingUniqueId} />} />}
//                                 </>
//                             } else if (accepts_ride?.status === "accept") {
//                                 return <Text>Captain  <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> accepted ride</Text>
//                             }
//                         } else {
//                             return <Text style={{ color: token.Red, cursor: "pointer" }} onClick={() => navigate(`/captains/${bookingUniqueId}`)}>Captain has not yet been assigned. Assign Now!</Text>
//                         }
//                     } else if (initiated) {
//                         return <Text style={{ color: token.appbluecolor, cursor: "pointer" }} onClick={() => navigate(`/captains/${bookingUniqueId}`)}>Captain has not yet been assigned. Assign Now!</Text>
//                     }
//                 } else if (petBoarding_status?.initiated?.status) {
//                     const { booking_confirmation, pet_received_at_boarding_center, parent_verification, pet_verification_by_admin, pet_assigned_a_kennel, parent_verification_while_releasing_pet, pet_handed_over_to_parent } = petBoarding_status;
//                     if (pet_handed_over_to_parent?.status === "accept") {
//                         return <Text>Pet handed over to parent.</Text>
//                     } else if (pet_handed_over_to_parent?.status === "decline") {
//                         return <Text style={{ color: token.Red }}>Pet not handed over to parent.</Text>
//                     } else if (parent_verification_while_releasing_pet?.status === "accept") {
//                         return <>
//                             <Text>Verification successfull while releasing pet.</Text>
//                             <Button type="link" onClick={() => dispatch(adminAction.releaseKennelEvent(bookingUniqueId))}>Release pet.</Button>
//                         </>
//                     } else if (parent_verification_while_releasing_pet?.status === "decline") {
//                         return <Text>Verification not successfull while releasing pet</Text>
//                     } else if (pet_assigned_a_kennel?.status === "accept") {
//                         return <>
//                             <Text style={{ color: token.Green }}>Kennel {kennelId} has been assigned.</Text>
//                             <Button type="link" onClick={() => dispatch(adminAction.removeKennelEvent(userDetails.boardCenterUniqueId, bookingUniqueId, { reason: "repair in kennel" }))}>Remove assigned kennel.</Button>

//                             <Button type="link" onClick={() => { showModalVerify(bookingId, bookingUniqueId, userId, bookingStatus, "drop") }}>Verify parent before releasing kennel and handover to parent.</Button>
//                             <CommonModal isModalOpen={isModalOpenVerify} handleCancel={handleCancelAssignKennel} body={() => <VerificationStepsForAdmin userTobeVerifiedByAdmin={userTobeVerifiedByAdmin} setIsModalOpen={setIsModalOpen} typeofroute={typeofroute} />} />
//                         </>
//                     } else if (pet_assigned_a_kennel?.status === "decline") {
//                         return <Text>Kennel has not assigned to pet.</Text>
//                     } else if (pet_assigned_a_kennel?.status === "none") {
//                         return <>
//                             <Text style={{ color: token.Red, cursor: "pointer" }} onClick={() => handleVerifyPetAssignKennel(bookingUniqueId)}>Kennel {kennelId} has been removed due to {petBoarding_status?.pet_assigned_a_kennel?.reason}. Reassign another kennel</Text>
//                             {kennel.current === bookingUniqueId && <CommonModal body={() => <KennelsScreen handleOkAssignKennel={handleOkAssignKennel} bookingId={kennel.current} />} handleOk={handleOkAssignKennel} handleCancel={handleCancelAssignKennel} isModalOpen={isModalOpenAssignKennel} />}
//                         </>
//                     } else if (pet_verification_by_admin?.status === "accept") {
//                         return <>
//                             <Text>Parent and pet verification successfull before assigning kennel.</Text>
//                             <Button type="link" onClick={() => handleVerifyPetAssignKennel(bookingUniqueId)}>Please assign a kennel.</Button>
//                             {kennel.current === bookingUniqueId && <CommonModal body={() => <KennelsScreen handleOkAssignKennel={handleOkAssignKennel} bookingId={kennel.current} />} handleOk={handleOkAssignKennel} handleCancel={handleCancelAssignKennel} isModalOpen={isModalOpenAssignKennel} />}
//                         </>
//                     } else if (pet_verification_by_admin?.status === "decline") {
//                         return <Text>Pet verification unsuccessful before assigning kennel.</Text>
//                     } else if (parent_verification?.status === "decline") {
//                         return <Text>Parent verification unsuccessful before assigning kennel.</Text>
//                     } else if (pet_received_at_boarding_center?.status === "accept") {
//                         return <>
//                             <Text>Pet received at boarding center</Text>
//                             <Button type="link" size="small" onClick={() => showModalVerify(bookingId, bookingUniqueId, userId, bookingStatus, "pickup")}>Verify Now.</Button>
//                             <CommonModal isModalOpen={isModalOpenVerify} handleCancel={handleCancelAssignKennel} body={() => <VerificationStepsForAdmin userTobeVerifiedByAdmin={userTobeVerifiedByAdmin} setIsModalOpen={setIsModalOpen} typeofroute={typeofroute} />} />
//                         </>
//                     } else if (pet_received_at_boarding_center?.status === "decline") {
//                         return <Text>Pet not received at boarding center</Text>
//                     } else if (booking_confirmation?.status === "accept") {
//                         return <>
//                             <Text>Booking has been accepted.</Text>
//                             <Popconfirm icon={null} cancelText={<Text>No</Text>} okText={<Text>Yes</Text>} onConfirm={() => handleReceivedPet(bookingId, bookingUniqueId)}>
//                                 <Button type="link">Did you receive the pet? </Button>
//                             </Popconfirm>
//                         </>
//                     }
//                 }
//             },
//         },
//     ];

//     return {
//         newAppointmentColumns,
//         declinedAppointmentColumns,
//         onprocessAppointmentColumns,
//         contextHolder,
//         handleClearFilters,
//         setFiltered
//     };
// };
// export default useColumns;


import React, { useRef, useState } from "react";
import { Modal, Space, Select, Input, Tag, Typography, theme } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { services } from "../../Common/Components/Services";
import useAcceptDecline from "./useAcceptDecline";
import { useDispatch, useSelector } from "react-redux";
import { adminAction } from "../state/actions";
import { FilterFilled } from "@ant-design/icons";
import { useSocket } from "../../context/SocketContext";
import useFiltering from "../../Common/Components/useFiltering";
import PDBCToParentDropPet from "./PDBCToParentDropPet";
import PDBCTOPickPet from "./PDBCTOPickPet";
import PetBoardingStatusForAdmin from "./PetBoardingStatusForAdmin";

const { Text, Paragraph } = Typography;
/**
 * Custom hook to generate table columns for various appointment types and handle filtering, modals, and navigation.
 * @returns {Object} - An object containing column configurations, modal context holder, and filter management functions.
 */
const useColumns = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userDetails } = useSelector((str) => str.navbarReducer);
    const { useToken } = theme;
    const { token } = useToken();
    const { currentSocket } = useSocket();
    const bookingIdToTrack = useRef(null);
    const { type } = useParams();
    const [modalVisible, setModalVisible] = useState(false);
    const { getColumnSearchProps, setFiltered, filtered } = useFiltering(
        (search) =>
            dispatch(
                adminAction.getConfirmedBookingDetails(
                    userDetails.boardCenterUniqueId,
                    {
                        // boardCenterId: userDetails.boardingCenter,
                        page: 1,
                        status: type,
                        ...search,
                    }
                )
            )
    );
    const showModalForTrack = (bookingId) => {
        bookingIdToTrack.current = bookingId;
        currentSocket.emit("booking_connected", {
            bookingUniqueId: bookingId,
        });
        setModalVisible(true);
    };
    const hideModalForTrack = () => {
        setModalVisible(false);
    };

    const {
        declineBooking,
        handleOk,
        handleCancel,
        handleAccept,
        setDeclineBooking,
        handleSelectChange,
        showModal,
        isModalOpen,
        contextHolder,
        setIsModalOpen,
    } = useAcceptDecline();
    /**
       * Clears all filters and reloads the data for a specific service type.
       * @param {string} key - The service type key.
       */
    const handleClearFilters = (key) => {
        setFiltered({ id: false, details: false, services: false });
        setIsModalOpen(false);
        navigate(`/services/${key}`);
        dispatch(
            adminAction.getConfirmedBookingDetails(userDetails.boardCenterUniqueId, {
                // boardCenterId: userDetails.boardingCenter,
                page: 1,
                status: key,
            })
        );
    };

    const generateColumns = (type) => [
        {
            title: "Booking Id",
            dataIndex: "bookingUniqueId",
            key: "bookingUniqueId",
            width: 150,
            align: "center",
            fixed: "left",
            ...getColumnSearchProps("id"),
            render: (_, { bookingUniqueId }) => (
                <>
                    <Text
                        style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: token.appbluecolor,
                        }}
                        onClick={() => navigate(`/userDetails/${bookingUniqueId}`)}
                    >
                        {bookingUniqueId}
                    </Text>
                </>
            ),
        },
        {
            title: "Details",
            dataIndex: "parentName",
            key: "parentName",
            width: 150,
            align: "center",
            ...getColumnSearchProps("details"),
            render: (_, { contact, parentName }) => (
                <div>
                    <Text style={{ display: "block" }}>{parentName}</Text>
                    <Text style={{ display: "block" }}>
                        {contact.replace(/^(\+)(\d{2})\s(\d{10})$/, "$1($2) ($3)")}
                    </Text>
                </div>
            ),
        },
        {
            title: "Services",
            dataIndex: "services",
            key: "services",
            width: 150,
            align: "center",
            filters: services.map((ser) => ({ text: ser.name, value: ser.key })),
            filterMultiple: false,
            filterIcon: () => (
                <FilterFilled
                    style={{ color: filtered.services ? token.colorPrimary : token.Gray }}
                />
            ),
            render: (_, selectedServices) => (
                <>
                    {selectedServices?.services?.map((service) => (
                        <Tag key={service}>
                            {services.find((e) => e.key === service).name}
                        </Tag>
                    ))}
                </>
            ),
        },
    ];

    const newAppointmentColumns = [
        ...generateColumns("newAppointments"),
        {
            title: "Appointment Status",
            dataIndex: "appointmentstatus",
            key: "appointmentstatus",
            align: "center",
            width: 200,
            render: (_, e) => {
                return (
                    <Space size={"small"} direction="vertical" align="center">
                        <Tag
                            style={{
                                background: "rgb(90, 123, 203)",
                                color: token.White,
                                cursor: "pointer",
                            }}
                            onClick={() => handleAccept(e)}
                        >
                            Accept & Assign
                        </Tag>
                        <>
                            <Tag
                                style={{
                                    background: "grey",
                                    color: token.White,
                                    cursor: "pointer",
                                }}
                                onClick={() => showModal(e)}
                            >
                                Decline
                            </Tag>
                            <Modal
                                title="Decline reason"
                                open={isModalOpen}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                centered
                            >
                                <Select
                                    defaultValue="Select your reason"
                                    style={{
                                        width: "90%",
                                    }}
                                    onChange={handleSelectChange}
                                    options={[
                                        {
                                            value: "Select your reason",
                                            label: "Select your reason",
                                        },
                                        {
                                            value: "Boarding center closed for the day",
                                            label: "Boarding center closed for the day",
                                        },
                                        { value: "other", label: "other" },
                                    ]}
                                />
                                <Input
                                    placeholder="Specify your decline reason"
                                    value={declineBooking.customReason}
                                    onChange={(e) =>
                                        setDeclineBooking({
                                            ...declineBooking,
                                            customReason: e.target.value,
                                        })
                                    }
                                    style={{
                                        marginTop: "1em",
                                        width: "90%",
                                        display:
                                            declineBooking.declineReason === "other"
                                                ? "initial"
                                                : "none",
                                    }}
                                />
                            </Modal>
                        </>
                    </Space>
                );
            },
        },
    ];

    const declinedAppointmentColumns = [
        ...generateColumns("declinedAppointments"),
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
            width: 200,
            align: "center",
            render: (_, e) => (
                <Space align="center">
                    <Paragraph style={{ color: token.Primary }}>
                        {e.bookingStatus.petBoarding_status.booking_confirmation?.reason}
                    </Paragraph>
                </Space>
            ),
        },
    ];

    const onprocessAppointmentColumns = [
        ...generateColumns("inProcessAppointments"),
        {
            title: "Appointment Status",
            dataIndex: "appointmentstatus",
            key: "appointmentstatus",
            width: 200,
            align: "center",
            render: (
                _,
                {
                    bookingId,
                    bookingUniqueId,
                    bookingStatus,
                    userId,
                    kennelId,
                    captainId,
                }
            ) => {
                const { petPickupAndDrop_status, petBoarding_status } = bookingStatus;
                if (petPickupAndDrop_status?.initiated?.status) {
                    const {
                        boarding_center_to_parent_location_to_pickup_pet,
                        initiated,
                        boarding_center_to_parent_location_drop_pet,
                    } = petPickupAndDrop_status;
                    if (boarding_center_to_parent_location_drop_pet) {
                        return (
                            <PDBCToParentDropPet
                                boarding_center_to_parent_location_drop_pet={
                                    boarding_center_to_parent_location_drop_pet
                                }
                                bookingIdToTrack={bookingIdToTrack}
                                bookingUniqueId={bookingUniqueId}
                                captainId={captainId}
                                hideModalForTrack={hideModalForTrack}
                                modalVisible={modalVisible}
                                showModalForTrack={showModalForTrack}
                            />
                        );
                    } else if (boarding_center_to_parent_location_to_pickup_pet) {
                        let {
                            accepts_ride,
                        } = boarding_center_to_parent_location_to_pickup_pet;
                        if (accepts_ride?.status === "decline") {
                            return (
                                <Text
                                    style={{ color: token.Primary, cursor: "pointer" }}
                                    onClick={() => navigate(`/captains/${bookingUniqueId}`)}
                                >
                                    Captain{" "}
                                    <Link to={`captainsBookings/${captainId}`}>{captainId}</Link>{" "}
                                    has declined. Please assign another captain!
                                </Text>
                            );
                        } else if (accepts_ride?.status === "in_process") {
                            return (
                                <Text>
                                    Captain{" "}
                                    <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>{" "}
                                    has been assigned. Awaiting for the response!
                                </Text>
                            );
                        } else if (accepts_ride?.status === "decline_request") {
                            return (
                                <Text>
                                    Decline request has been raised by the captain{" "}
                                    <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>{" "}
                                    for this booking.Check now!
                                </Text>
                            );
                        } else if (accepts_ride?.status === "none") {
                            return (
                                <Text
                                    style={{ color: token.appbluecolor, cursor: "pointer" }}
                                    onClick={() => navigate(`/captains/${bookingUniqueId}`)}
                                >
                                    Board head has cancelled the captain{" "}
                                    <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>
                                    . Reassign now.
                                </Text>
                            );
                        } else if (accepts_ride?.status === "accept") {
                            return (
                                <PDBCTOPickPet
                                    boarding_center_to_parent_location_to_pickup_pet={
                                        boarding_center_to_parent_location_to_pickup_pet
                                    }
                                    bookingId={bookingId}
                                    bookingIdToTrack={bookingIdToTrack}
                                    bookingStatus={bookingStatus}
                                    bookingUniqueId={bookingUniqueId}
                                    captainId={captainId}
                                    hideModalForTrack={hideModalForTrack}
                                    kennelId={kennelId}
                                    modalVisible={modalVisible}
                                    petBoarding_status={petBoarding_status}
                                    showModalForTrack={showModalForTrack}
                                />
                            );
                        } else {
                            return (
                                <Text
                                    style={{ color: token.Red, cursor: "pointer" }}
                                    onClick={() => navigate(`/captains/${bookingUniqueId}`)}
                                >
                                    Captain has not yet been assigned. Assign Now!
                                </Text>
                            );
                        }
                    } else if (initiated) {
                        return (
                            <Text
                                style={{ color: token.appbluecolor, cursor: "pointer" }}
                                onClick={() => navigate(`/captains/${bookingUniqueId}`)}
                            >
                                Captain has not yet been assigned. Assign Now!
                            </Text>
                        );
                    }
                } else if (petBoarding_status?.initiated?.status) {
                    return (
                        <PetBoardingStatusForAdmin
                            bookingId={bookingId}
                            bookingStatus={bookingStatus}
                            bookingUniqueId={bookingUniqueId}
                            kennelId={kennelId}
                            petBoarding_status={petBoarding_status}
                            userId={userId}
                        />
                    );
                }
            },
        },
    ];

    return {
        newAppointmentColumns,
        declinedAppointmentColumns,
        onprocessAppointmentColumns,
        contextHolder,
        handleClearFilters,
        setFiltered,
    };
};
export default useColumns;
