import axios from "axios";
import { jwtDecode } from "jwt-decode";

const apiURL = process.env.REACT_APP_BACKEND_URL;

//update parent details
const updateParentDetails = (data) => {
  // console.log(data, "data");

  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.put(`${apiURL}/updateProfile`, data, config);
};

// get parent details
const parentDetails = () => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/parentProfileDetails`, config);
};

// get pet details
const petDetails = () => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/petDetails`, config);
};

// post pet details
const postPetdetails = (data) => {
  // console.log(data, "data");
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(`${apiURL}/addPetDetails`, data, config);
};

// update pet details
const updatePetDetails = (data, id) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.put(`${apiURL}/updatePetDetails/${id}`, data, config);
};

// post select services
const selectServices = (data, id) => {
  console.log("hello21");
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  console.log("hello22");
  let res = axios.post(
    `${apiURL}/bookAppointment/selectServices/${id}`,
    data,
    config
  );
  console.log("hello23");
  return res;
};

// get pet parentboardingcenter details
const parentBoardingCenterDetails = () => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/getParentBoardingCenterDetails`, config);
};

const slotExpiration = (bookingId) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  console.log("config", config);
  return axios.put(`${apiURL}/expireSlots/${bookingId}`, null, config);
};

export const profileapi = {
  updateParentDetails,
  parentDetails,
  petDetails,
  postPetdetails,
  updatePetDetails,
  selectServices,
  parentBoardingCenterDetails,
  slotExpiration,
};
