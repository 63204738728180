// import boarding from "/Assets/Services/boarding.png";
// import adoption from "/Assets/Services/adoption.png";
// import pickup from "/Assets/Services/pickups.png";
// import walkings from "/Assets/Services/walking.png";
// import dayCare from "/Assets/Services/daycare.png";
// import sitting from "/Assets/Services/sitting.png";
// import training from "/Assets/Services/training.png";
// import grooming from "/Assets/Services/grooming.png";
// import fashion from "/Assets/Services/fashion.png";
// import cremation from "/Assets/Services/cremation.png";

export const services = [
  {
    image: "/Assets/Services/boarding.png",
    name: "Boarding",
    key: "petBoarding",
    serviceType: "primary",
    price: "1000"
  },
  {
    image: "/Assets/Services/adoption.png",
    name: "Adoption",
    key: "petAdoption",
    serviceType: "primary",
    price: "1000"
  },
  {
    image: "/Assets/Services/pickups.png",
    name: "Pickup And Drop",
    key: "petPickupAndDrops",
    serviceType: "primary",
    price: "1000"
  },
  {
    image: "/Assets/Services/walking.png",
    name: "Walkings",
    key: "petWalkings",
    serviceType: "primary",
    price: "1000"
  },
  {
    image: "/Assets/Services/daycare.png",
    name: "Day Care",
    key: "petDayCare",
    serviceType: "primary",
    price: "1000"
  },
  {
    image: "/Assets/Services/sitting.png",
    name: "Sitting",
    key: "petSitting",
    serviceType: "primary",
    price: "1000"
  },
  {
    image: "/Assets/Services/training.png",
    name: "Training",
    key: "petTraining",
    serviceType: "primary",
    price: "1000"
  },
  {
    image: "/Assets/Services/grooming.png",
    name: "Spa And Grooming",
    key: "petSpaAndGrooming",
    serviceType: "primary",
    price: "1000"
  },
  {
    image: "/Assets/Services/fashion.png",
    name: "Fashion Show",
    key: "petFashionShow",
    serviceType: "primary",
    price: "1000"

  },
  {
    image: "/Assets/Services/cremation.png",
    name: "Cremation",
    key: "petCremation",
    serviceType: "primary",
    price: "1000"
  },
];

