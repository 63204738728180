import { dashboardConstants } from "./action-types";

const initialState = {
  acceptOrRejectCaptainsDeclineRequest: false,
  acceptOrRejectCaptainsDeclineRequestErr: false,
  actionCenterDetails: false,
  captainDetails: false,
  captainDetailsErr: false,
  notifyAdmin: false,
  notifyAdminErr: false,
  actionCenterDetailsError: false,
  getCaptainsBookings: false,
  getCaptainsBookingsErr: false,
  totalCaptainsBookings: 0,
  totalCaptainDetails:0,
  requestCancellationSuccess: false,
  requestCancellationErr: false
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardConstants.GET_ACTION_CENTER_DETAILS_SUCCESS:
      return {
        ...state,
        actionCenterDetails: action.data,
        actionCenterDetailsError: false,
      };
    case dashboardConstants.GET_ACTION_CENTER_DETAILS_FAILURE:
      return {
        ...state,
        actionCenterDetails: false,
        actionCenterDetailsError: action.error,
      };
    case dashboardConstants.GET_CAPTAIN_DETAILS_SUCCESS:
      return {
        ...state,
        captainDetails: action.data.currentPage === 1 ? action.data.docs : [...state.captainDetails, ...action.data.docs],
        captainDetailsErr: false,
        totalCaptainDetails: action.data.totalDocs
        // getCaptainsBookings: action.data.currentPage === 1 ? action.data.docs : [...state.getCaptainsBookings, ...action.data.docs],
        // getCaptainsBookingsErr: false,
        // totalCaptainsBookings: action.data.totalDocs
      };
    case dashboardConstants.GET_CAPTAIN_DETAILS_ERROR:
      return {
        ...state,
        captainDetails: false,
        captainDetailsErr: action.error,
        totalCaptainDetails: 0
      };
    case dashboardConstants.NOTIFY_ADMIN_OF_DECLINING_BOOKING_SUCCESS:
      return {
        ...state,
        notifyAdmin: action.data,
        notifyAdminErr: false,
      };
    case dashboardConstants.NOTIFY_ADMIN_OF_DECLINING_BOOKING_ERROR:
      return {
        ...state,
        notifyAdmin: false,
        notifyAdminErr: action.error,
      };
    case dashboardConstants.GET_CAPTAINS_BOOKINGS_SUCCESS:
      return {
        ...state,
        getCaptainsBookings: action.data.currentPage === 1 ? action.data.docs : [...state.getCaptainsBookings, ...action.data.docs],
        getCaptainsBookingsErr: false,
        totalCaptainsBookings: action.data.totalDocs
      };
    case dashboardConstants.GET_CAPTAINS_BOOKINGS_ERROR:
      return {
        ...state,
        getCaptainsBookings: false,
        getCaptainsBookingsErr: action.error,
        totalCaptainsBookings: 0
      };
    case dashboardConstants.ACCEPT_OR_REJECT_CAPTAINS_DECLINE_REQUEST_SUCCESS:
      return {
        ...state,
        acceptOrRejectCaptainsDeclineRequest: action.data,
        acceptOrRejectCaptainsDeclineRequestErr: false,
      };
    case dashboardConstants.ACCEPT_OR_REJECT_CAPTAINS_DECLINE_REQUEST_ERROR:
      return {
        ...state,
        acceptOrRejectCaptainsDeclineRequest: false,
        acceptOrRejectCaptainsDeclineRequestErr: action.error,
      };
    case dashboardConstants.REQUEST_FOR_CANCELLATION_SUCCESS:
      return {
        ...state,
        requestCancellationSuccess: action.data,
        requestCancellationErr: false,
      };
    case dashboardConstants.REQUEST_FOR_CANCELLATION_ERROR:
      return {
        ...state,
        requestCancellationSuccess: false,
        requestCancellationErr: action.error,
      };
    case dashboardConstants.CLEAR_ACTION_CENTER:
      return {
        ...state,
        actionCenterDetails:false
      }
    default:
      return state;
  }
};
export { dashboardReducer };
