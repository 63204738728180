import axios from "axios";
const apiURL = process.env.REACT_APP_BACKEND_URL;

//get all users
const getAllUsers = (page, search, sort, systemPasswordOnly) => {
  console.log(search, sort, 'search')
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  let url = `${apiURL}/get_all_users?page=${page}`;
  if (search) {
    url += `&search=${search}`;
  }
  if (sort) url += `&sortField=${sort}`;
  if (systemPasswordOnly) url += `&systemPasswordOnly=${systemPasswordOnly}`
  return axios.get(url, config);
};

//update user role
const updateUserRole = (data, roleStatus) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.put(
    `${apiURL}/updateUserRole?role_status=${roleStatus}`,
    data,
    config
  );
};

// send verification email
const sendVerificationEmail = (email) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/send_verification_email/${email}`, config);
};

// update user
const updateUser = (email, data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.put(`${apiURL}/update_user/${email}`, data, config);
};

// create user
const createUser = (data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${apiURL}/create_user`, data, config);
};


export const usersApi = {
  getAllUsers,
  updateUserRole,
  sendVerificationEmail,
  updateUser,
  createUser
};
