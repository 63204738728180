import { Button, Col, theme } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleStartTransaction } from "../utils/paymentMethods";
import { useSocket } from "../../context/SocketContext";

const FinalBookAppointmentButtons = ({
  slotsExpired,
  isPaymentLoading,
  setIsPaymentLoading,
  setLoading,
}) => {
  console.log("final buttons");
  const { useToken } = theme;
  const { token } = useToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bookappointmentdetails } = useSelector(
    (str) => str.bookAppointmentReducer
  );
  const { currentSocket } = useSocket();
  // console.log("bookAppointment details", bookappointmentdetails);
  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={15}
      style={{
        marginTop: "1.2em",
        marginBottom: "1.2em",
        position: "sticky",
        bottom: 10,
        justifyContent: "flex-end",
        display: "flex",
        background: token.background,
      }}
    >
      <Button
        type="primary"
        onClick={() => {
          navigate("/book-appointment/step-2");
        }}
        style={{ marginRight: "1.2em" }}
      >
        ❮ Back
      </Button>

      <Button
        type="primary"
        loading={isPaymentLoading}
        onClick={() => {
          handleStartTransaction(
            setIsPaymentLoading,
            bookappointmentdetails,
            token,
            navigate,
            setLoading,
            dispatch
          );
          currentSocket.emit("orderInitiated", {
            orderInitiated: true,
            bookingId: bookappointmentdetails?.bookingDetails?.bookingUniqueId,
            userId: bookappointmentdetails?.bookingDetails?.parent,
          });
        }}
        disabled={
          slotsExpired || !bookappointmentdetails?.bookingDetails?.paymentPage
        }
        style={{ marginRight: "1.2em" }}
      >
        Place order ❯
      </Button>
      {/* Create New Booking Button (visible when slots expired) */}
      {slotsExpired && (
        <Button
          type="primary"
          onClick={() => {
            localStorage.removeItem("bookingID");
            localStorage.setItem("newBookAppointment", true);
            navigate("/book-appointment/step-1");
          }}
        >
          Create New Booking ❯
        </Button>
      )}
    </Col>
  );
};

export default FinalBookAppointmentButtons;
