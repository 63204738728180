import axios from "axios";
const apiURL = process.env.REACT_APP_BACKEND_URL;
// signup
const signup = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${apiURL}/signup`, data, config);
};

//login
const login = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "User-Agent": navigator.userAgent,
    },
  };
  return axios.post(`${apiURL}/login`, data, config);
};

//verify after signup
const verifyuser = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${apiURL}/verify/${id}/${token}`,null, config);
};

//forgot password
const forgotPassword = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${apiURL}/forgotpassword`, data, config);
};

//change password
const changePassword = async (data, id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${apiURL}/forgotPassword/${id}/${token}`, data, config);
};

//SSO through google
const ssoGoogle = (code, source) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "User-Agent": navigator.userAgent,
      // credential: data,
      // source: source
    },
  };
  // const x = {
  //   credential: data,
  //   source: source
  // }
  const x = {
    code: code,
    source: source,
    type: "web"
  };
  return axios.post(`${apiURL}/auth/google/callback`, x, config);
};

// Request OTP
const requestOtp = (data) => {
  const token = localStorage.getItem('authToken');
  const config = {
    headers: {
      'Content-Type': "application/json",
      Authorization: token
    }
  }
  return axios.post(`${apiURL}/requestOTP`, data, config);
}

//verify otp
const verifyOtp = (data) => {
  const token = localStorage.getItem('authToken');
  const config = {
    headers: {
      'Content-Type': "application/json",
      Authorization: token
    }
  }
  return axios.post(`${apiURL}/verifyOTP`, data, config);
}

// user check
const userCheck= (email) => {
  const token = localStorage.getItem('authToken');
  const config = {
    headers: {
      'Content-Type': "application/json",
      Authorization: token
    }
  }
  return axios.get(`${apiURL}/user-check/${email}`, config);
}

export const signupApi = {
  signup,
  login,
  verifyuser,
  forgotPassword,
  changePassword,
  ssoGoogle,
  requestOtp,
  verifyOtp,
  userCheck
};
