import { CaretRightFilled } from "@ant-design/icons";
import { Avatar, Card, List, Typography, theme } from "antd";
import React, { useEffect } from "react";
import { services } from "../../Common/Components/Services";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
import { adminAction } from "../../ServiceDetails/state/actions";
const { Text } = Typography;

/** @description -lists recent 5 new appointments for admin */
const NewAppointmentsForAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDetails } = useSelector((str) => str.navbarReducer);
  const { getNewConfirmedBookings } = useSelector(
    (str) => str.adminDashboardReducer
  );
  const { isLoadingAllConfirmedBookings } = useSelector(
    (str) => str.loadingReducer
  );

  let confirmedBookings =
    getNewConfirmedBookings &&
    getNewConfirmedBookings.map((booking) => booking.parentPetInfo);

  useEffect(() => {
    if (userDetails.boardingCenter) {
      dispatch(
        adminAction.getConfirmedBookingDetails(
          userDetails.boardCenterUniqueId,
          {
            // boardCenterId: userDetails.boardingCenter,
            page: 1,
            status: "newAppointments",
          }
        )
      );
    }
  }, [userDetails, dispatch]);

  if (isLoadingAllConfirmedBookings) {
    return <Spinner />;
  }

  return (
    <div>
      <Card title="New Appointments">
        <List
          itemLayout="horizontal"
          dataSource={
            getNewConfirmedBookings ? confirmedBookings?.slice(0, 5) : []
          }
          renderItem={(item) => (
            <List.Item
              actions={[<CaretRightFilled />]}
              onClick={() => navigate(`/userDetails/${item?.bookingUniqueId}`)}
              style={{ cursor: "pointer" }}
            >
              <List.Item.Meta
                avatar={
                  <Avatar src="https://as1.ftcdn.net/v2/jpg/02/43/12/34/1000_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" />
                }
                title={item?.parentName}
                description={
                  <>
                    <Text style={{ display: "block" }}>{item?.petName}</Text>
                    <Text style={{ display: "block" }}>{item?.contact}</Text>
                    <Text style={{ display: "block" }}>
                      {item?.services
                        ?.map(
                          (service) =>
                            services.find((e) => e.key === service).name
                        )
                        .join(",")}
                    </Text>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
};

export default NewAppointmentsForAdmin;
