import React from "react";
import { Col, Image, Row, Tooltip, Typography, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
import styles from "../Styles/banner.module.css";
import { useTranslation } from "react-i18next";
import { useRoute } from "../../context/RouteContext";
import welcomeHouse from "../../Assets/Dashboard/bookAppointmentHouse.png";
import bookAppointmentIcon from "../../Assets/Dashboard/bookappointmentIcon.png";
import { InfoCircleFilled } from "@ant-design/icons";
const { Text } = Typography;

const Banner = () => {
  const navigate = useNavigate();
  const { userDetails } = useSelector((str) => str.navbarReducer);
  // const { isLoadingBoardingCenter } = useSelector((str) => str.loadingReducer);
  const { t } = useTranslation('home');
  const { useToken } = theme;
  const { token } = useToken();
  const { setSelectedKey } = useRoute();

  return (
    <>
      <Row
        className={styles.banner}
        style={{ background: `${token.Secondary}` }}
      >
        <Col xs={24} sm={24} md={12} lg={12}>
          <Text className={styles.furryfriends}>
            {t("let_us_care_our_furry_friends")}
          </Text>
          {userDetails?.boardingCenterLocation?.completeBoardingAddress ? (
            <>
              <Text className={styles.boardingcenter}>
                {t("nearest_boarding_center")} : &nbsp;
              </Text>
              <span className={styles.boardingcenter}>
                {userDetails?.boardingCenterLocation?.completeBoardingAddress ||
                  "--"}
              </span>
            </>
          ) : (
            <Text className={styles.noboardingcenter}>
              Selected Address is not servicable. Please select another
              location.{" "}
              <Tooltip
                color={token.Secondary}
                title="Selected Address is not servicable. Our team is working tirelessly to provide the services at your location. Please select another
              location."
              >
                <InfoCircleFilled />
              </Tooltip>
            </Text>
          )}
          <Text className={styles.passionate}>{t("passionate_pet_care!")}</Text>
          <Text className={styles.ourdevoted}>
            {t(
              "our_devoted_team_ensures_your_pets_thrive_with_love,_attention,_and_top-notch_care."
            )}
          </Text>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={4}
          style={{ cursor: "pointer" }}
          onClick={() => {
            localStorage.setItem("newBookAppointment", true);
            localStorage.removeItem("bookingID");
            navigate("book-appointment/step-1");
            setSelectedKey("CRUD_BOOK_APPOINTMENT");
            // navigate("/book-appointment/new/id?step=1");
          }}
        >
          <Image
            src={bookAppointmentIcon}
            preview={false}
            alt="Book appointment"
          />
        </Col>
        <Col xs={0} sm={0} md={0} lg={6} style={{ textAlign: "end" }}>
          <Image
            src={welcomeHouse}
            style={{ width: "60%" }}
            preview={false}
            alt="Book appointment"
          />
        </Col>
      </Row>
    </>
  );
};

export default Banner;
