import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoute } from "../../context/RouteContext";
import { useSocket } from "../../context/SocketContext";
import { useParams, useSearchParams } from "react-router-dom";
import { adminAction } from "../state/actions";
/**
 * Custom hook to manage service details for confirmed bookings in the admin dashboard.
 * 
 * @returns {Object} - Contains methods and state for managing and loading booking data.
 */
const useServiceDetails = () => {
    const dispatch = useDispatch();
    const { userDetails } = useSelector((str) => str.navbarReducer);
    const { setRouteKey } = useRoute();
    const { type } = useParams();
    const [activekey, setActivekey] = useState(type);
    const {
        getNewConfirmedBookings,
        updateBookingStatus,
        removeKennel,
        releaseKennel,
        totalGetNewConfirmedBookings,
        notifyCaptain
    } = useSelector((str) => str.adminDashboardReducer);
    const { isLoadingAllConfirmedBookings } = useSelector(
        (str) => str.loadingReducer
    );
    const { currentSocket } = useSocket();
    const [page, setPage] = useState(1);
    const [searchParams] = useSearchParams();
    const [bookingCount, setBookingCount] = useState({
        bookingIds: new Set(),
        count: 0,
    });
    /**
        * Handles the fetching of confirmed booking details.
        * 
        * @param {number} pageNumber - The page number to fetch.
        * @param {string} key - The status key for filtering bookings.
        */
    const handleConfirmedBookings = (pageNumber, key) => {
        if (searchParams.size === 0) {
            dispatch(
                adminAction.getConfirmedBookingDetails(
                    userDetails.boardCenterUniqueId,
                    {
                        // boardCenterId: userDetails.boardingCenter,
                        page: pageNumber,
                        status: key,
                    }
                )
            );
        } else {
            const search = Array.from(searchParams.entries()).reduce(
                (acc, [key, val]) => {
                    acc[key] = val;
                    return acc;
                },
                {}
            );
            dispatch(
                adminAction.getConfirmedBookingDetails(
                    userDetails.boardCenterUniqueId,
                    {
                        // boardCenterId: userDetails.boardingCenter,
                        page: pageNumber,
                        status: key,
                        ...search,
                    }
                )
            );
        }
    };

    useEffect(() => {
        if (userDetails.boardingCenter) {
            handleConfirmedBookings(1, activekey);
        }
    }, [userDetails, activekey, dispatch]);

    /**
         * Syncs the confirmed bookings list by resetting the booking count and fetching the latest data.
         */
    const handleSync = () => {
        handleConfirmedBookings(1, activekey);
        setBookingCount({ bookingIds: new Set(), count: 0 });
    };

    useEffect(() => {
        setRouteKey("services");
    }, [setRouteKey]);

    useEffect(() => {
        setActivekey(type);
    }, [type]);

    useEffect(() => {
        if (updateBookingStatus || removeKennel || releaseKennel || notifyCaptain) {
            handleConfirmedBookings(1, activekey);
            dispatch(adminAction.clearAlldata());
        }
        // eslint-disable-next-line
    }, [updateBookingStatus, removeKennel, releaseKennel, notifyCaptain]);

    /**
        * Loads more booking data as the user scrolls.
        * 
        * @param {number} page - The page number to load.
        * @param {string} key - The status key for filtering bookings.
        */
    const loadMoreData = (page, key) => {
        if (
            !isLoadingAllConfirmedBookings &&
            getNewConfirmedBookings.length < totalGetNewConfirmedBookings
        ) {
            handleConfirmedBookings(page, key);
        } else {
            setPage(1);
        }
    };
    
    /**
         * Listens for real-time updates via WebSocket and updates booking counts accordingly.
         */
    useEffect(() => {
        if (currentSocket) {
            currentSocket.on("statusUpdate", (message) => {
                const bookingId = message.bookingId;
                setBookingCount((prevState) => {
                    if (!prevState.bookingIds.has(bookingId)) {
                        const newBookingIds = new Set(prevState.bookingIds);
                        newBookingIds.add(bookingId);
                        return {
                            bookingIds: newBookingIds,
                            count: newBookingIds.size,
                        };
                    }
                    return prevState;
                });
            });
        }
    }, [currentSocket]);

    return {
        handleSync,
        handleConfirmedBookings,
        activekey,
        bookingCount,
        loadMoreData,
        setPage,
        page,
    };
};

export default useServiceDetails;
