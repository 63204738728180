import axios from "axios";
const apiURL = process.env.REACT_APP_BACKEND_URL;

//get all unverified certificates of the boarding center
const getAllCertificates = () => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/get_all_unverified_vaccine_certificates`, config);
}

// update kennels of boarding center
const updateCertificate = (data, petId) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    let res = axios.put(`${apiURL}/vaccineCertificateVerification/${petId}`, data, config);
    return res;
};


export const certificateApi = {
    getAllCertificates,
    updateCertificate,
};
