import { PlusOutlined } from "@ant-design/icons";
import { FloatButton, Popconfirm, Switch, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRoute } from "../context/RouteContext";
import useWarnings from "../Common/Components/useWarnings";
import { kennelApi } from "./utils/api";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
const { Text } = Typography;

const Kennels = () => {
    const navigate = useNavigate();
    const { setSelectedKey } = useRoute();
    const { success, error, contextHolder } = useWarnings();
    const { userDetails } = useSelector((str) => str.navbarReducer);
    const [kennels, setKennels] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSelectedKey("CREATE_KENNEL");
    }, []);

    const getKennels = async () => {
        setLoading(true);
        try {
            const response = await kennelApi.getAllKennels();
            if (response?.data?.info) {
                setKennels(response.data.info);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            error(err?.response?.data?.message);
        }
    };
    useEffect(() => {
        getKennels();
    }, []);

    const handleConfirm = async () => {
        try {
            const response = await kennelApi.addKennel(
                userDetails?.boardCenterUniqueId
            );
            if (response?.data?.info?.kennelId){
                 success("Kennel added successfully");
                 getKennels()
            }
        } catch (err) {
            error(err?.response?.data?.message);
        }
    };

    const handleAction = (e, kennelId) => {
        setLoading(true);
        const data = {
            isBeingRepaired: !e,
        };
        try {
            kennelApi.updateKennel(data, kennelId).then(res => {
                setLoading(false);
                if (res.data.info) {
                    getKennels();
                    success("Updated successfully")
                }
            });
        } catch (err) {
            setLoading(false);
            error(err?.response?.data?.message);
        }
    };

    const columns = [
        {
            title: "Created on",
            key: "createdAt",
            render: (_, { createdAt }) => (
                <Text>{dayjs(createdAt).format("MMM D, YYYY h:mm A")}</Text>
            ),
        },
        {
            title: "Kennel Id",
            key: "kennelId",
            dataIndex: "kennelId",
        },
        {
            title: "Action",
            key: "isBeingRepaired",
            dataIndex: "isBeingRepaired",
            render: (_, { isBeingRepaired, kennelId }) => (
                <>
                    <Switch
                        checked={!isBeingRepaired}
                        onClick={(e) => handleAction(e, kennelId)}
                    />
                </>
            ),
        },
    ];

    return (
        <div>
            {contextHolder}
            <Table
                loading={loading}
                columns={columns}
                dataSource={kennels}
                bordered
                pagination={false}
            />
            <Popconfirm
                title="Are you sure to create a kennel for your boarding center?"
                onConfirm={handleConfirm}
            >
                <FloatButton
                    icon={<PlusOutlined />}
                    type="primary"
                    style={{
                        right: 30,
                    }}
                />
            </Popconfirm>
        </div>
    );
};

export { Kennels };
