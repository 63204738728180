import axios from "axios";
const apiURL = process.env.REACT_APP_BACKEND_URL;

// add kennel
const addKennel = (boardCenterId) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    let res = axios.post(`${apiURL}/addNewKennel/${boardCenterId}`, null, config);
    return res;
};

//get all kennels of the boarding center
const getAllKennels = () => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/getAllKennelsOfBoardingCenter`, config);
}

// update kennels of boarding center
const updateKennel = (data,kennelId) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    let res = axios.put(`${apiURL}/updateKennel/${kennelId}`, data, config);
    return res;
};


export const kennelApi = {
    addKennel,
    getAllKennels,
    updateKennel
};
