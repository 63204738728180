import Chart from "chart.js/auto";
import React, { useEffect, useRef, useState, useMemo } from "react";
import styles from '../Styles/dashboard.module.css';
import { Image, Card } from "antd";
import serviceHistoryName from '../../Assets/Dashboard/Service history.png';


const ServiceHistoryPie = () => {
    const [mode, setMode] = useState("Yearly");
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState("Jan");
    const [serviceData, setServiceData] = useState([]);
    const [lables, setLables] = useState([]);
    const [selectedMonthlyYear, setSelectedMonthlyYear] = useState(
        new Date().getFullYear()
    );
    const chartRef = useRef(null);

    const configuration = useMemo(
        () => ({
            type: "doughnut",
            data: {
                labels: lables,
                datasets: [
                    {
                        label: "Services",
                        data: serviceData,
                        backgroundColor: [
                            "rgb(255, 99, 132)",
                            "rgb(54, 162, 235)",
                            "rgb(255, 205, 86)",
                            "rgb(255, 0, 0)",
                        ],
                        hoverOffset: 4,
                    },
                ],
            },
        }),
        [lables, serviceData]
    );

    useEffect(() => {
        // get data from actual API
        setServiceData([300, 50, 100,21]);
        setLables([
            "Pet day care",
            "Pet SPA & grooming",
            "Pet sitting",
            "Pet walking",
        ]);
    }, [mode, selectedMonth, selectedMonthlyYear, selectedYear]);

    useEffect(() => {
        if (chartRef.current !== null) {
            chartRef.current.destroy();
        }
        const ctx = document.getElementById("myPieChart").getContext("2d");
        chartRef.current = new Chart(ctx, configuration);
    }, [configuration]);

    const handleModeChange = (event) => {
        setMode(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(parseInt(event.target.value));
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleMonthlyYearChange = (event) => {
        setSelectedMonthlyYear(parseInt(event.target.value));
    };

    return (
        <Card hoverable className={styles.entirePiePage}>
            <div style={{justifyContent:"flex-end",display:"flex"}}>
                    <select className={styles.select} id="mode" value={mode} onChange={handleModeChange}>
                        <option value="Yearly">Yearly</option>
                        <option value="Monthly">Monthly</option>
                    </select>&nbsp;
                {mode === "Yearly" ? (
                    <div>
                        <select className={styles.select} id="year" value={selectedYear} onChange={handleYearChange}>
                            {Array.from(
                                { length: 5 },
                                (_, i) => new Date().getFullYear() - i
                            ).map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>&nbsp;
                    </div>
                ) : (
                    <div>
                        <select
                            className={styles.select}
                            id="monthlyYear"
                            value={selectedMonthlyYear}
                            onChange={handleMonthlyYearChange}
                        >
                            {Array.from(
                                { length: 5 },
                                (_, i) => new Date().getFullYear() - i
                            ).map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>&nbsp;
                        <select className={styles.select} id="month" value={selectedMonth} onChange={handleMonthChange}>
                            {[
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                            ].map((month) => (
                                <option key={month} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
            <div style={{height:"300px",display:"flex",justifyContent:"center"}}>
                <canvas id="myPieChart" />
            </div>
            <div className={styles.serviceHistoryName}>
                <Image src={serviceHistoryName} width={150} alt='Service History' preview={false} />
            </div>
        </Card>
    );
};

export default ServiceHistoryPie;
