import { Button, Card, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { ReactQuillComponentRender } from "../../Common/Components/ReactQuillComponentRender";
import { ImageUploadBlog } from "../../Common/Components/ImageUploadBlog";
import { useDispatch, useSelector } from "react-redux";
import { petMarketingAction } from "../State/actions";
import { useNavigate, useParams } from "react-router-dom";

const AddPetBreedForMarketing = () => {
    const [form] = Form.useForm();
    const [description, setDescription] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { getPetById } = useSelector((str) => str.petsMarketingReducer);

    const onFinish = (values) => {
        const changedFields = {};
        Object.keys(values).forEach((field) => {
            if (
                !getPetById.hasOwnProperty(field) ||
                values[field] !== getPetById[field]
            ) {
                changedFields[field] = values[field];
            }
        });
        if (id)
            dispatch(petMarketingAction.updatePetMarketingEvent(id, changedFields));
        else dispatch(petMarketingAction.addPetMarketingEvent(values));
        form.resetFields();

        console.log('hello iam printing')
        navigate("/pets");
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList[0]?.originFileObj;
    };

    useEffect(() => {
        if (id === undefined) {
            form.resetFields();
            // setDesc("");
        } else if (id) {
            dispatch(petMarketingAction.getPetByIdEvent(id));
        }
    }, [id]);

    useEffect(() => {
        if (getPetById) {
            form.setFieldsValue(getPetById);
            setDescription(getPetById.description);
        }
    }, [getPetById]);

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={16}>
                <Col md={24} lg={12}>
                    <Form.Item
                        label="Type of pet"
                        name={"type"}
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Dog" />
                    </Form.Item>
                    <Form.Item
                        label="Pet Breed"
                        name={"breed"}
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="German Sheperd" />
                    </Form.Item>
                    <Form.Item
                        label="Redirect URL"
                        name={"redirectURL"}
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Thumbnail"
                        name={"thumbnail"}
                        getValueFromEvent={normFile}
                        rules={[{ required: true }]}
                    >
                        <ImageUploadBlog origin={"thumbnail"} maxCount={1} />
                    </Form.Item>
                    <ReactQuillComponentRender setDescription={setDescription} />
                </Col>
                <Col md={24} lg={12}>
                    <Card
                        title="Preview"
                        hoverable={false}
                        style={{ height: "80vh", overflow: "scroll" }}
                        extra={<Button htmlType="submit">Submit</Button>}
                    >
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                    </Card>
                </Col>
            </Row>
        </Form>
    );
};

export default AddPetBreedForMarketing;
