import {
  Col,
  Form,
  Input,
  Row,
  Card,
  Select,
  Typography,
  Button,
  theme,
} from "antd";
import React, { useEffect } from "react";
import IdentificationInfo from "./IdentificationInfo";
import PhoneInput from "react-phone-input-2";
import useSettingName from "./useSettingName";
import { useDispatch, useSelector } from "react-redux";
import useWarnings from "./useWarnings";
import { profileAction } from "../State/actions";
import { ImageUpload } from "./ImageUpload";
const { Option } = Select;
const { Title } = Typography;

/**
 * @description
 * The `ParentDetails` component is a form for capturing and editing parent details. The form is prefilled
 * with existing parent details if available, and includes validation for required fields.
 */

const ParentDetails = ({
  onFinish,
  setIdPattern,
  setIdType,
  idType,
  idPattern,
  parentDetails,
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const [form] = Form.useForm();
  const { isLoadingParentDetails } = useSelector(
    (str) => str.additionalloadingReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(parentDetails).length > 0) {
      form.setFieldsValue(parentDetails);
      setIdType(parentDetails?.identification);
      setIdPattern(parentDetails?.identification);
    }
  }, []);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList[0]?.originFileObj;
  };

  useEffect(() => {
    return () => {
      dispatch(profileAction.cleardetailsProfile());
    };
  }, []);

  return (
    <div>
      <Card hoverable style={{ marginTop: "1.2em" }}>
        <Title level={2} style={{ marginTop: 0, color: token.Primary }}>
          Parent Details
        </Title>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[16, 0]} style={{ marginTop: "1.2em" }}>
            <Col
              xs={24}
              sm={12}
              md={24}
              lg={24}
              xl={8}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Form.Item
                // label="Profile Image"
                name="profileImage"
                getValueFromEvent={normFile}
              >
                <ImageUpload
                  maxCount={1}
                  value={parentDetails?.profileImage}
                  form={form}
                  formValueName={"profileImage"}
                  type={"photo"}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item
                    name="firstName"
                    rules={[{ required: true }]}
                    label="First Name"
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Form.Item
                    name="lastName"
                    rules={[{ required: true }]}
                    label="Last Name"
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={24} lg={24} xl={12}>
                  <Form.Item
                    name="email"
                    rules={[{ required: true }]}
                    label="Email"
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={24} lg={24} xl={12}>
                  <Form.Item
                    label="Phone"
                    name="phoneNumber"
                    rules={[{ required: true }]}
                  >
                    <PhoneInput
                      countryCodeEditable={false}
                      country="in"
                      inputStyle={{
                        width: "100%",
                        padding: "8px 11px 8px 48px",
                        border: "0.5px solid grey",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      buttonStyle={{
                        border: "0.5px solid rgba(0, 0, 0, 0.30)",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={12} md={24} lg={12} xl={8}>
              <Form.Item
                name="gender"
                rules={[{ required: true }]}
                label="Gender"
              >
                <Select>
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
              {/* <LocationInfo Form={Form} /> */}
            </Col>
            <Col xs={24} sm={12} md={24} lg={12} xl={16}>
              <IdentificationInfo
                idType={idType}
                setIdType={setIdType}
                idPattern={idPattern}
                setIdPattern={setIdPattern}
                Form={Form}
                form={form}
              />
            </Col>
          </Row>
          <div
            style={{ textAlign: "end" }}
          >
            <Button
              loading={isLoadingParentDetails}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ParentDetails;
