import { Col, Collapse, Drawer, Input, Row, theme, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
import { CaretRightOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "../Styles/helpDrawer.module.css";
import { helpAction } from "../../Help/State/actions";
import { useRoute } from "../../context/RouteContext";
import { debounceFun } from "../../Common/Components/DebounceSearchFun";

/**
 * A drawer component that displays a list of help questions with their descriptions.
 * @param {Object} props - The component props.
 * @param {Function} props.onClose - Callback function to handle closing the drawer.
 * @param {boolean} props.open - Boolean flag to control whether the drawer is open or not.
 *
 * @returns {JSX.Element} A drawer component containing a list of questions. Displays a spinner if data is loading, or "No Data" if there are no questions.
 */

const HelpDrawer = ({ onClose, open }) => {
  const { isLoadingQuestionById } = useSelector((str) => str.loadingReducer);
  const { allQuestionsForscreen } = useSelector((str) => str.helpReducer);
  const { useToken } = theme;
  const { token } = useToken();
  const dispatch = useDispatch();
  const { routeKey } = useRoute();

  if (!open) {
    return;
  }

  const items =
    allQuestionsForscreen &&
    allQuestionsForscreen?.map((e) => {
      return {
        key: e.questionUniqueId,
        label: (
          <Typography.Text style={{ fontWeight: 500 }}>
            {e.title}
          </Typography.Text>
        ),
        children: (
          <p
            className={styles.descImg}
            dangerouslySetInnerHTML={{ __html: e.description?.["en"] }}
          />
        ),
      };
    });

  const searchClientFunc = debounceFun((value) => {
    dispatch(helpAction.getQuestionByKeyEvent(routeKey, "Web", value));
  }, 1000);


  return (
    <Drawer
      size="large"
      onClose={onClose}
      open={open}
      placement="left"
      destroyOnClose={true}
      closeIcon={null}
      title={
        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
          <Col xs={18} sm={16} md={12} lg={16}>
            <Input
              prefix={
                <SearchOutlined style={{ color: token.GreyShadeLight }} />
              }
              allowClear
              onClear={() => dispatch(helpAction.getQuestionByKeyEvent(routeKey, "Web"))}
              autoFocus
              placeholder="How can we help?"
              onChange={(e) => {
                const value = e.target.value;
                if (value.trim()) searchClientFunc(value);
              }}
            />
          </Col>
          <Col
            xs={6}
            sm={8}
            md={12}
            lg={8}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {/* <Input allowClear placeholder="Search..." /> */}
            <CloseOutlined style={{ cursor: "pointer" }} onClick={onClose} />
          </Col>
        </Row>
      }
    >
      {isLoadingQuestionById ? (
        <Spinner />
      ) : allQuestionsForscreen && allQuestionsForscreen?.length > 0 ? (
        <Collapse
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          bordered={false}
          size="small"
          items={items}
        />
      ) : (
        "No Data"
      )}
    </Drawer>
  );
};

export default HelpDrawer;
