import { startLoading, startLoadingAllQuestions, startLoadingQuestionById, stopLoading, stopLoadingAllQuestions, stopLoadingQuestionById } from "../../state/actions";
import { petsMarketingApi } from "../utils/api";
import { petMarketingConstants } from "./action-types";

const addPetSuccess = (data) => {
  return {
    type: petMarketingConstants.ADD_PET_SUCCESS,
    data: data,
  };
};

const addPetErr = (err) => {
  return {
    type: petMarketingConstants.ADD_PET_ERROR,
    valuesError: err,
  };
};

const addPetMarketingEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading());
    petsMarketingApi
      .addPetMarketing(data)
      .then((response) => {
        dispatch(addPetSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(addPetErr(err?.response?.data?.message));
        dispatch(stopLoading());
      });
  };
};

const getPetMarketingSuccess = (data) => {
  return {
    type: petMarketingConstants.GET_ALL_PETS_SUCCESS,
    data: data,
  };
};

const getPetMarketingErr = (err) => {
  return {
    type: petMarketingConstants.GET_ALL_PETS_ERROR,
    valuesError: err,
  };
};

const getPetMarketingEvent = (page) => {
  return (dispatch) => {
    dispatch(startLoadingAllQuestions());
    petsMarketingApi
      .getAllPets(page)
      .then((response) => {
        dispatch(getPetMarketingSuccess(response.data.info));
        dispatch(stopLoadingAllQuestions());
      })
      .catch((err) => {
        dispatch(getPetMarketingErr(err?.response?.data?.message));
        dispatch(stopLoadingAllQuestions());
      });
  };
};

const getPetByIdSuccess = (data) => {
  return {
    type: petMarketingConstants.GET_PET_BY_ID_SUCCESS,
    data: data,
  };
};

const getPetByIdErr = (err) => {
  return {
    type: petMarketingConstants.GET_PET_BY_ID_ERROR,
    valuesError: err,
  };
};

const getPetByIdEvent = (id) => {
  return (dispatch) => {
    dispatch(startLoading());
    petsMarketingApi
      .getPetById(id)
      .then((response) => {
        dispatch(getPetByIdSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getPetByIdErr(err?.response?.data?.message));
        dispatch(stopLoading());
      });
  };
};


const updatePetMarketingSuccess = (data) => {
  return {
    type: petMarketingConstants.UPDATE_PET_SUCCESS,
    data: data,
  };
};

const updatePetMarketingErr = (err) => {
  return {
    type: petMarketingConstants.UPDATE_PET_ERROR,
    valuesError: err,
  };
};

const updatePetMarketingEvent = (id, data) => {
  return (dispatch) => {
    dispatch(startLoadingQuestionById());
    petsMarketingApi
      .updatePetById(id, data)
      .then((response) => {
        dispatch(updatePetMarketingSuccess(response.data.info));
        dispatch(stopLoadingQuestionById());
      })
      .catch((err) => {
        dispatch(updatePetMarketingErr(err?.response?.data?.message));
        dispatch(stopLoadingQuestionById());
      });
  };
};

const clearPetsMarketing = () => {
  return {
    type: petMarketingConstants.CLEAR_PETS_BREEDS,
  };
};

export const petMarketingAction = {
  addPetMarketingEvent,
  getPetMarketingEvent,
  updatePetMarketingEvent,
  getPetByIdEvent,
  clearPetsMarketing
};
