import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { boardCenterAction } from "../State/actions";
import { Form } from "antd";
import useWarnings from "../../Common/Components/useWarnings";

/**
 * @description
 * The `useBoardingCenterMethods` hook provides methods and state for handling boarding center creation and updates.
 *  It manages form data, address details, selected values, and integrates with Redux for dispatching actions related to boarding centers. The hook also includes navigation and warning functionalities.
 * 
 * @returns {Object} The hook returns an object with the following properties and methods:
 * 
 * @property {Function} onFinish - Function to handle form submission. It processes form values, addresses, and selected breeds, then dispatches actions to add or update a boarding center.
 * @property {Function} setMaps - Function to update the `maps` state, which holds location and address details.
 * @property {Object} form - The Ant Design `Form` instance used for managing form data.
 * @property {Object} maps - State object containing location and address details, including latitude, longitude, and full address.
 * @property {Function} setDefaultValue - Function to update the `defaultValue` state.
 * @property {Array} defaultValue - State containing default values for form fields.
 * @property {Function} setSelectedValues - Function to update the `selectedValues` state, which tracks added and removed breeds.
 * @property {ReactNode} contextHolder - The context holder for warnings, used to display warning messages.
 * @property {Function} success - Function to display success messages.
 * @property {Function} error - Function to display error messages.

*/
const useBoardingCenterMethods = () => {
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const { getBoardCenterById } = useSelector((str) => str.boardCenterReducer);
  const [defaultValue, setDefaultValue] = useState([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { commonwarning, contextHolder, success, error } = useWarnings();
  const { id } = useParams();
  const [maps, setMaps] = useState({
    show: false,
    location: "",
    fullLocation: {},
  });
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState({
    addBreeds: [],
    removeBreeds: [],
  });

  const onFinish = (values) => {
    const fullAddress = {
      state: values?.state || maps.fullLocation?.state,
      city: values?.city || maps.fullLocation?.city,
      // district: maps.fullLocation?.district,
      pincode: values?.pincode || maps.fullLocation?.pincode,
      houseName: values?.houseName || maps.fullLocation?.houseName,
      houseNumber: values?.houseNumber || maps.fullLocation?.houseNumber,
      street: values?.street || maps.fullLocation?.street,
      landmark: values?.landmark || maps.fullLocation?.landmark,
    };
    // Filter out empty values from fullAddress
    const filteredFullAddress = Object.fromEntries(
      Object.entries(fullAddress).filter(([_, v]) => v)
    );
    let data = {};
    if (id === undefined) {
      data = {
        name: values.name,
        boardCenterHeadEmail: values.boardCenterHeadEmail,
        services: values.services?.map((serviceName, index) => ({
          serviceName,
          price: values[`price_${serviceName}`],
          totalSlots: values[`slots_${serviceName}`],
          isAvailable: true,
        })),
        address: {
          locationName: values.city,
          locationCoords: {
            lat: maps?.fullLocation.lat,
            lng: maps?.fullLocation.lng,
          },
          eLoc: maps.fullLocation?.eLoc,
          fullAddress: filteredFullAddress,
          pincode: maps.fullLocation?.pincode,
        },
        // globalCapAmount: values?.globalCapAmount,
        capAmount: values?.capAmount,
      };
    } else {
      if (values.name !== getBoardCenterById.name) {
        data.name = values.name;
      }
      if (values.boardCenterHeadEmail !== getBoardCenterById.boardCenterHead) {
        data.boardCenterHead = values.boardCenterHeadEmail;
      }

      // if (values?.globalCapAmount !== getBoardCenterById.globalCapAmount) {
      //   data.globalredeemCapPoints = {
      //     "capAmount": values?.globalCapAmount,
      //     "isActive": true
      //   }
      // }

      if (values?.capAmount !== getBoardCenterById.capAmount) {
        data.redeemCapPoints = {
          "capAmount": values?.capAmount,
          "isActive": true
        }
      }

      // const updatedServices = values.services.map((serviceName, index) => ({
      //   serviceName,
      //   isAvailable: true,
      //   price: values[`price_${serviceName}`],
      //   totalSlots: values[`slots_${serviceName}`]
      // }));
      // if (
      //   JSON.stringify(updatedServices) !==
      //   JSON.stringify(getBoardCenterById.services)
      // ) {
      //   data.services = updatedServices;
      // }
      const updatedServices = values.services.map((serviceName) => {
        return {
          serviceName,
          isAvailable: true,
          price: values[`price_${serviceName}`],
          totalSlots: values[`slots_${serviceName}`],
        };
      });

      const getServicesByName = (servicesArray) =>
        servicesArray.reduce((acc, service) => {
          acc[service.serviceName] = service;
          return acc;
        }, {});

      const boardCenterServicesByName = getServicesByName(getBoardCenterById.services);
      const updatedServicesByName = getServicesByName(updatedServices);

      const finalServices = [];

      // Handle services in `values` (for add or update actions)
      updatedServices.forEach((updatedService) => {
        const existingService = boardCenterServicesByName[updatedService.serviceName];

        if (!existingService) {
          // New service, mark it as 'add'
          finalServices.push({
            ...updatedService,
            action: 'add',
          });
        } else if (
          existingService.price !== updatedService.price ||
          existingService.totalSlots !== updatedService.totalSlots
        ) {
          // Service exists but has changes, mark it as 'update'
          finalServices.push({
            ...updatedService,
            action: 'update',
          });
        }
      });

      // Handle services in `getBoardCenterById.services` (for remove action)
      getBoardCenterById.services.forEach((existingService) => {
        if (!updatedServicesByName[existingService.serviceName]) {
          // Service is missing in `values`, mark it as 'remove'
          finalServices.push({
            ...existingService,
            action: 'remove',
          });
        }
      });

      // Set the `services` field in the `data` object only if there are changes
      if (finalServices.length > 0) {
        data.services = finalServices;
      }
      data.address = {
        locationName: maps.fullLocation.city,
        locationCoords: {
          lat: maps?.fullLocation.lat,
          lng: maps?.fullLocation.lng,
        },
        eLoc: maps.fullLocation?.eLoc,
        fullAddress: filteredFullAddress,
        pincode: maps.fullLocation?.pincode,
      };
      if (
        selectedValues.addBreeds.length > 0 ||
        selectedValues.removeBreeds.length > 0
      ) {
        data.pets = [
          ...selectedValues.addBreeds,
          ...selectedValues.removeBreeds,
        ];
      }

      if (values?.addCaptains) {
        data.addCaptains = values?.addCaptains
      }
    }

    if (id) {
      if (Object.keys(data).length === 0)
        return commonwarning("There is nothing to update");
      else dispatch(boardCenterAction.boardCenterUpdateEvent(id, data));
    } else {
      dispatch(boardCenterAction.addBoardCenterEvent(data));
    }
    form.resetFields();
    setDefaultValue([]);
    if (id && userDetails.currentRole === "ADMIN") navigate(`/boardingCenter/${id}`);
    else navigate("/allBoardingCenters");
  };

  return {
    onFinish,
    setMaps,
    form,
    maps,
    setDefaultValue,
    defaultValue,
    setSelectedValues,
    contextHolder,
    success,
    error,
  };
};

export default useBoardingCenterMethods;
