import React, { useState } from "react";
import { Typography } from "antd";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CommonModal from "../../CommonModal/CommonModal";
import MyCalendar from "./MyCalendar";
import { formatDateToUTC } from "./FormatDateToUTC";
import styles from "../Styles/details.module.css";
import dayjs from "dayjs";
const { Text } = Typography;

/**
 * @component
 * @description This component is responsible for selecting and managing the start and end date and time for a particular service.
 * It allows users to choose start and end times through a modal, which displays a calendar and available time slots.
 * The component also handles the conversion of selected date and time to UTC format before updating the parent state.
 *
 * @param {Function} getTimeSlotsForParticularMonth - Function to fetch available time slots for the selected month.
 * @param {string} serviceName - The name of the service for which the date and time are being selected.
 * @param {Function} setDateTime - Function to update the selected date and time in the parent state.
 * @param {Object} dateTime - The current selected date and time for the service, containing `checkInDateTime` and `checkOutDateTime`.
 */

const DateTime = ({
  getTimeSlotsForParticularMonth,
  serviceName,
  setDateTime,
  dateTime,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTimeSlots, setShowTimeSlots] = useState(false);
  const [date, setDate] = useState("");
  const [key, setKey] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(dayjs());

  const showModal = (value) => {
    setKey(value === "startDate" ? "startDate" : "endDate");
    getTimeSlotsForParticularMonth({ date: dayjs(), serviceName: serviceName });
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setShowTimeSlots(false);
    setCurrentMonth(dayjs());
  };
  const handleTime = (time) => {
    handleCancel();
    let res = formatDateToUTC(date, time?.time, key);
    if (key === "startDate") {
      setStartDate(date + " " + time?.time);
    } else {
      setEndDate(date + " " + time?.time);
    }
    setDateTime((prev) => ({
      ...prev,
      [serviceName]: {
        ...(prev[serviceName] || {}), // Preserve existing data
        [key === "startDate" ? "checkInDateTime" : "checkOutDateTime"]: res, // Update only the necessary part
      },
    }));
  };

  return (
    <>
      <Text style={{ display: "block" }}>
        Start Date and Time:{" "}
        <span className={styles.select} onClick={() => showModal("startDate")}>
          {dateTime?.[serviceName]?.checkInDateTime
            ? dayjs(dateTime?.[serviceName]?.checkInDateTime).format(
                "MMM D, YYYY h:mm A"
              )
            : "Select start date and time"}
        </span>{" "}
      </Text>
      <Text style={{ display: "block" }}>
        End Date and Time:{" "}
        <span className={styles.select} onClick={() => showModal("endDate")}>
          {dateTime?.[serviceName]?.checkOutDateTime
            ? dayjs(dateTime?.[serviceName]?.checkOutDateTime).format(
                "MMM D, YYYY h:mm A"
              )
            : "Select end date and time"}
        </span>{" "}
      </Text>
      <CommonModal
        maskClosable={true}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        width={500}
        body={() => (
          <MyCalendar
            showTimeSlots={showTimeSlots}
            handleTime={handleTime}
            setShowTimeSlots={setShowTimeSlots}
            setDate={setDate}
            getTimeSlotsForParticularMonth={getTimeSlotsForParticularMonth}
            serviceName={serviceName}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      />
    </>
  );
};

export default DateTime;
