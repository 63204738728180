import React, { useEffect } from "react";
import { Button, Table, Tabs } from "antd";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { FilterOutlined, SyncOutlined } from "@ant-design/icons";
import useColumns from "./Components/useColumns";
import { adminAction } from "./state/actions";
import { useDispatch, useSelector } from "react-redux";
import useServiceDetails from "./Components/useServiceDetails";
import { useRoute } from "../context/RouteContext";

/**
 * ServiceDetails component to manage and display appointment bookings
 * in different statuses (New, In Process, Declined).
 * @returns {JSX.Element} The rendered ServiceDetails component.
 */

const ServiceDetails = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoading, isLoadingAllConfirmedBookings } = useSelector(
    (str) => str.loadingReducer
  );
  const { setSelectedKey } = useRoute();
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const {
    handleSync,
    activekey,
    bookingCount,
    loadMoreData,
    page,
    setPage,
  } = useServiceDetails();
  const {
    newAppointmentColumns,
    declinedAppointmentColumns,
    onprocessAppointmentColumns,
    setFiltered,
    handleClearFilters,
  } = useColumns();
  const { getNewConfirmedBookings } = useSelector(
    (state) => state.adminDashboardReducer
  );
  const [searchParams] = useSearchParams();

  // Extract confirmed bookings data for display
  const confirmedBookings =
    getNewConfirmedBookings &&
    getNewConfirmedBookings.map((booking) => booking.parentPetInfo);

  const handleTableChange = (_, newFilters) => {
    let navigateTo = `/services/${type}`;
    const searchParams = new URLSearchParams(location.search);
    if (newFilters?.services === null) {
      setFiltered((prev) => ({ ...prev, services: false }));
      searchParams.delete("services");
      navigateTo += `?${searchParams.toString()}`;
      navigate(navigateTo);
      const search = Array.from(searchParams.entries()).reduce(
        (acc, [key, val]) => {
          acc[key] = val;
          return acc;
        },
        {}
      );

      dispatch(
        adminAction.getConfirmedBookingDetails(
          userDetails.boardCenterUniqueId,
          {
            // boardCenterId: userDetails.boardingCenter,
            page: 1,
            status: type,
            ...search,
          }
        )
      );
    } else {
      setFiltered((prev) => ({ ...prev, services: true }));
      searchParams.set("services", newFilters?.services?.[0]);
      navigateTo += `?${searchParams.toString()}`;
      navigate(navigateTo);
      const search = Array.from(searchParams.entries()).reduce(
        (acc, [key, val]) => {
          acc[key] = val;
          return acc;
        },
        {}
      );

      dispatch(
        adminAction.getConfirmedBookingDetails(
          userDetails.boardCenterUniqueId,
          {
            // boardCenterId: userDetails.boardingCenter,
            page: 1,
            status: type,
            ...search,
          }
        )
      );
    }
  };

  const createTabItem = (key, label, columns) => ({
    key,
    label,
    children: (
      <Table
        columns={columns}
        dataSource={confirmedBookings}
        pagination={false}
        onChange={handleTableChange}
        bordered
        loading={isLoadingAllConfirmedBookings || isLoading}
        onScroll={(e) => {
          const { scrollTop, scrollHeight, clientHeight } = e.target;
          if (scrollTop + clientHeight >= scrollHeight - 50) {
            if (!isLoadingAllConfirmedBookings) {
              const nextPage = page + 1;
              setPage(nextPage);
              loadMoreData(nextPage, activekey);
            }
          }
        }}
        scroll={{ y: 500 }}
      />
    ),
  });

  const items = [
    createTabItem("newAppointments", "New Appointments", newAppointmentColumns),
    createTabItem(
      "inProcessAppointments",
      "In Process Appointments",
      onprocessAppointmentColumns
    ),
    createTabItem(
      "declinedAppointments",
      "Declined Appointments",
      declinedAppointmentColumns
    ),
  ];

  /**
   * Handles tab changes, clears all data, and resets pagination and filters.
   * @param {string} key - The key of the active tab.
   */
  const onChange = (key) => {
    dispatch(adminAction.clearAlldata());
    navigate(`/services/${key}`);
    setPage(1);
    setFiltered({ id: false, details: false, services: false });
  };

  useEffect(() => {
    setSelectedKey("SERVICE_DETAILS");
  }, []);

  return (
    <div style={{ overflow: "auto" }}>
      <Tabs
        tabBarExtraContent={{
          left: (
            <Button
              onClick={handleSync}
              disabled={bookingCount.count === 0}
              size="small"
              type="primary"
              icon={<SyncOutlined />}
            >
              Sync {bookingCount.count}
            </Button>
          ),
          right: (
            <Button
              onClick={() => handleClearFilters(activekey)}
              size="small"
              iconPosition="end"
              disabled={searchParams.size === 0}
              icon={<FilterOutlined />}
            >
              Clear filters
            </Button>
          ),
        }}
        activeKey={activekey}
        centered
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

export { ServiceDetails };
