import { Button, Form, Input } from "antd";
import React from "react";
const { TextArea } = Input;

const ActiveDeactiveBody = ({ onFinish }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item name="reason" rules={[{ required: true }]}>
        <TextArea rows={3} placeholder="Please type valid reason..." />
      </Form.Item>
      <Form.Item style={{ textAlign: "end" }}>
        <Button type="primary" size="small" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ActiveDeactiveBody;
