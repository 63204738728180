import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Popconfirm, Row, Typography, theme } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { setDrawerOpen } from "../../state/actions";
import Spinner from "../../Spinner/Spinner";
import { v4 as uuidv4 } from "uuid";
import useWarnings from "../../Common/Components/useWarnings";
import { settingsApi } from "../utils/api";
import { settingsAction } from "../State/actions";
import { addressAction } from "../../Common/State/adressactions";
const { Text, Title, Paragraph } = Typography;

/**
 * @description
 * The `Addresses` component is responsible for displaying a list of user addresses in a card format.
 * Users can manage their addresses by editing or deleting them. Each address is displayed with an icon,
 * a title, and a truncated view of the full address.
 * @returns {JSX.Element} The rendered Addresses component.
 */

const Addresses = () => {
  const { alladdress } = useSelector((str) => str.addressReducer);
  const { isLoadingAddresses } = useSelector((str) => str.loadingReducer);
  const dispatch = useDispatch();
  const { useToken } = theme;
  const { token } = useToken();
  const { error, success, contextHolder } = useWarnings();
  const { userDetails } = useSelector(str => str.navbarReducer);

  const handleDelete = async (id) => {
    const currentAddress = userDetails?.address?.find(e => e.currentLocation === true);
    if (currentAddress?.addressId?.addressUniqueId === id) {
      return error('Current location cannot be deleted.')
    }
    try {
      const {
        data: { info },
      } = await settingsApi.deleteAddress(id);
      dispatch(addressAction.getAddressesEvent());
      success(info);
    } catch (err) {
      error(err?.response?.data?.message);
    }
  };

  if (isLoadingAddresses) {
    return <Spinner />;
  }

  return (
    <div>
      {contextHolder}
      <Title style={{ fontSize: "2em" }}>Manage Addresses</Title>
      <Row gutter={[16, 16]}>
        {alladdress?.map((address) => (
          <Col key={uuidv4()} lg={11}>
            <Card
              hoverable
              style={{ borderRadius: 0, borderColor: "lightgrey", height: "100%" }}
            >
              <Row style={{ alignItems: "baseline" }}>
                <Col span={2}>
                  <HomeOutlined style={{ fontSize: "1.5em" }} />
                </Col>
                <Col span={22}>
                  <Text
                    style={{
                      fontWeight: 500,
                      fontSize: "1.2em",
                      marginBottom: "1.2em",
                    }}
                  >
                    Home{" "}
                  </Text>
                  <Paragraph
                    ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
                  >
                    {address?.fullAddress}
                  </Paragraph>
                  <Text
                    style={{
                      marginRight: "15px",
                      fontWeight: 500,
                      color: token.appbluecolor,
                    }}
                    onClick={() => dispatch(setDrawerOpen("edit", address))}
                  >
                    EDIT
                  </Text>
                  <Popconfirm
                    title="Are you sure you want to delete this address?"
                    onConfirm={() => handleDelete(address?.addressUniqueId)}
                  >
                    <Text
                      style={{
                        marginRight: "15px",
                        fontWeight: 500,
                        color: token.Red,
                      }}
                    >
                      DELETE
                    </Text>
                  </Popconfirm>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Addresses;
