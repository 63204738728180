import { startLoading, startLoadingNotifications, startLoadinguserDetails, stopLoading, stopLoadingNotifications, stopLoadingUserDetails } from "../../state/actions";
import { navbarApi } from "../utils/api";
import { navbarConstants } from "./action-types";

const getUserDetailsError = (err) => {
    return {
        type: navbarConstants.GET_USER_DETAILS_ERROR,
        valuesError: err,
    };
};

const getUserDetailsSuccess = (data) => {
    return {
        type: navbarConstants.GET_USER_DETAILS_SUCCESS,
        data: data,
    };
};

const getUserDetails = () => {
    return (dispatch) => {
        dispatch(startLoadinguserDetails());
        navbarApi.getUserDetails()
            .then((response) => {
                dispatch(getUserDetailsSuccess(response?.data?.info));
                dispatch(stopLoadingUserDetails());
            })
            .catch((err) => {
                dispatch(getUserDetailsError(err));
                dispatch(stopLoadingUserDetails());
            });
    };
};

const sendNotificationError = (err) => {
    return {
        type: navbarConstants.SEND_NOTIFICATION_ERROR,
        valuesError: err,
    };
};

const sendNotificationSSuccess = (data) => {
    return {
        type: navbarConstants.SEND_NOTIFICATION_SUCCESS,
        data: data,
    };
};

const sendNotificationsEvent = (data, token) => {
    return (dispatch) => {
        navbarApi.sendNotificationToServer(data)
            .then((response) => {
                dispatch(sendNotificationSSuccess(response?.data?.info));
                localStorage.setItem("fcmToken", data?.token);
            })
            .catch((err) => {
                dispatch(sendNotificationError(err));
            });
    };
};

const logoutError = (err) => {
    return {
        type: navbarConstants.LOGOUT_FAILURE,
        valuesError: err
    }
}

const logoutSuccess = (data) => {
    return {
        type: navbarConstants.LOGOUT_SUCCESS,
        data: data
    }
}

const logoutEvent = () => {
    return (dispatch) => {
        // dispatch(startLoading());
        navbarApi.logoutUser().then(response => {
            dispatch(logoutSuccess(response?.data?.info));
            // dispatch(stopLoading());
        }).catch(err => {
            dispatch(logoutError(err));
            // dispatch(stopLoading());
        })
    }
}

const getNotificationsError = (err) => {
    return {
        type: navbarConstants.GET_NOTIFICATIONS_ERROR,
        valuesError: err
    }
}

const getNotificationSuccess = (data) => {
    return {
        type: navbarConstants.GET_NOTIFICATIONS_SUCCESS,
        data: data
    }
}

const getNotificationsEvent = (page) => {
    return (dispatch) => {
        dispatch(startLoadingNotifications());
        navbarApi.getNotifications(page).then(response => {
            dispatch(getNotificationSuccess(response?.data?.info));
            dispatch(stopLoadingNotifications());
        }).catch(err => {
            dispatch(getNotificationsError(err));
            dispatch(stopLoadingNotifications());
        })
    }
}

const updateNotificationsError = (err) => {
    return {
        type: navbarConstants.UPDATE_NOTIFICATION_ERROR,
        valuesError: err
    }
}

const updateNotificationSuccess = (data) => {
    return {
        type: navbarConstants.UPDATE_NOTIFICATION_SUCCESS,
        data: data
    }
}

const updateNotificationsEvent = (id, data) => {
    return (dispatch) => {
        dispatch(startLoadingNotifications());
        navbarApi.updateNotification(id, data).then(response => {
            // console.log(response, "response")
            dispatch(updateNotificationSuccess(response?.data?.info));
            // dispatch(getNotificationsEvent())
            dispatch(stopLoadingNotifications());
        }).catch(err => {
            dispatch(updateNotificationsError(err));
            dispatch(stopLoadingNotifications());
        })
    }
}

const markNotificationsError = (err) => {
    return {
        type: navbarConstants.MARK_ALL_AS_READ_ERROR,
        valuesError: err
    }
}

const markNotificationsSuccess = (data) => {
    return {
        type: navbarConstants.MARK_ALL_AS_READ_SUCCESS,
        data: data
    }
}

const markAllNotificationsEvent = () => {
    return (dispatch) => {
        dispatch(startLoadingNotifications());
        navbarApi.markAllNotificationsAsRead().then(response => {
            dispatch(markNotificationsSuccess(response?.data?.info));
            // dispatch(getNotificationsEvent(1))
            dispatch(stopLoadingNotifications());
        }).catch(err => {
            dispatch(markNotificationsError(err));
            dispatch(stopLoadingNotifications());
        })
    }
}

const notificationCountError = (err) => {
    return {
        type: navbarConstants.GET_NOTIFICATION_COUNT_ERROR,
        valuesError: err
    }
}

const notificationCountSuccess = (data) => {
    return {
        type: navbarConstants.GET_NOTIFICATION_COUNT_SUCCESS,
        data: data
    }
}

const getNotificationCountEvent = () => {
    return (dispatch) => {
        // dispatch(startLoadingNotifications());
        navbarApi.getNotificationCount().then(response => {
            dispatch(notificationCountSuccess(response?.data?.info.notificationCount));
            // dispatch(stopLoadingNotifications());
        }).catch(err => {
            dispatch(notificationCountError(err));
            // dispatch(stopLoadingNotifications());
        })
    }
}



const userDetailsReset = () => {
    return {
        type: navbarConstants.RESET_USER_DETAILS,
    };
};

const markNotificationsReset = () => {
    return {
        type: navbarConstants.CLEAR_MARK_NOTIFICATIONS,
    };
};


const clearNotifications = () => {
    return {
        type: navbarConstants.CLEAR_NOTIFICATIONS,
    };
}

export const navbarAction = {
    getUserDetails,
    logoutEvent,
    userDetailsReset,
    sendNotificationsEvent,
    getNotificationsEvent,
    updateNotificationsEvent,
    markAllNotificationsEvent,
    getNotificationCountEvent,
    clearNotifications,
    markNotificationsReset
};

