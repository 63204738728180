import { userConstants } from "./action-type";

const initialState = {
  getUsers: false,
  getUsersErr: false,
  totalUsers: 0,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.GET_USERS_SUCCESS:
      return {
        ...state,
        getUsers:
          action.data.currentPage === 1
            ? action.data.allUsers
            : [...state.getUsers, ...action.data.allUsers],
        getUsersErr: false,
        totalUsers: action.data.totalUsers,
      };
    case userConstants.GET_USERS_ERROR:
      return {
        ...state,
        getUsers: false,
        getUsersErr: action.valuesError,
        totalUsers: 0,
      };
    default:
      return state;
  }
};
export { usersReducer };
