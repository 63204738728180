
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { verifySignature } from "../../Common/Verify";
const PrivateRouteForPublicRoutes = () => {
    const token = localStorage.getItem("authToken");
    const authentication = verifySignature(token);

    if (!authentication) {
       return <Outlet />
    }
    
    return (
        <Navigate to="/" />
    );
};

export { PrivateRouteForPublicRoutes };
