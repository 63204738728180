import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Form, theme } from "antd";
import { EditOutlined } from "@ant-design/icons";
import CommonModal from "../../CommonModal/CommonModal";
import ParentDetails from "../../Common/Components/ParentDetails";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "../../Common/State/actions";
import styles from "../Styles/finalPage.module.css";
import useWarnings from "../../Common/Components/useWarnings";

const { Title } = Typography;

/**
 * @component FinalParentDetails
 * @description This component displays the details of a parent and allows editing them through a modal.
 * It shows information such as the parent's name, contact details, gender, location, and identification.
 * The component also manages the state for the modal visibility and form submission.
 * The parent details can be updated
 * @param {Object} details - The parent details to display and edit.
 */

const FinalParentDetails = ({ details }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idType, setIdType] = useState("");
  const [idPattern, setIdPattern] = useState("");
  const { useToken } = theme;
  const { token } = useToken();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const { updatedProfileDetails, updatedProfileDetailsErr } = useSelector(
    (str) => str.profileReducer
  );
  const { success, error, contextHolder } = useWarnings();

  // Handle form submissio
  const onFinish = (values) => {
    setIsModalOpen(false);
    // Check for changes in each field
    let changedData = {};
    for (const key in values) {
      if (values[key] !== details[key]) {
        if (key === "phoneNumber") {
          if (values[key] && values[key].trim() !== "") {
            const phoneNumber = values[key];
            const countryCode = phoneNumber.substring(
              0,
              phoneNumber.length - 10
            );
            const formattedPhoneNumber = phoneNumber.substring(
              phoneNumber.length - 10
            );
            changedData["countryCode"] = countryCode;
            changedData["phoneNumber"] = formattedPhoneNumber;
          }
        } else {
          if (key === "profileImage" && values["profileImage"] === null) {
            changedData["removeProfileImage"] = true;
          }
          changedData[key] = values[key];
        }
      }
    }
    dispatch(profileAction.updateParentProfileEvent(changedData));
  };

  useEffect(() => {
    if (updatedProfileDetails) {
      success("updated successfully!");
      dispatch(profileAction.cleardetailsProfile());
    } else if (updatedProfileDetailsErr) {
      error(updatedProfileDetailsErr);
      dispatch(profileAction.cleardetailsProfile());
    }
  }, [updatedProfileDetails, updatedProfileDetailsErr]);

  return (
    <>
      {contextHolder}
      <Row
        justify="space-between"
        align="middle"
        className={styles.displayedit}
      >
        <Title level={3} style={{ marginTop: 0, color: token.Primary }}>
          Parent Details
        </Title>
        <EditOutlined
          style={{ fontSize: "1.2em", color: token.Primary }}
          onClick={() => setIsModalOpen(true)}
        />

        {/* Modal for editing parent details */}
        <CommonModal
          width={1000}
          body={() => (
            <ParentDetails
              parentDetails={details}
              form={form}
              onFinish={onFinish}
              setIdPattern={setIdPattern}
              setIdType={setIdType}
              idType={idType}
              idPattern={idPattern}
              from={"finalstep"}
              setIsModalOpen={setIsModalOpen}
            />
          )}
          handleOk={() => setIsModalOpen(false)}
          handleCancel={() => setIsModalOpen(false)}
          isModalOpen={isModalOpen}
        />
      </Row>

      {/* Display parent details */}
      <Row wrap>
        {[
          { label: "First Name", value: details?.firstName },
          { label: "Last Name", value: details?.lastName },
          {
            label: "Contact Number",
            value: details?.contactNumber
              ? `+${details.contactNumber.countryCode} ${details.contactNumber.phoneNumber}`
              : "--",
          },
          { label: "Email", value: details?.email },
          { label: "Gender", value: details?.gender },
          {
            label: "Location",
            value: userDetails?.usersCurrentAddressDetails?.fullAddress || "--",
          },
          {
            label: "Identification",
            value: `${details?.identification || "--"} - ${details?.indentificationValue || "--"
              }`,
          },
        ].map((item, index) => (
          <React.Fragment key={index}>
            <Col span={10} className={styles.col}>
              {item.label}
            </Col>
            <Col span={2} className={styles.col}>
              :
            </Col>
            <Col span={12} className={styles.col}>
              {item.value || "--"}
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </>
  );
};

export default FinalParentDetails;
