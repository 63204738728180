// DefaultLayout.js
import React, { Suspense, useState } from "react";
import { Button, Layout, Menu } from "antd";
import { Outlet } from "react-router-dom";
import { Navbar } from "../../Navbar";
import Sidebar from "../../Navbar/Components/Sidebar";
import AddressDrawer from "./AddressDrawer";
import Spinner from "../../Spinner/Spinner";

const { Content, Header } = Layout;

const DefaultLayout = () => {
  const [finalAddress, setFinalAddress] = useState("");
  const [collapsed, setCollapsed] = useState(true);
  // const { userDetails } = useSelector((str) => str.navbarReducer);
  return (
    // <Layout>
    //   <Navbar
    //     // displayName={userDetails?.firstName}
    //     finalAddress={finalAddress}
    //   />
    //   <Layout>
    //     <Sidebar />
    //     <Content style={{ marginLeft: "20px", marginRight: "20px" }}>
    //       <Outlet />
    //     </Content>
    //   </Layout>
    //   <AddressDrawer setFinalAddress={setFinalAddress} />
    // </Layout>
    <Layout>
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        <Navbar finalAddress={finalAddress} collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          style={{
            padding: "0px 24px",
            // overflow: "scroll"
            // minHeight: 100 % -80,
          }}
        >
          {/* <Suspense fallback={<Spinner />}> */}
            <Outlet />
          {/* </Suspense> */}
        </Content>
      </Layout>
      <AddressDrawer setFinalAddress={setFinalAddress} />
    </Layout>
  );
};

export default DefaultLayout;
