import { appconstants } from "./action-types";
export const startLoading = () => ({
    type: appconstants.START_LOADING
})

export const stopLoading = () => ({
    type: appconstants.STOP_LOADING
});

export const startLoadingActionCenter = () => ({
    type: appconstants.START_LOADING_ACTION_CENTER
})

export const stopLoadingActionCenter = () => ({
    type: appconstants.STOP_LOADING_ACTION_CENTER
});

export const startLoadingFinalBookAppointDetails = () => ({
    type: appconstants.START_LOADING_FINALBOOKAPPOINTMENT_DETAILS
})

export const stopLoadingFinalBookAppointDetails = () => ({
    type: appconstants.STOP_LOADING_FINALBOOKAPPOINTMENT_DETAILS
});

export const startLoadingUpdatedServices = () => ({
    type: appconstants.START_LOADING_UPDATED_SERVICES
})

export const stopLoadingUpdatedServices = () => ({
    type: appconstants.STOP_LOADING_UPDATED_SERVICES
});

export const startLoadingMaps = () => ({
    type: appconstants.START_LOADING_MAPS
})

export const stopLoadingMaps = () => ({
    type: appconstants.STOP_LOADING_MAPS
});

export const startLoadingAllAddress = () => ({
    type: appconstants.START_LOADING_ALL_ADDRESSES
})

export const stopLoadingAllAddress = () => ({
    type: appconstants.STOP_LOADING_ALL_ADDRESSES
});

export const startLoadingBoardingCenter = () => ({
    type: appconstants.START_LOADING_BOARDING_CENTER
})

export const stopLoadingBoardingCenter = () => ({
    type: appconstants.STOP_LOADING_BOARDING_CENTER
});

export const startLoadinguserDetails = () => ({
    type: appconstants.START_LOADING_USER_DETAILS
})

export const stopLoadingUserDetails = () => ({
    type: appconstants.STOP_LOADING_USER_DETAILS
});

export const startLoadingSlots = () => ({
    type: appconstants.START_LOADING_SLOTS
})

export const stopLoadingSlots = () => ({
    type: appconstants.STOP_LOADING_SLOTS
});

export const setName = (name) => ({
    type: appconstants.SET_NAME,
    name: name
});
export const resetName = () => ({
    type: appconstants.RESET_NAME,
})

export const setDrawerOpen = (data, address) => ({
    type: appconstants.SET_DRAWER_OPEN,
    payload: data,
    address: address,
})
export const resetDrawer = () => ({
    type: appconstants.RESET_DRAWER,
})

export const startLoadingbooking = (data) => ({
    type: appconstants.START_LOADING_BOOKING,
    payload: data
})

export const stopLoadingBooking = (data) => ({
    type: appconstants.STOP_LOADING_BOOKING,
    payload: data
});

export const startTrackingMapsLoading = () => ({
    type: appconstants.START_TRACKING_LOADING
});

export const stopTrackingLoading = () => ({
    type: appconstants.STOP_LOADING_TRACKING
});

export const startLoadingNotifications = () => ({
    type: appconstants.START_LOADING_NOTIFICATIONS
});

export const stopLoadingNotifications = () => ({
    type: appconstants.STOP_LOADING_NOTIFICATIONS
});

export const startLoadingRequestOtp = () => ({
    type: appconstants.START_LOADING_REQUEST_OTP
})

export const stopLoadingRequestOtp = () => ({
    type: appconstants.STOP_LOADING_REQUEST_OTP
})

export const startLoadingAllQuestions = () => ({
    type: appconstants.START_LOADING_GET_ALL_QUESTIONS
})

export const stopLoadingAllQuestions = () => ({
    type: appconstants.STOP_LOADING_GET_ALL_QUESTIONS
})

export const startLoadingQuestionById = () => ({
    type: appconstants.START_LOADING_QUESTION_BY_ID
})

export const stopLoadingQuestionById = () => ({
    type: appconstants.STOP_LOADING_QUESTION_BY_ID
})

export const startLoadingVerifyOtp = () => ({
    type: appconstants.START_LOADING_VERIFY_OTP
})

export const stopLoadingVerifyOtp = () => ({
    type: appconstants.STOP_LOADING_VERIFY_OTP
})

export const startLoadingCaptains = () => ({
    type: appconstants.START_LOADING_CAPTAIN_DETAILS_EVENT
})

export const stopLoadingCaptains = () => ({
    type: appconstants.STOP_LOADING_CAPTAIN_DETAILS_EVENT
})

export const startLoadingKennels = () => ({
    type: appconstants.START_LOADING_KENNELS
})

export const stopLoadingKennels = () => ({
    type: appconstants.STOP_LOADING_KENNELS
})

export const startLoadingParentBoardingCenter = () => ({
    type: appconstants.START_LOADING_PARENT_BOARDING_CENTER_DETAILS
})

export const stopLoadingParentBoardingCenter = () => ({
    type: appconstants.STOP_LOADING_PARENT_BOARDING_CENTER_DETAILS
})

export const startLoadingAllBoardingCenters = () => ({
    type: appconstants.START_LOADING_ALL_BOARD_CENTERS
})

export const stopLoadingAllBoardingCenters = () => ({
    type: appconstants.STOP_LOADING_ALL_BOARD_CENTERS
})

export const startLoadingAllBookings = () => ({
    type:appconstants.START_LOADING_ALL_BOOKINGS
})

export const stopLoadingAllBookings = () => ({
    type:appconstants.STOP_LOADING_ALL_BOOKINGS
})

export const startLoadingAllConfirmedBookings = () => ({
    type:appconstants.START_LOADING_ALL_CONFIRMED_BOOKINGS
})

export const stopLoadingAllConfirmedBookings = () => ({
    type:appconstants.STOP_LOADING_ALL_CONFIRMED_BOOKINGS
})

export const startLoadingAllBookingsForSupport = () => ({
    type:appconstants.START_LOADING_ALL_BOOKINGS_SUPPORT
})

export const stopLoadingAllBookingsForSupport = () => ({
    type:appconstants.STOP_LOADING_ALL_BOOKINGS_SUPPORT
})