import {
    Button,
    Col,
    Collapse,
    Radio,
    Row,
    Switch,
    theme,
    Typography,
} from "antd";
import React from "react";
import styles from "../Styles/subscriptionDetails.module.css";
import { CaretRightOutlined, MoonOutlined, RightOutlined, SunOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Passwords from "./Passwords";
import { useTheme } from "../../theme/ThemeToggle";
import LanguageItems from "../../Navbar/Components/LanguageItems";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const More = () => {
    const navigate = useNavigate();
    const { useToken } = theme;
    const { token } = useToken();
    const { isDarkMode, toggleTheme } = useTheme();
    const languageItems = LanguageItems();

    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        sessionStorage.setItem("locale", lng);
    };

    const items = [
        {
            key: "1",
            label: <Text style={{ fontWeight: 500 }}>Update your password</Text>,
            children: <Passwords />,
        },
        {
            key: "2",
            label: <Text style={{ fontWeight: 500 }}>Switch Appearance</Text>,
            children: (
                <>
                    <Text style={{ marginRight: "1.2em" }}>
                        {isDarkMode ? "Dark mode" : "Light Mode"}
                    </Text>
                    <Switch
                        size="small"
                        style={{ backgroundColor: token.Gray }}
                        unCheckedChildren={<MoonOutlined />}
                        checkedChildren={<SunOutlined />}
                        defaultChecked
                        onChange={toggleTheme}
                    />
                </>
            ),
        },
        {
            key: "3",
            label: (
                <Text style={{ fontWeight: 500 }}>Choose your preferred language</Text>
            ),
            children: (
                <Radio.Group
                    value={sessionStorage.getItem("locale") || "en"}
                    onChange={(e) => changeLanguage(e.target.value)}
                >
                    {languageItems?.map((language) => (
                        <Radio value={language?.key}>{language?.label}</Radio>
                    ))}
                </Radio.Group>
            ),
        },
    ];

    return (
        <Row justify={"center"}>
            <Col xs={22} sm={20} md={20} lg={18}>
                <Collapse
                    accordion
                    expandIconPosition="end"
                    size="small"
                    items={items}
                    expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                />
            </Col>
        </Row>
    );
};

export default More;
