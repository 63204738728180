import { CaretDownOutlined, TransactionOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Flex,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Progress,
  Row,
  theme,
  Typography,
} from "antd";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dashboardAction } from "../State/actions";
import useWarnings from "../../Common/Components/useWarnings";
import { useSocket } from "../../context/SocketContext";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useMediaQuery } from "react-responsive";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Strings from "../../Constants/Strings";
import ActionCenterCardHeader from "./ActionCenterCardHeader";
const { TextArea } = Input;
const { Text, Title, Paragraph } = Typography;

dayjs.extend(duration);

/**
 * @description
 * The `ActionCenterCard` component renders a card for each booking action in the dashboard.
 * It provides options to reschedule, cancel, or track a booking, and displays relevant
 * booking details like pet name, booking ID, and selected services.
 *
 * @param {Object} props.action - The booking action data.
 */

const ActionCenterCard = ({ action }) => {
  const { t } = useTranslation("home");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const bookingIdTobecancelled = useRef();
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();
  const { currentSocket } = useSocket();
  const { error, contextHolder } = useWarnings();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ maxWidth: 576 });
  const { useToken } = theme;
  const { token } = useToken();

  const calculateTimeRemaining = (expiry) => {
    const now = dayjs();
    const expiryTime = dayjs(expiry);
    const diff = expiryTime.diff(now, "second");
    return Math.max(0, diff);
  };

  const showModal = (id) => {
    setIsModalOpen(true);
    bookingIdTobecancelled.current = id;
  };
  const handleCancel = () => setIsModalOpen(false);

  const handleTrack = (bookingId) => {
    currentSocket.emit("booking_connected", {
      bookingUniqueId: bookingId,
    });
    navigate(`/track/${bookingId}`);
  };

  const items = [
    {
      key: "reschedule",
      label:
        action.step > 1 ? (
          <Text
            onClick={() => {
              localStorage.setItem("bookingID", action?.bookingID);
              navigate("/book-appointment/step-2");
            }}
          >
            Reschedule Booking
          </Text>
        ) : (
          <Text>Not allowed to reschedule</Text>
        ),
    },
    {
      key: "cancel",
      label: !action?.status?.cancellation?.requested ? (
        <>
          <Text onClick={() => showModal(action?.bookingID)}>
            Request to cancel Booking
          </Text>
        </>
      ) : action?.status?.cancellation?.approved ? (
        <Text>Cancellation request approved</Text>
      ) : (
        <Text>Cancellation request raised</Text>
      ),
    },
  ];

  // Handles the continuation of the booking process depending on the action step and boarding center match.
  const handleContinue = (action) => {
    if (action.isBoardingCenterMatched) {
      localStorage.setItem("newBookAppointment", false);
      localStorage.setItem("bookingID", action?.bookingID);
      navigate(`/book-appointment/step-${action.step}`);
    }
  };

  return (
    <div
      style={{
        marginLeft: isSmallScreen ? "" : "10px",
        marginRight: isSmallScreen ? "" : "10px",
      }}
    >
      {contextHolder}
      <Card
        hoverable={TransactionOutlined}
        style={{
          borderRadius: 0,
          minHeight: "250px",
          overflow: "scroll",
          borderColor: "lightgray",
        }}
      >
        <Row
          justify={"space-between"}
          style={{ alignItems: "center", marginBottom: 10 }}
        >
          <ActionCenterCardHeader
            action={action}
            calculateTimeRemaining={calculateTimeRemaining}
            handleContinue={handleContinue}
            handleTrack={handleTrack}
            items={items}
          />
        </Row>
        <Progress
          strokeColor={token.Primary}
          size={"small"}
          percent={action?.step === 2 ? 50 : action?.step === 3 ? 80 : 100}
          showInfo={false}
        />
        <Text style={{ display: "block" }}>
          {t("Pet Name")} : {action?.petDetails?.name || "--"}
        </Text>
        <Text style={{ display: "block" }} copyable>
          {t("booking_id")} : {action?.bookingID || "--"}
        </Text>
        <Text style={{ display: "block" }}>
          Created On : {dayjs(action?.createdAt).format("MMM D, YYYY h:mm A")}
        </Text>
        <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: 0 }}>
          {t("selected_services")} :{" "}
          {action?.services
            ?.map((e) => t(e.serviceName, { ns: "common" }))
            .join(", ")}
        </Paragraph>
        {action?.step === 3 && action?.rescheduleBooking?.count === 1 && (
          <Text>{action?.rescheduleBooking?.rescheduleUpto}</Text>
        )}
      </Card>
      <Modal
        title="Reason for cancellation"
        maskClosable={true}
        onCancel={handleCancel}
        footer={() => (
          <Popconfirm
            title="Are you sure to raise cancel request?"
            onConfirm={() => {
              if (reason === "")
                return error(
                  "Please enter the reason for raising cancel request"
                );
              dispatch(
                dashboardAction.requestCancellationEvent(
                  bookingIdTobecancelled.current,
                  { reason }
                )
              );
            }}
          >
            <Button>Request for cancellation</Button>
          </Popconfirm>
        )}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        open={isModalOpen}
      >
        <TextArea rows={3} onChange={(e) => setReason(e.target.value)} />
      </Modal>
    </div>
  );
};

export default ActionCenterCard;
