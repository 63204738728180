import { Col, Input, Row, Spin, Button, Form } from "antd";
import React from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import styles from "../Styles/signup.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router-dom";

/**
 * SignupForm component provides a form for users to enter their details and sign up for an account.
 * @param {Object} props - The props for the SignupForm component.
 * @param {function} props.handleSignup - The function to handle form submission, passed from the parent component.
 * @param {Object} props.form - The Antd Form instance used to control the form's state and validation.
 * */

const SignupForm = ({ handleSignup, form, captchaRef }) => {
  const { isLoading } = useSelector((str) => str.loadingReducer);
  const location = useLocation();
  const referralCode = new URLSearchParams(location.search).get("referralCode");
  // console.log(!!referralCode);

  const validatePhoneNumber = (_, value) => {
    if (!value) {
      return Promise.reject("Please input your phone number");
    }

    // Remove the country code and any non-digit characters
    const phoneNumber = value.slice(2).replace(/\D/g, "");

    if (phoneNumber.length !== 10) {
      return Promise.reject("Not valid phone number");
    }

    return Promise.resolve();
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleSignup}>
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={12} md={12} lg={12}>
          <Form.Item
            required
            name={"firstName"}
            rules={[
              {
                required: true,
                message: "Please input your firstname!",
              },
            ]}
          >
            <Input placeholder="First Name" className={styles.formInput} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <Form.Item
            required
            name={"lastName"}
            rules={[
              {
                required: true,
                message: "Please input your lastname!",
              },
            ]}
          >
            <Input placeholder="Last Name" className={styles.formInput} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        required
        name={"email"}
        rules={[
          {
            required: true,
            message: "Please input your email!",
          },
        ]}
      >
        <Input placeholder="Email Address" className={styles.formInput} />
      </Form.Item>
      <Row gutter={[16, 0]}>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Form.Item
            required
            name={"password"}
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message:
                  "Must contain atleast 1 uppercase, 1 lowercase, 1 number, and 1 special character.Minimum 8 characters.",
              },
            ]}
          // hasFeedback
          >
            <Input.Password
              placeholder="Password"
              className={styles.formInput}
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Form.Item
            required
            name={"cpassword"}
            // label={"confirm password"}
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Password do not match!");
                },
              }),
            ]}
          >
            <Input
              placeholder="Confirm Password"
              className={styles.formInput}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Form.Item
            name="phoneNumber"
            rules={[
              {
                validator: validatePhoneNumber,
              },
            ]}
          >
            <PhoneInput
              countryCodeEditable={false}
              country="in"
              onlyCountries={["in"]}
              inputStyle={{
                width: "100%",
                // padding: "8px 11px 8px 48px",
                border: "0.5px solid rgba(0, 0, 0, 0.30)",
                height: "39.6px",
                borderRadius: "2px",
              }}
              buttonStyle={{
                border: "0.5px solid rgba(0, 0, 0, 0.30)",
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Form.Item name={"referredBy"} initialValue={referralCode}>
            <Input
              placeholder="Referral code"
              className={styles.formInput}
              disabled={!!referralCode}
            // format
            />
          </Form.Item>
        </Col>
      </Row>
      {/* {!referralCode && (
        <Form.Item
          name="referredBy"
        >
          <Input 
            placeholder="Referral code" 
            className={styles.formInput} 
          />
        </Form.Item>
      )} */}
      <Form.Item
        required
        name={"recaptcha"}
        rules={[
          {
            required: true,
            message: "Please verify",
          },
        ]}
      >
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          ref={captchaRef}
          style={{ width: "100%", justifyContent: "center", display: "flex" }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          htmlType="submit"
          style={{
            width: "100%",
            height: "45px",
            backgroundColor: "#3A60A1",
            color: "white",
            fontSize: "18px",
          }}
          loading={isLoading}
        >
          Sign Up
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SignupForm;
