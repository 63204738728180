import { Button, Card, Col, Form, Row, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressForm from "../../App/Components/AddressForm";
import Mapss from "../../Navbar/Components/Mapss";
import Spinner from "../../Spinner/Spinner";
import { addressAction } from "../../Common/State/adressactions";
import { useNavigate } from "react-router-dom";
import CommonModal from "../../CommonModal/CommonModal";
import LocationDetailsBody from "../../App/Components/LocationDetailsBody";
import useAddAddressEffects from "./useAddAddressEffects";
const { Text } = Typography;

/**
 * This component allows users to add a new address using a form, select an existing address from a list, and update the boarding center with the selected address. It also includes a modal for confirming the selected address.
 * @description
 * - Displays a map for selecting an address and a form for adding a new address if no addresses are available.
 * - Allows the user to select an address from a list of previously added addresses.
 * - Opens a modal for confirming the selected address.
 * - Handles loading states for maps, addresses, and user details.
 */


const AddAddress = () => {
  const { tokendata } = useSelector((str) => str.appReducer);
  const {
    isLoadingMaps,
    isLoadingAddresses,
    isLoadingUserDetails,
    isLoadingBoardingCenter
  } = useSelector((str) => str.loadingReducer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    alladdress,
    boardingcenterError,
    boardingcenter,
  } = useSelector((str) => str.addressReducer);
  const [address, setAddress] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const showModal = () => setIsModalOpen(true);
  const onFinish = (v) => {
    dispatch(addressAction.addAddressEvent(v));
  };

  const handleOk = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  const handleCancel = () => setIsModalOpen(false);

  // Function to handle address selection
  const onChange = (boardingAddress) => {
    dispatch(
      addressAction.updateBoardingCenterEvent({
        locationId: boardingAddress._id,
      })
    );
    handleOk();
  };

  // fetches all addresses, loaders
  useAddAddressEffects({ form, address });

  const handleAddressSelect = (item) => {
    if (item?.placeAddress) {
      let splitt_place_address = item?.placeAddress?.split(", ");
      if (item?.type) {
        setAddress({
          pincode:
            item?.type === "PINCODE"
              ? item?.placeName
              : item?.type === "CITY"
                ? ""
                : splitt_place_address[splitt_place_address.length - 1],
          eLoc: item?.eLoc,
          state:
            item?.type === "PINCODE"
              ? splitt_place_address[splitt_place_address.length - 1]
              : item?.type === "CITY"
                ? splitt_place_address[splitt_place_address.length - 1]
                : splitt_place_address[splitt_place_address.length - 2],
          city:
            item?.type === "CITY"
              ? item?.placeName
              : splitt_place_address[splitt_place_address.length - 3],
        })
      } else if (item.type === "" || !item?.type) {
        setAddress({
          pincode: item?.placeAddress.match(/\b\d{6}\b/)[0],
          eLoc: item?.eLoc,
        })
      }
      // setAddress({
      //   pincode: splitt_place_address[splitt_place_address.length - 1],
      //   state: splitt_place_address[splitt_place_address.length - 2],
      //   city: splitt_place_address[splitt_place_address.length - 3],
      //   eLoc: item?.eLoc,
      // });
    } else if (item?.data) {
      setAddress( item?.data);
    } else {
      setAddress(item);
    }
  };

  // called when user selects any location from the list and click on continue
  const handleContinue = (value) => {
    dispatch(addressAction.getBoardingEvent(value?._id));
    showModal();
  };

  if (isLoadingMaps || isLoadingAddresses || isLoadingUserDetails || isLoadingBoardingCenter) {
    return <Spinner />;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {!alladdress ? (
        <Row gutter={16} justify={"center"} style={{ alignItems: "center" }}>
          <Col span={10}>
            <Mapss
              height={"500px"}
              handleAddressSelect={handleAddressSelect}
              tokendata={tokendata}
            />
          </Col>
          <Col span={10}>
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <AddressForm />
            </Form>
          </Col>
        </Row>
      ) : (
        alladdress?.map((address) => (
          <Card style={{ width: "400px" }} title="Selected Address">
            <Text style={{ display: "block" }}>{address?.fullAddress}</Text>
            <div style={{ textAlign: "end" }}>
              <Button size="small" onClick={() => handleContinue(address)}>
                Continue
              </Button>
            </div>
          </Card>
        ))
      )}
      {isModalOpen &&
        <CommonModal
          handleCancel={handleCancel}
          handleOk={handleOk}
          maskClosable={false}
          isModalOpen={isModalOpen}
          width={500}
          body={() => (
            <LocationDetailsBody
              boardingcenterError={boardingcenterError}
              boardingcenter={boardingcenter}
              onChange={onChange}
              handleOk={handleOk}
            />
          )}
        />
      }
    </div>
  );
};

export default AddAddress;
