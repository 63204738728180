import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { dashboardAction } from "../State/actions";
import useCaptainDashboardColumns from "./useCaptainDashboardColumns";
import { adminAction } from "../../ServiceDetails/state/actions";
import { useSocket } from "../../context/SocketContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";
/**
 * @description -
 * This component displays detailed information about captains using a table format.
 * It integrates real-time updates via sockets and handles filtering and pagination
 * based on URL search parameters.
 */

const CaptainDetail = () => {
  const dispatch = useDispatch();
  const { captainDetails, totalCaptainDetails } = useSelector(
    (state) => state.dashboardReducer
  );
  const { isLoadingCaptainDetails, isLoading } = useSelector(
    (str) => str.loadingReducer
  );
  const { updateBookingStatus } = useSelector(
    (str) => str.adminDashboardReducer
  );
  const { currentSocket } = useSocket();
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchParams] = useSearchParams();
  const [bookingCount, setBookingCount] = useState({
    bookingIds: new Set(),
    count: 0,
  });
  const { columns, setFiltered } = useCaptainDashboardColumns();

  const handleCaptainDetails = (page = 1) => {
    if (searchParams.size === 0) {
      setFiltered({ status: false, pick: false, drop: false, id: false });
      dispatch(dashboardAction.getCaptainDetailsEvent(page));
    } else {
      const search = Array.from(searchParams.entries()).reduce(
        (acc, [key, val]) => {
          acc[key] = val;
          return acc;
        },
        {}
      );
      dispatch(dashboardAction.getCaptainDetailsEvent(page, search));
    }
  };

  const handleTableChange = (_, newFilters) => {
    if (newFilters?.status?.[0] === "all" || newFilters?.status === null) {
      setFiltered((prev) => ({ ...prev, status: false }));
      searchParams.delete("status");
      navigate(`?${searchParams.toString()}`);
      const search = Array.from(searchParams.entries()).reduce(
        (acc, [key, val]) => {
          acc[key] = val;
          return acc;
        },
        {}
      );
      dispatch(dashboardAction.getCaptainDetailsEvent(1, search));
    } else {
      setFiltered((prev) => ({ ...prev, status: true }));
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("status", newFilters?.status[0]);
      navigate(`?${searchParams.toString()}`);
      const search = Array.from(searchParams.entries()).reduce(
        (acc, [key, val]) => {
          acc[key] = val;
          return acc;
        },
        {}
      );
      dispatch(dashboardAction.getCaptainDetailsEvent(page, search));
    }
  };

  useEffect(() => {
    handleCaptainDetails(page);
  }, [dispatch]);

  const handleSync = () => {
    handleCaptainDetails(page);
    setBookingCount({ bookingIds: new Set(), count: 0 });
  };

  useEffect(() => {
    if (currentSocket) {
      currentSocket.on("bookingUpdate", (message) => {
        const bookingId = message.bookingId;
        setBookingCount((prevState) => {
          if (!prevState.bookingIds.has(bookingId)) {
            const newBookingIds = new Set(prevState.bookingIds);
            newBookingIds.add(bookingId);
            return {
              bookingIds: newBookingIds,
              count: newBookingIds.size,
            };
          }
          return prevState;
        });
      });
    }
    return () => {
      if (currentSocket) {
        currentSocket.off("bookingUpdate");
      }
    };
  }, [currentSocket]);

  const loadMoreData = (pageNumber) => {
    if (
      !isLoadingCaptainDetails &&
      captainDetails.length < totalCaptainDetails
    ) {
      handleCaptainDetails(pageNumber);
    } else {
      setPage(1);
    }
  };

  useEffect(() => {
    if (updateBookingStatus) {
      // dispatch(dashboardAction.getCaptainDetailsEvent());
      handleCaptainDetails(page);
      dispatch(adminAction.clearAlldata());
    }
  }, [updateBookingStatus]);

  // if (isLoadingCaptainDetails) {
  //   return <Spinner />
  // }

  return (
    <>
      <div>
        {/* <Layout>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            > */}
        {/* <Card
                title="Captain Details"
                bordered={false}
                style={{ marginBottom: 24 }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Row gutter={16}>
                      <Col
                        span={8}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Avatar size={100} src={captainDetails?.photo} />
                      </Col>
                      <Col span={16}>
                        <p style={{ padding: 0, margin: 0 }}>
                          <strong>Name:</strong> {captainDetails?.name}
                        </p>
                        <p style={{ padding: 0, margin: 0 }}>
                          <strong>Contact Number:</strong>{" "}
                          {captainDetails?.contactNumber}
                        </p>
                        <p style={{ padding: 0, margin: 0 }}>
                          <strong>Email:</strong> {captainDetails?.email}
                        </p>
                        <p style={{ padding: 0, margin: 0 }}>
                          <strong>Employee ID:</strong>{" "}
                          {captainDetails?.employeeId}
                        </p>
                        <p style={{ padding: 0, margin: 0 }}>
                          <strong>Boarding Center Head:</strong>{" "}
                          {captainDetails?.boardCenterHead}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <div>
                      <Row gutter={16}>
                        <Col span={6}>
                          <h1
                            style={{
                              fontSize: "40px",
                              margin: 0,
                              textAlign: "center",
                            }}
                          >
                            {captainDetails?.averageRating}
                          </h1>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Rate
                              disabled
                              allowHalf
                              style={{
                                fontSize: "14px",
                                transform: "scale(0.7)",
                                textAlign: "center",
                              }}
                              value={captainDetails?.averageRating}
                            />
                          </div>
                          <p style={{ margin: 0, textAlign: "center" }}>
                            {captainDetails?.reviews} reviews
                          </p>
                        </Col>
                        <Col span={18}>
                          {captainDetails?.ratingDistribution?.map(
                            (item, index) => (
                              <Row
                                key={index}
                                align="middle"
                                style={{ marginBottom: "-6px" }}
                              >
                                <Col span={2}>{item.rating}</Col>
                                <Col span={22}>
                                  <Progress
                                    percent={item.percent}
                                    strokeColor="#4CAF50"
                                    showInfo={false}
                                    trailColor="#E0E0E0"
                                  />
                                </Col>
                              </Row>
                            )
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Card> */}
        <Table
          bordered
          loading={isLoadingCaptainDetails || isLoading}
          columns={columns}
          pagination={false}
          dataSource={captainDetails}
          onChange={handleTableChange}
          onScroll={(e) => {
            const { scrollTop, scrollHeight, clientHeight } = e.target;
            if (scrollTop + clientHeight >= scrollHeight - 50) {
              if (!isLoadingCaptainDetails) {
                const nextPage = page + 1;
                setPage(nextPage);
                loadMoreData(nextPage);
              }
            }
          }}
          title={() => (
            <Button
              icon={<SyncOutlined />}
              size="small"
              disabled={bookingCount.count === 0}
              onClick={handleSync}
              type="primary"
            >
              Sync {bookingCount.count}
            </Button>
          )}
          scroll={{
            x: 630,
            y: 500,
          }}
        />
        {/* </Content>
          </Layout> */}
      </div>
    </>
  );
};

export default CaptainDetail;
