export const petMarketingConstants = {
    ADD_PET_SUCCESS: "ADD_PET_SUCCESS",
    ADD_PET_ERROR: "ADD_PET_ERROR",

    GET_ALL_PETS_SUCCESS: "GET_ALL_PETS_SUCCESS",
    GET_ALL_PETS_ERROR: "GET_ALL_PETS_ERROR",

    UPDATE_PET_SUCCESS: "UPDATE_PET_SUCCESS",
    UPDATE_PET_ERROR: "UPDATE_PET_ERROR",

    GET_PET_BY_ID_SUCCESS: "GET_PET_BY_ID_SUCCESS",
    GET_PET_BY_ID_ERROR: "GET_PET_BY_ID_ERROR",

    CLEAR_PETS_BREEDS:"CLEAR_PETS_BREEDS"
};
