import { profileAction } from "../../Common/State/actions";
import { loadRazorpayScript } from "../../Common/utils/loadScript";
import { paymentApi } from "../../Common/utils/paymentapi";

export const handleStartTransaction = async (
  setIsPaymentLoading,
  bookappointmentdetails,
  token,
  navigate,
  setLoading,
  dispatch
) => {
  setIsPaymentLoading(true);

  // Dynamically load Razorpay script
  const scriptLoaded = await loadRazorpayScript();

  if (!scriptLoaded) {
    alert("Razorpay SDK failed to load. Are you online?");
    setIsPaymentLoading(false);
    return;
  }

  try {
    const {
      data: {
        info: { key },
      },
    } = await paymentApi.getApiKey();

    const {
      data: { info },
    } = await paymentApi.makePayment(
      bookappointmentdetails.bookingDetails.bookingUniqueId
    );

    const options = {
      key,
      amount: info.amount,
      currency: "INR",
      name: "Pets website",
      description: "Pets service",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbYPH_66lG5dhDAVXuwo7u9iQR4WRNdmHElw&s",
      order_id: info.id,
      // callback_url: `http://localhost:8080/v1/payment-verification/${bookingId}`,
      prefill: {
        name: "Gaurav Kumar",
        email: "gaurav.kumar@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: token.Secondary,
      },
      handler: function (response) {
        handlePaymentSuccess(
          response,
          bookappointmentdetails,
          navigate,
          setLoading
        );
      },
      modal: {
        ondismiss: function () {
          // This function is called when the modal is closed by the user
          console.log("Payment modal closed by user");
          // bookappointmentdetails?.bookingDetails?.bookingUniqueId
          dispatch(
            profileAction.slotRemovalEvent(
              bookappointmentdetails?.bookingDetails?.bookingUniqueId
            )
          );

          // Handle modal close logic here
          // alert("Payment process was not completed.");
          // You can also trigger a function here to mark the booking as pending
        },
      },
    };
    setIsPaymentLoading(false);

    const razor = new window.Razorpay(options);
    razor.open();
  } catch (err) {
    setIsPaymentLoading(false);
  }
};

const handlePaymentSuccess = async (
  response,
  bookappointmentdetails,
  navigate,
  setLoading
) => {
  setLoading(true);
  try {
    const result = await paymentApi.paymentVerification(
      bookappointmentdetails.bookingDetails.bookingUniqueId,
      response
    );

    // Handle the result of payment verification
    if (result.data.info.type === "SUCCESS") {
      const payment_id = result.data.info.payment_id;

      // Payment successful
      navigate(
        `/paymentsuccess?reference=${payment_id}&bookingId=${bookappointmentdetails?.bookingDetails?.bookingUniqueId}`
      );
    } else {
      console.log("Payment failed");
    }
    setLoading(false);
  } catch (error) {
    console.error("Error verifying payment:", error);
    setLoading(false);
    // Handle error scenario
  }
};
