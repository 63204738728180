/**
 * @description
 * Create a new object with a screen name and add key value pairs for strings
 * If some text is used in many screens then create those under common object
 */
const Strings = {
    ScreenName: {
        home: 'Dashboard',
    },
    Login: {
        fName: 'First Name',
        lName: 'Last Name',
        login: 'Login',
    },
    Common: {
        pdf: 'PDF',
        doc: 'DOCS',
        timer: 1.5 // timer for booking 
    },

};

export default Strings;
