import {
  BellOutlined,
  DownOutlined,
  GlobalOutlined,
  MoonOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SunOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Col, Dropdown, Image, Switch, Tag, theme } from "antd";
import React from "react";
import { useTheme } from "../../theme/ThemeToggle";
import LanguageItems from "./LanguageItems";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "../Styles/header.module.css";
import { useMediaQuery } from "react-responsive";
import Lottie from "lottie-react";
import coinAnimation from "./goldcoin.json";

/**
 * A component that renders the right side of the large screen navbar,
 * featuring theme toggling, language selection, search, help, notifications, and user profile options.
 *
 * @param {Object} props - The component props.
 * @param {string} props.displayName - The name of the logged-in user to display in the navbar.
 * @param {Function} props.showDrawerHelp - Function to open the help drawer.
 * @param {Function} props.showDrawer - Function to open the notifications drawer.
 * @param {Array<Object>} props.profileItems - The items to be displayed in the user profile dropdown menu.
 *
 * @returns {JSX.Element} A section of the navbar that contains various interactive icons and dropdowns.
 *
 * @description
 * - **Switch**: Toggles between light and dark themes, using a sun or moon icon.
 * - **Dropdown**: Handles the language selection and user profile menu.
 * - **SearchOutlined**: Opens the search page when clicked.
 * - **QuestionCircleOutlined**: Opens the help drawer when clicked.
 * - **BellOutlined**: Opens the notifications drawer, with a badge displaying the count of unread notifications.
 */

const RightPartLargeScreenNavbar = ({
  showDrawerHelp,
  showDrawer,
  profileItems,
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const { toggleTheme } = useTheme();
  const languageItems = LanguageItems();
  const navigate = useNavigate();
  const { notificationCount, userDetails } = useSelector(
    (str) => str.navbarReducer
  );
  const { pointsRedeemedOrRestored } = useSelector(
    (state) => state.bookAppointmentReducer
  );
  const isMediumScreen = useMediaQuery({ maxWidth: 768 });

  return (
    <Col
      xs={12}
      sm={12}
      md={12}
      lg={8}
      xl={{ span: 8 }}
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <div
        className={styles.eachitem}
        onClick={() => navigate("/accounts/referrals")}
        style={{
          paddingRight: `clamp(5px, 3vw, 10px)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span>
          {pointsRedeemedOrRestored
            ? pointsRedeemedOrRestored?.totalRewardsLeft
            : userDetails?.rewards?.totalRewards}
        </span>
        <Lottie
          animationData={coinAnimation}
          style={{
            width: `clamp(35px, 3vw, 50px)`,
            display: "flex",
            alignItems: "center",
          }}
          loop={true}
        />
      </div>
      <div
        className={styles.eachitem}
        style={{ paddingRight: `clamp(5px, 3vw, 20px)` }}
      >
        <SearchOutlined onClick={() => navigate("/search")} />
      </div>
      <div
        className={styles.eachitem}
        onClick={showDrawerHelp}
        style={{ paddingRight: `clamp(5px, 3vw, 20px)` }}
      >
        <QuestionCircleOutlined />
      </div>
      <div
        className={styles.eachitem}
        onClick={showDrawer}
        style={{ paddingRight: `clamp(5px, 3vw, 20px)` }}
      >
        <Badge count={notificationCount} overflowCount={9} size="small">
          <BellOutlined style={{ fontSize: `clamp(5px, 3vw, 20px)` }} />
        </Badge>
      </div>
      <Dropdown menu={{ items: profileItems }}>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!isMediumScreen ? (
            <div className={styles.eachitem}>
              {userDetails?.profileImage ? (
                <Avatar
                  size={"small"}
                  src={
                    <Image
                      src={userDetails?.profileImage}
                      alt="avatar"
                      preview={false}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                      fallback="https://static.vecteezy.com/system/resources/thumbnails/005/545/335/small/user-sign-icon-person-symbol-human-avatar-isolated-on-white-backogrund-vector.jpg"
                    />
                  }
                />
              ) : (
                <Avatar size={"small"}>
                  {userDetails?.firstName?.[0] + userDetails?.lastName?.[0] ||
                    "User"}
                </Avatar>
              )}
              &nbsp;
              <span style={{ fontSize: "18px" }}>
                {userDetails?.firstName}
                {/* {userDetails?.currentRole !== "USER" ? (
                  <Tag style={{ display: "block" }} color="blue">
                    {userDetails?.currentRole}
                  </Tag>
                ) : (
                  ""
                )} */}
              </span>
            </div>
          ) : userDetails?.profileImage ? (
            <Avatar
              size={"small"}
              src={
                <Image
                  src={userDetails?.profileImage}
                  alt="avatar"
                  preview={false}
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                  fallback="https://static.vecteezy.com/system/resources/thumbnails/005/545/335/small/user-sign-icon-person-symbol-human-avatar-isolated-on-white-backogrund-vector.jpg"
                />
              }
            />
          ) : (
            <Avatar size={"small"}>
              {userDetails?.firstName?.[0] + userDetails?.lastName?.[0] ||
                "User"}
            </Avatar>
          )}
        </div>
      </Dropdown>
    </Col>
  );
};

export default RightPartLargeScreenNavbar;
