import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Image, Row, Spin, theme, Typography } from 'antd';
import { services } from '../../Common/Components/Services';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { settingsAction } from '../State/actions';
import Spinner from '../../Spinner/Spinner';
import { useMediaQuery } from 'react-responsive';
const { Text } = Typography;

/**
 * @description
 * The `OngoingBookings` component displays a list of ongoing bookings using a card layout. It supports infinite scrolling to load more bookings as the user scrolls down. Each booking is shown with details such as the boarding center name, drop address, booking ID, and status.
 *  The component provides options to view details and continue booking based on the booking's steps and status.
 * 
 * @param {Object} props - The component props.
 * @param {Function} props.setPage - Function to set the current page number for pagination.
 * @param {number} props.page - The current page number.
 */

const OngoingBookings = ({ setPage, page }) => {
    const { allBookings, totalBookings } = useSelector((str) => str.settingsReducer);
    const { isLoadingAllBookings } = useSelector(str => str.loadingReducer);
    const isTab = useMediaQuery({ minWidth: 992 });
    const dispatch = useDispatch();

    const { useToken } = theme;
    const { token } = useToken();
    const navigate = useNavigate();

    const loadMoreNotifications = () => {
        if (allBookings.length < totalBookings) {
            const nextPage = page + 1;
            dispatch(settingsAction.getAllBookingsEvent(nextPage, "ongoing"));
            setPage(nextPage);
        } else {
            setPage(1);
        }
    }

    const handleContinue = (booking) => {
        if (booking?.steps === 4) {
            navigate(`/track/${booking?.bookingUniqueId}`)
        } else if (booking.isBoardingCenterMatched) {
            localStorage.setItem("newBookAppointment", false);
            localStorage.setItem("bookingID", booking?.bookingUniqueId);
            navigate(`/book-appointment/step-${booking.steps}`);
        }
    }

    return (
        allBookings ?
            <div id='notificationsScroll' style={{ height: window.innerHeight*0.7, overflow: "auto" }}>
                <InfiniteScroll
                    scrollableTarget="notificationsScroll"
                    dataLength={allBookings.length}
                    hasMore={allBookings.length < totalBookings}
                    next={loadMoreNotifications}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                    loader={
                        isLoadingAllBookings && <div style={{ textAlign: 'center',paddingTop:"1.2em", paddingBottom:"1.2em" }}><Spin size='large' /></div>
                    }
                >
                    {allBookings && allBookings?.map(booking => (
                        <Card hoverable style={{ borderRadius: 0, width: isTab? "85%" : "100%", marginBottom: "1.2em", borderColor: "lightgrey" }}>
                            <Row>
                                <Col span={4}>
                                    <Image width={"90%"} src='https://t3.ftcdn.net/jpg/06/59/96/80/360_F_659968047_EuG02YzFOSfAS0HsMjKc5bC6S4VSaxvF.jpg' preview={false} />
                                </Col>
                                <Col span={12}>
                                    <Text style={{ fontWeight: 600, display: "block" }}>{booking.boardCenterName}</Text>
                                    <Text style={{ display: "block", fontSize: "12px" }} >{booking?.dropAddress?.city}</Text>
                                    <Text style={{ color: token.GreyShadeLight, fontSize: "12px", display: "block" }}>ORDER#{booking?.bookingUniqueId}</Text>
                                    {/* <Button style={{border:"none",textAlign:"start"}} size='small'>VIEW DETAILS</Button> */}
                                </Col>
                                <Col span={8} style={{ textAlign: "end" }}>
                                    Initiated on {dayjs(booking?.status?.booking_status?.date).format("MMM D, YYYY h:mm A")} <CheckCircleOutlined style={{ color: token.Green }} />
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={18} style={{ textAlign: "left" }}>
                                    {booking?.services?.map((e) => (
                                        <Text style={{ display: "block" }}>{`${services.find((service) => service.key === e.serviceName).name
                                            } FROM ${dayjs(e.slots?.checkInDateTime).format(
                                                "MMM D, YYYY h:mm A"
                                            )} TO ${dayjs(e?.slots?.checkOutDateTime).format(
                                                "MMM D, YYYY h:mm A"
                                            )}`}</Text>)
                                    )}
                                </Col>
                                <Col span={6} style={{ textAlign: "end" }}>
                                    <Text style={{ display: "block" }}>{booking?.services?.every(e => e.paymentId) ? `Total Paid: ₹${booking?.totalPrice}` : ""}</Text>
                                    <Button size='small'
                                        type='primary'
                                        style={{ borderRadius: 0 }}
                                        onClick={() => handleContinue(booking)}
                                        disabled={!booking.isBoardingCenterMatched}
                                    >
                                        {booking?.steps === 4 ? "Track ❯" : "Continue booking ❯"}
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </InfiniteScroll>
            </div> :
            isLoadingAllBookings ? (
                <Spinner />
            ) : "No data"
    )
}

export default OngoingBookings
