import { EllipsisOutlined } from "@ant-design/icons";
import { Drawer, Dropdown, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navbarAction } from "../State/actions";
import { useNavigate } from "react-router-dom";
import NotificationsList from "./NotificationsList";
import Spinner from "../../Spinner/Spinner";
const { Text } = Typography;

/**
 * NotificationDrawer component displays a list of notifications in a side drawer.
 * It allows users to mark notifications as read, delete them, and navigate to specific URLs.
 *
 * @param {Object} props - The component props.
 * @param {function} props.onClose - Function to handle closing the drawer.
 * @param {boolean} props.open - Boolean indicating whether the drawer is open or closed.
 *
 * @returns {JSX.Element} The rendered NotificationDrawer component.
 *
 * @description
 * - Fetches notifications when the drawer is opened.
 * - Provides options to mark all notifications as read through a dropdown menu.
 * - Allows users to load more notifications if available.
 * - Enables users to handle individual notifications (read, delete, navigate).
 */

const NotificationDrawer = ({ onClose, open }) => {
  const { isLoadingNotifications } = useSelector((str) => str.loadingReducer);
  const {
    allNotifications,
    totalNotifications,
    markNotifications,
    updatedNotification,
  } = useSelector((str) => str.navbarReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const items = [
    {
      key: "1",
      label: (
        <Text
          onClick={() => dispatch(navbarAction.markAllNotificationsEvent())}
        >
          Mark All as Read
        </Text>
      ),
    },
  ];

  const loadMoreNotifications = () => {
    if (allNotifications.length < totalNotifications) {
      const nextPage = page + 1;
      dispatch(navbarAction.getNotificationsEvent(nextPage));
      setPage(nextPage);
    }
  };

  const handleNotification = (item) => {
    if (item?.redirectUrl) {
      navigate(item?.redirectUrl);
      onClose();
    }
    dispatch(navbarAction.updateNotificationsEvent(item?._id, { read: true }));
    dispatch(navbarAction.getNotificationCountEvent());
  };

  const handleRead = (item) => {
    dispatch(navbarAction.updateNotificationsEvent(item._id, { read: true }));
    dispatch(navbarAction.getNotificationCountEvent());
  };

  const handleDelete = (item) => {
    dispatch(
      navbarAction.updateNotificationsEvent(item._id, {
        read: true,
        deleted: true,
      })
    );
    dispatch(navbarAction.getNotificationCountEvent());
  };

  useEffect(() => {
    if (open) {
      dispatch(navbarAction.getNotificationsEvent(1));
    }
  }, [open]);

  useEffect(() => {
    if (markNotifications || updatedNotification) {
      dispatch(navbarAction.getNotificationsEvent(1));
      setPage(1);
      dispatch(navbarAction.markNotificationsReset());
    }
  }, [markNotifications, updatedNotification]);

  return (
    <Drawer
      styles={{ body: { padding: 0 } }}
      title="Notifications"
      placement="right"
      onClose={onClose}
      open={open}
      destroyOnClose={true}
      width={500}
      extra={
        <Dropdown menu={{ items }}>
          <EllipsisOutlined />
        </Dropdown>
      }
    >
      {allNotifications ? (
        <NotificationsList
          handleDelete={handleDelete}
          handleNotification={handleNotification}
          handleRead={handleRead}
          loadMoreNotifications={loadMoreNotifications}
        />
      ) : isLoadingNotifications ? (
        <div style={{ textAlign: "center" }}>
          <Spin />
        </div>
      ) : (
        "No data"
      )}
    </Drawer>
  );
};

export default NotificationDrawer;
