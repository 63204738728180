import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import useWarnings from "../../Common/Components/useWarnings";
import { crudPlansApi } from "../utils/api";
import { useRoute } from "../../context/RouteContext";

const CreateStateForm = () => {
  const [form] = Form.useForm();
  const { success, error, contextHolder } = useWarnings();
  const [laoding, setLoading] = useState(false);
  const { setSelectedKey } = useRoute();
  const [uncreatedStates, setUncreatedStates] = useState({
    states: [],
    loading: false
  })
  const [unassignedStateAdmins, setUnassignedStateAdmins] = useState({
    admins: [],
    loading: false
  })

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const responseCreateState = await crudPlansApi.createState(values);
      if (responseCreateState?.data?.info?.code)
        success("State created successfully");
      form.resetFields();
      setLoading(false);
    } catch (err) {
      error(err?.response?.data?.message);
      setLoading(false);
    }
  };

  const getUncreatedStates = async () => {
    setUncreatedStates({ loading: true, states: [] })
    try {
      const response = await crudPlansApi.getUncreatedStates(false);
      if (response?.data?.info) {
        setUncreatedStates({ loading: false, states: response.data?.info })
        setLoading(false);
      }
    } catch (err) {
      setUncreatedStates({ loading: false, states: [] })
      setLoading(false);
    }
  }

  const getUnassignedStateAdmins = async () => {
    setUnassignedStateAdmins({ loading: true, admins: [] });
    try {
      const response = await crudPlansApi.getUnassignedStateAdmins();
      if (response?.data?.info) {
        setUnassignedStateAdmins({ loading: false, admins: response.data?.info })
      }
    } catch (err) {
      setUnassignedStateAdmins({ loading: false, admins: [] })
    }
  }

  useEffect(() => {
    setSelectedKey("CREATE_STATE");
    getUncreatedStates();
    getUnassignedStateAdmins();
  }, [])

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      {contextHolder}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="State"
            name={"stateName"}
            rules={[{ required: true }]}
          >
            {/* <Input /> */}
            <Select
              options={uncreatedStates?.states?.map((e) => ({
                value: e,
                label: e,
              }))}
              showSearch
              loading={uncreatedStates?.loading}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Email Id of the state admin"
            name={"email"}
            rules={[{ required: true }]}
          >
            {/* <Input type="email" /> */}
            <Select
              options={unassignedStateAdmins?.admins?.map((e) => ({
                value: e.email,
                label: e.email,
              }))}
              showSearch
              loading={unassignedStateAdmins?.loading}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" loading={laoding} htmlType="submit" size="small">
          Create a state
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateStateForm;
