import { Button, Card, Col, Empty, Row, Tabs, theme, Typography } from "antd";
import React, { useState } from "react";
import { ShareAltOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import StickyBox from "react-sticky-box";
import InviteModal from "./InviteModal";

const { Text, Title } = Typography;

const ReferralProgram = () => {
  const { userDetails } = useSelector((state) => state.navbarReducer);
  const { useToken } = theme;
  const { token } = useToken();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const referralCode = userDetails?.referralCode; // Replace with the actual referral code from userDetails
  const signupUrl = `${process.env.REACT_APP_FRONTEND_URL}/signup?referralCode=${referralCode}`;

  // Define an array of light colors
  const lightColors = [
    "#FFFFFF", // White
    "#d4e5f5", // Light Blue Grey
    "#E2E8F0", // Light Grey
    "#FFF5F5", // Light Red
    "#c2dfca", // Light Green
    "#FFFFF0", // Light Yellow
    "#ead5ff", // Light Purple
    "#FFF0F5", // Light Pink
    "#F0FFFF", // Light Cyan
    "#FFE4E1", // Misty Rose
    "#F5F5DC", // Beige
    "#F0E68C", // Khaki
    "#98FB98", // Pale Green
    "#87CEEB", // Sky Blue
    "#DDA0DD", // Plum
    "#F0E0E0", // Soft Pink
    "#E0F0E0", // Soft Mint
    "#E6E6FA", // Lavender
    "#FFE4B5", // Moccasin
    "#B0E0E6", // Powder Blue
  ];

  // const darkBackgroundColors = [
  //   "#000000", // Black
  //   "#1A1A1A", // Nearly Black
  //   "#1E293B", // Dark Blue Grey
  //   "#1E3A8A", // Dark Blue
  //   "#1E40AF", // Navy Blue
  //   "#312E81", // Indigo
  //   "#4C1D95", // Deep Purple
  //   "#831843", // Deep Pink
  //   "#991B1B", // Dark Red
  //   "#065F46", // Dark Green
  //   "#115E59", // Dark Teal
  //   "#713F12", // Dark Brown
  //   "#3730A3", // Deep Indigo
  //   "#4338CA", // Royal Blue
  //   "#7E22CE", // Bright Purple
  //   "#BE185D", // Bright Pink
  //   "#2563EB", // Bright Blue
  //   "#059669", // Emerald
  //   "#2D3748", // Slate
  //   "#374151", // Gray
  // ];

  // Helper function to get a random light color

  const getRandomLightColor = () => {
    return lightColors[Math.floor(Math.random() * lightColors.length)];
  };

  // Filter user rewards based on booking completion
  const userRewards = userDetails?.referrals?.filter(
    (referral) => referral.referralStatus === "BOOKING_COMPLETED"
  );

  // Rendering user rewards as cards
  const referralCards = (
    ///////////
    <Row gutter={[24, 24]} justify="center" style={{ padding: "20px" }}>
      {userDetails?.referrals?.length > 0 ? userDetails?.referrals?.map((referral, index) => {
        const backgroundColor = getRandomLightColor();

        return (
          <Col key={index} xs={24} sm={12} md={8}>
            <Card
              hoverable
              bordered={false}
              headStyle={{
                background: backgroundColor,
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                padding: "0",
                margin: "0",
                border: "none",
              }}
              title={
                <div
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  {`${referral?.referredUserId?.firstName} ${referral?.referredUserId?.lastName}`}
                </div>
              }
              style={{
                borderRadius: "15px",
                boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
                background: "linear-gradient(135deg, #f0f2f5, #ffffff)",
                overflow: "hidden", // This ensures the border radius is applied correctly
              }}
            >
              <div
                style={{
                  background: "#fafafa",
                  padding: "10px",
                  borderRadius: "8px",
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "15px",
                }}
              >
                <Text strong style={{ fontSize: "1.1em", color: "#00C853" }}>
                  {referral?.rewardEarned} Coins Earned
                </Text>
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <p>
                  <strong>Status:</strong>{" "}
                  {referral?.referralStatus === "PENDING"
                    ? "Pending"
                    : "Booking completed"}
                </p>
                <p>
                  <strong>Date:</strong>{" "}
                  {referral?.referralStatus === "PENDING"
                    ? dayjs(referral?.createdAt).format("DD-MM-YYYY")
                    : dayjs(referral?.bookingCompletedAt).format("DD-MM-YYYY")}
                  {/*  */}
                </p>
              </div>
            </Card>
          </Col>
        );
      }) : <Empty />}
    </Row>
  );

  // Rendering user rewards as cards
  const rewardCards = (
    ///////////
    <Row gutter={[24, 24]} justify="center" style={{ padding: "20px" }}>
      {userRewards?.length >0 ? userRewards?.map((referral, index) => {
        const backgroundColor = getRandomLightColor();

        return (
          <Col key={index} xs={24} sm={12} md={8}>
            <Card
              hoverable
              bordered={false}
              styles={{header: {
                background: backgroundColor,
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                padding: "0",
                margin: "0",
                border: "none",
              }}}
              title={
                <div
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  {`${referral?.referredUserId?.firstName} ${referral?.referredUserId?.lastName}`}
                </div>
              }
              style={{
                borderRadius: "15px",
                boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
                background: "linear-gradient(135deg, #f0f2f5, #ffffff)",
                overflow: "hidden", // This ensures the border radius is applied correctly
              }}
            >
              <div
                style={{
                  background: "#fafafa",
                  padding: "10px",
                  borderRadius: "8px",
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "15px",
                }}
              >
                <Text strong style={{ fontSize: "1.1em", color: "#00C853" }}>
                  ₹{referral?.rewardEarned} Earned
                </Text>
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <p>
                  <strong>Status:</strong> Booking completed
                </p>
                <p>
                  <strong>Date:</strong>{" "}
                  {dayjs(referral?.bookingCompletedAt).format("DD-MM-YYYY")}
                </p>
              </div>
            </Card>
          </Col>
        );
      }) : <Empty />}
    </Row>
  );

  const items = [
    {
      key: "1",
      label: "My Referrals",
      children: referralCards, // Using Cards to show referrals
    },
    {
      key: "2",
      label: "My Rewards",
      children: rewardCards,
    },
  ];

  const renderTabBar = (props, DefaultTabBar) => (
    <StickyBox
      offsetTop={0}
      style={{
        zIndex: 1,
      }}
    >
      <DefaultTabBar
        {...props}
        style={{
          background: token.background,
        }}
      />
    </StickyBox>
  );

  return (
    <Row justify={"center"} style={{ height: "100vh", overflow: "auto" }}>
      <Col xs={22} sm={24} md={24} lg={24}>
        <Card
          size="small"
          styles={{ body: { padding: 0 } }}
          hoverable
          cover={
            <div
              style={{
                paddingTop: "1.2em",
                paddingBottom: "1.2em",
                background: `linear-gradient(${token.Secondary}, ${token.White})`,
              }}
            >
              <Text
                style={{
                  display: "block",
                  color: token.blurText,
                  textAlign: "center",
                  fontSize: "1.5em",
                }}
              >
                Total Rewards Earned
              </Text>
              <Title
                style={{
                  fontSize: "1.8em",
                  textAlign: "center",
                  color: token.White,
                  marginTop: 0,
                }}
              >
                {userDetails?.rewards?.totalRewards} coins
              </Title>
              <Row gutter={16} justify={"space-between"}>
                <Col span={12} style={{ textAlign: "center" }}>
                  <Text style={{ display: "block", color: token.blurText }}>
                    Invited
                  </Text>
                  <Text style={{ display: "block", fontWeight: 500 }}>
                    {userDetails?.rewards?.invited} Friends
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "center" }}>
                  <Text style={{ display: "block", color: token.blurText }}>
                    Referrals
                  </Text>
                  <Text style={{ display: "block", fontWeight: 500 }}>
                    {userDetails?.rewards?.successfulReferrals} Successful
                  </Text>
                </Col>
                {/* <Col span={8} style={{ textAlign: "center" }}>
                  <Text style={{ display: "block", color: token.blurText }}>
                    Rewards
                  </Text>
                  <Text style={{ display: "block", fontWeight: 500 }}>
                    0 Claimed
                  </Text>
                </Col> */}
              </Row>
              <Row justify={"center"}>
                <Col sm={12} md={8} lg={6} style={{ margin: "1.2em" }}>
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    icon={<ShareAltOutlined />}
                    onClick={() => setIsModalVisible(true)}
                  >
                    Invite your friends!
                  </Button>
                  <InviteModal
                    isVisible={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    referralLink={signupUrl}
                  />
                </Col>
              </Row>
            </div>
          }
        >
          <Tabs
            defaultActiveKey="1"
            renderTabBar={renderTabBar}
            items={items}
            centered
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ReferralProgram;
