import React, { useState } from "react";
import { Calendar } from "antd";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
import dayjs from "dayjs";
import TimeSlots from "./TimeSlots";
import InfoBelowCalendar from "./InfoBelowCalendar";
import useCalendarMethods from "./useCalendarMethods";
import useCalendarEffects from "./useCalendarEffects";


/**
 * @component
 * @description This component manages the display and interaction with a calendar interface,
 * allowing users to select dates and time slots for a service. It handles the logic for rendering available
 * time slots, disabling dates, and managing button states for refreshing time slot availability.
 *
 * @param {boolean} showTimeSlots - A flag to determine whether to show the time slots view.
 * @param {Function} setShowTimeSlots - Function to toggle the visibility of the time slots view.
 * @param {Function} setDate - Function to set the selected date.
 * @param {Function} handleTime - Function to handle the selection of a time slot.
 * @param {Function} getTimeSlotsForParticularMonth - Function to fetch available time slots for a specific month.
 * @param {string} serviceName - The name of the service for which the calendar is being displayed.
 * @param {dayjs.Dayjs} currentMonth - The currently selected month in the calendar.
 * @param {Function} setCurrentMonth - Function to update the currently selected month.
 * @param {string|null} startDate - The selected start date for the service.
 * @param {string|null} endDate - The selected end date for the service.
 */

const MyCalendar = ({
  showTimeSlots,
  setShowTimeSlots,
  setDate,
  handleTime,
  getTimeSlotsForParticularMonth,
  serviceName,
  currentMonth,
  setCurrentMonth,
  startDate,
  endDate,
}) => {
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [buttonState, setButtonState] = useState({
    isVisible: false,
    countdownTimer: 90,
    popoverVisible: false,
  });
  const { isLoadingSlots } = useSelector((str) => str.loadingReducer);
  const { slotDetails } = useSelector((str) => str.bookAppointmentReducer);
  const { onPanelChange, headerRender, renderCell } = useCalendarMethods({
    setCurrentMonth,
    getTimeSlotsForParticularMonth,
    slotDetails,
    serviceName,
    setShowTimeSlots,
    setDate,
    setSelectedTimeSlots,
  });
  useCalendarEffects({
    setButtonState,
    buttonState,
    getTimeSlotsForParticularMonth,
    currentMonth,
    serviceName,
  });

  const handleButtonClick = () => {
    getTimeSlotsForParticularMonth({
      date: currentMonth,
      serviceName: serviceName,
    });

    setButtonState({
      ...buttonState,
      isVisible: false,
      popoverVisible: false,
      countdownTimer: 90,
    });
  };

  if (isLoadingSlots) {
    return <Spinner />;
  }

  return (
    <>
      {!showTimeSlots && (
        <>
          <Calendar
            fullscreen={false}
            value={currentMonth}
            onPanelChange={onPanelChange}
            headerRender={headerRender}
            disabledDate={(date) => {
              const today = date.endOf("day").isBefore(dayjs());
              const isBeforeStartDate =
                startDate && !date.isSame(dayjs(startDate.split(" ")[0]), "day")
                  ? date.isBefore(dayjs(startDate.split(" ")[0]))
                  : false;
              const isAfterEndDate =
                endDate && !date.isSame(dayjs(endDate.split(" ")[0]), "day")
                  ? date.isAfter(dayjs(endDate.split(" ")[0]))
                  : false;
              return today || isBeforeStartDate || isAfterEndDate;
            }}
            fullCellRender={renderCell}
          />
          <InfoBelowCalendar
            buttonState={buttonState}
            handleButtonClick={handleButtonClick}
            setButtonState={setButtonState}
          />
        </>
      )}
      {showTimeSlots && (
        <TimeSlots
          setShowTimeSlots={setShowTimeSlots}
          selectedTimeSlots={selectedTimeSlots}
          handleTime={handleTime}
          slotDetails={slotDetails}
        />
      )}
    </>
  );
};

export default MyCalendar;
