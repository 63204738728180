import React, { useEffect } from "react";
import { bookAppointmentAction } from "../State/bookAppointmentActions";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "../../context/SocketContext";
import dayjs from "dayjs";

const useFinalBookAppointmentEffects = ({
  setIsModalVisible,
  setSlotsExpired,
  // bookingTime,
  // setTimeRemaining,
  // timeRemaining
}) => {
  const dispatch = useDispatch();
  const { currentSocket } = useSocket();
  const { bookappointmentdetails, details, updateddetails } = useSelector(
    (str) => str.bookAppointmentReducer
  );
  const { updatedProfileDetails, updatedPetProfileDetails } = useSelector(
    (str) => str.profileReducer
  );

  // useEffect(() => {
  //   const calculateTimeRemaining = () => {
  //     const now = new Date();
  //     const endTime = new Date(bookingTime);
  //     const diff = endTime - now; // Time difference in milliseconds

  //     if (diff > 0) {
  //       setTimeRemaining(diff);
  //     } else {
  //       setTimeRemaining(0); // Time expired
  //     }
  //   };

  //   calculateTimeRemaining(); // Calculate immediately
  //   const interval = setInterval(() => {
  //     calculateTimeRemaining(); // Update every second
  //   }, 1000);

  //   return () => clearInterval(interval); // Cleanup on component unmount
  // }, [bookingTime]);

  const calculateTimeRemaining = (expiry) => {
    const now = dayjs();
    const expiryTime = dayjs(expiry);
    const diff = expiryTime.diff(now, "second");
    return Math.max(0, diff);
  };

  // Function to format time as HH:mm:ss
  const formatTime = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingMinutes = minutes % 60;
    const remainingSeconds = seconds % 60;

    return `${String(remainingMinutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  useEffect(() => {
    dispatch(
      bookAppointmentAction.getBookAppointmentdetailsEvent(
        localStorage.getItem("bookingID")
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (currentSocket) {
      currentSocket.on("slots_expired", (data) => {
        console.log("data", data);
        const { bookingId } = data;
        if (bookingId === bookappointmentdetails?.bookingDetails?._id) {
          setIsModalVisible(true); // Show the unclosable modal
          setSlotsExpired(true);
        }
      });
    }
    return () => {
      if (currentSocket) {
        currentSocket.off("slots_expired");
      }
    };
  }, [currentSocket]);

  useEffect(() => {
    if (
      updatedProfileDetails ||
      updatedPetProfileDetails ||
      details ||
      updateddetails
    )
      dispatch(
        bookAppointmentAction.getBookAppointmentdetailsEvent(
          localStorage.getItem("bookingID")
        )
      );
  }, [
    dispatch,
    updatedProfileDetails,
    updatedPetProfileDetails,
    details,
    updateddetails,
  ]);

  return {
    // formatTime,
    calculateTimeRemaining
  }
};

export default useFinalBookAppointmentEffects;
