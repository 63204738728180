import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Tag,
  theme,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "../Styles/finalPage.module.css";
import {
  ClockCircleOutlined,
  InfoCircleOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { services } from "../../Common/Components/Services";
import { useDispatch } from "react-redux";
import { bookAppointmentAction } from "../State/bookAppointmentActions";
import { useSocket } from "../../context/SocketContext";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Strings from "../../Constants/Strings";
const { Title, Text } = Typography;

/**
 * @component final payment page
 * @description This component displays the details of a payment.
 * @param {Object} details - services and its prices details to display and edit.
 */
const RightPayment = ({
  details,
  rewardDetails,
  slotsExpired,
  calculateTimeRemaining,
}) => {
  console.log("object");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [slotExtended, setSlotExtended] = useState(false);
  const [slotTimer, setSlotTimer] = useState("");
  const { currentSocket } = useSocket();
  const { useToken } = theme;
  const { token } = useToken();
  // Match bookings from history
  const bookingRewardHistory = rewardDetails?.rewardsHistories?.find(
    (reward) => reward.bookingId.toString() === details?._id.toString()
  );
  console.log("details", details);
  const timerExtRef = useRef();

  useEffect(() => {
    if (currentSocket) {
      currentSocket.on("slot_timer_extended", (data) => {
        const { bookingId, timer } = data;
        console.log(
          "data",
          data,
          bookingId,
          details.bookingUniqueId,
          bookingId === details?.bookingUniqueId
        );
        if (bookingId === details?.bookingUniqueId) {
          setSlotExtended(true);
          setSlotTimer(timer);
          // timerExtRef.current = timer;
        }
      });
    }
    return () => {
      if (currentSocket) {
        currentSocket.off("slot_timer_extended");
      }
    };
  }, [currentSocket]);

  const handleRedeemPoints = () => {
    const data = {
      bookingId: details?.bookingUniqueId,
      action: details?.redeem === false ? "REDEEM" : "UNREDEEM",
    };

    // Dispatch the action
    dispatch(bookAppointmentAction.redeemOrUnredeemRewardsEvent(data));
  };

  console.log("slotExtended", slotExtended);
  console.log("timerExtRef.current", timerExtRef.current);
  console.log("slotTimer", slotTimer);

  return (
    <Card hoverable>
      {/* Timer display */}
      <Row
        style={{
          marginTop: "1em",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>Complete payment in </Text>&nbsp;
        <CountdownCircleTimer
          isPlaying
          // duration={Strings.Common.timer * 60}
          duration={calculateTimeRemaining(
            slotExtended ? slotTimer : details?.countDown
          )}
          colors={[token.Primary]}
          size={40}
          strokeWidth={2}
        >
          {({ remainingTime }) => {
            if (remainingTime <= 0 || !remainingTime) {
              return <Text style={{ fontSize: "0.65em" }}>Expired</Text>;
            } else {
              const minutes = String(Math.floor(remainingTime / 60)).padStart(
                2,
                "0"
              );
              const seconds = String(remainingTime % 60).padStart(2, "0");
              return (
                <Text
                  style={{ fontSize: "0.65em" }}
                >{`${minutes}:${seconds}`}</Text>
              );
            }
          }}
        </CountdownCircleTimer>
      </Row>

      <Row>
        {details?.services?.map((service) => (
          <React.Fragment key={uuidv4()}>
            <Col span={18}>
              <Typography.Title style={{ fontSize: "1em" }}>
                {services?.find((e) => e.key === service?.serviceName).name}
                &nbsp;
                {service?.serviceType === "addon" && (
                  <Tooltip placement="topLeft" title="Addon">
                    <PlusCircleFilled />
                  </Tooltip>
                )}
              </Typography.Title>
            </Col>
            <Col span={6}>
              <Title className={styles.pricetext}>
                &#8377; {service?.price}
              </Title>
            </Col>
          </React.Fragment>
        ))}
        <Divider
          style={{
            background: "lightGrey",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <Col span={18}>
          <Title style={{ fontSize: "1em" }}>GST & other charges</Title>
        </Col>
        <Col span={6}>
          <Title className={styles.pricetext}>--</Title>
        </Col>
        {details?.redeem === true && (
          <>
            <Col span={18}>
              <Title style={{ fontSize: "1em" }}>You saved</Title>
            </Col>
            <Col span={6}>
              <Title className={styles.pricetext}>
                <Text className={styles.pricetext}>
                  ₹{bookingRewardHistory?.utilizedAmount / 10}{" "}
                  <Tooltip
                    color="#fdfdfd"
                    title={`You've saved ₹ ${
                      bookingRewardHistory?.utilizedAmount / 10
                    }, which is equivalent to 900 reward points (with 10 reward points equal to ₹1).`}
                    overlayInnerStyle={{ color: "#000" }}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Text>
              </Title>
            </Col>
          </>
        )}
        <Col span={18}>
          <Title style={{ fontSize: "1.2em" }}>TO PAY</Title>
        </Col>
        <Col span={6}>
          <Title className={styles.pricetext}>
            &#8377; {details?.price?.totalBillingAmount}
          </Title>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1.2em",
        }}
      >
        <Tag onClick={() => navigate("/accounts/subscriptionPlans")}>
          <InfoCircleOutlined /> Save more by subscribing to our plans
        </Tag>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1.2em",
        }}
      >
        <Tooltip
          color="#f9f3e6"
          title={`You can redeem points to get up to 20% off or save upto ₹${
            details?.boardCenter?.redeemCapSettings?.admin?.isActive === true
              ? details?.boardCenter?.redeemCapSettings?.admin?.capAmount
              : details?.boardCenter?.redeemCapSettings?.global?.capAmount
          } on your total bill.`}
          overlayInnerStyle={{ color: "#000" }}
        >
          <Button
            type="primary"
            onClick={handleRedeemPoints}
            disabled={slotsExpired ? true : false}
          >
            {details.redeem === false ? "Redeem points" : "Unredeem points"}
            <InfoCircleOutlined />
          </Button>
        </Tooltip>
      </div>
    </Card>
  );
};

export default RightPayment;
