import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL;

// add a blog
const addBlog = (data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
        },
    };
    return axios.post(`${apiURL}/addBlog`, data, config);
}

// get all blogs
const getAllBlogs = (page) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/news?page=${page}`, config);
}

// update blog based on id
const updateBlogById = (data, id) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
        },
    };
    return axios.put(`${apiURL}/updateNews/${id}`, data, config);
}

// get blog based on id
const getBlogById = (id) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/news/${id}`, config);
}


export const newsApi = {
    addBlog,
    getAllBlogs,
    updateBlogById,
    getBlogById
};