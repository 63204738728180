export const navbarConstants = {
    GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
    GET_USER_DETAILS_ERROR: "GET_USER_DETAILS_ERROR",

    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAILURE: "LOGOUT_FAILURE",

    RESET_USER_DETAILS: "RESET_USER_DETAILS",

    SEND_NOTIFICATION_SUCCESS: "SEND_NOTIFICATION_SUCCESS",
    SEND_NOTIFICATION_ERROR: "SEND_NOTIFICATION_ERROR",

    GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
    GET_NOTIFICATIONS_ERROR: "GET_NOTIFICATIONS_ERROR",

    UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",
    UPDATE_NOTIFICATION_ERROR: "UPDATE_NOTIFICATION_ERROR",

    MARK_ALL_AS_READ_SUCCESS:"MARK_ALL_AS_READ_SUCCESS",
    MARK_ALL_AS_READ_ERROR:"MARK_ALL_AS_READ_ERROR",

    GET_NOTIFICATION_COUNT_SUCCESS: "GET_NOTIFICATION_COUNT_SUCCESS",
    GET_NOTIFICATION_COUNT_ERROR:"GET_NOTIFICATION_COUNT_ERROR",

    CLEAR_NOTIFICATIONS: "CLEAR_NOTIFICATIONS",

    CLEAR_MARK_NOTIFICATIONS: "CLEAR_MARK_NOTIFICATIONS",
}