import { appconstants } from "./action-types";
const initialState = {
  isLoadingPetDetails: false,
  isLoadingBillingSub: false,
  isLoadingPaymentLinks: false,
  isLoadingParentDetails: false,
  isLoadingPostUpdatePetDetails: false,
  isLoadingAllUsers: false
};
const additionalloadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case appconstants.START_LOADING_PET_DETAILS:
      return {
        ...state,
        isLoadingPetDetails: true,
      };
    case appconstants.STOP_LOADING_PET_DETAILS:
      return {
        ...state,
        isLoadingPetDetails: false,
      };
    case appconstants.START_LOADING_BILLING_SUB:
      return {
        ...state,
        isLoadingBillingSub: true,
      };
    case appconstants.STOP_LOADING_BILLING_SUB:
      return {
        ...state,
        isLoadingBillingSub: false,
      };
    case appconstants.START_LOADING_PAYMENT_LINKS:
      return {
        ...state,
        isLoadingPaymentLinks: true,
      };
    case appconstants.STOP_LOADING_PAYMENT_LINKS:
      return {
        ...state,
        isLoadingPaymentLinks: false,
      };
    case appconstants.START_LOADING_PARENT_DETAILS:
      return {
        ...state,
        isLoadingParentDetails: true,
      };
    case appconstants.STOP_LOADING_PARENT_DETAILS:
      return {
        ...state,
        isLoadingParentDetails: false,
      };
    case appconstants.START_LOADING_POST_UPDATE_PET_DETAILS:
      return {
        ...state,
        isLoadingPostUpdatePetDetails: true,
      };
    case appconstants.STOP_LOADING_POST_UPDATE_PET_DETAILS:
      return {
        ...state,
        isLoadingPostUpdatePetDetails: false,
      };
    case appconstants.START_LOADING_ALL_USERS:
      return {
        ...state,
        isLoadingAllUsers: true,
      };
    case appconstants.STOP_LOADING_ALL_USERS:
      return {
        ...state,
        isLoadingAllUsers: false,
      };
    default:
      return state;
  }
};
export { additionalloadingReducer };
