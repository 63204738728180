import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "../Common/State/actions";
import Spinner from "../Spinner/Spinner";
import { useRoute } from "../context/RouteContext";
import { settingsAction } from "./State/actions";
import OngoingBookings from "./Components/OngoingBookings";
import PastBookings from "./Components/PastBookings";
import useSettingName from "../Common/Components/useSettingName";
import SettingsItems from "./Components/SettingsItems";
import useWarnings from "../Common/Components/useWarnings";

/**
 * @description
 * This component renders the Settings page, providing different tabs for user settings, including ongoing and past bookings,
 * pet profiles, addresses, tickets. It dynamically loads content based on the active tab and user role.
 */

const Settings = ({ showDrawer }) => {
  const isTab = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const { key } = useParams();
  const [activekey, setActivekey] = useState(key);

  const onChange = (key) => {
    navigate(`/accounts/${key}`);
  };
  const dispatch = useDispatch();
  const { parentBoardingDetails } = useSelector((str) => str.profileReducer);
  const { userDetails } = useSelector(str => str.navbarReducer);
  const {
    isLoading,
    isLoadingParentBoardingCenter,
    isLoadingUserDetails,
    isLoadingAllBookings
  } = useSelector((str) => str.loadingReducer);
  const { isLoadingPetDetails, isLoadingBillingSub } = useSelector(
    (str) => str.additionalloadingReducer
  );
  const [activekeyInOrders, setActivekeyInOrders] = useState("ongoing");
  const [page, setPage] = useState(1);


  useEffect(() => {
    if (activekey === "parentProfile") {
      dispatch(profileAction.getParentProfileEvent());
    } else if (activekey === "petProfile") {
      dispatch(profileAction.getPetProfileEvent());
      dispatch(profileAction.parentBoardingDetailsEvent());
    } else if (activekey === "orders") {
      dispatch(settingsAction.getAllBookingsEvent(1, activekeyInOrders));
    } else if (activekey === "tickets") {
      dispatch(settingsAction.getAllTickersForUserEvent());
    } else if (activekey === "subscriptionPlans") {
      dispatch(settingsAction.getAllSubscriptionPlans());
    }
    // eslint-disable-next-line
  }, [activekey, activekeyInOrders]);

  useEffect(() => {
    setActivekey(key);
  }, [key]);

  // set the name
  useSettingName("Settings");
  const { setRouteKey, setSelectedKey } = useRoute();

  useEffect(() => {
    setRouteKey("accounts");
    setSelectedKey("SETTINGS");
  }, [setRouteKey, setSelectedKey]);

  let [boardingCenterallowedPets, setBoardingCenterallowedPets] = useState();

  useEffect(() => {
    setBoardingCenterallowedPets(parentBoardingDetails?.pets);
  }, [parentBoardingDetails]);

  const getItems = [
    {
      key: "ongoing",
      label: "Ongoing Bookings",
      children: <OngoingBookings setPage={setPage} page={page} />,
    },
    {
      key: "past",
      label: "Past Bookings",
      children: <PastBookings setPage={setPage} page={page} />,
    },
  ];

  const items = SettingsItems({
    boardingCenterallowedPets,
    showDrawer,
    activekeyInOrders,
    setActivekeyInOrders,
    setPage,
    getItems,
  });

  if (
    isLoading ||
    isLoadingParentBoardingCenter ||
    isLoadingUserDetails || isLoadingBillingSub ||
    isLoadingPetDetails 
  ) {
    return <Spinner />;
  }

  const role = userDetails?.currentRole

  return (
    role === "USER" && (
      <Tabs
        tabPosition={isTab ? "top" : "left"}
        activeKey={activekey}
        items={items}
        onChange={onChange}
      />
    )
  );
};

export { Settings };
