import React, { useState } from "react";
import { roles } from "../../Common/Components/roles";
import { Button, Dropdown, Popconfirm, Space, Switch } from "antd";
import { CheckCircleFilled, DownOutlined, MailOutlined } from "@ant-design/icons";
import { usersApi } from "../utils/api";
import CommonModal from "../../CommonModal/CommonModal";
import ActiveDeactiveBody from "./ActiveDeactiveBody";

const UserActionList = ({
  roleType,
  email,
  success,
  error,
  setLoading,
  setPage,
  getUsersfn,
  active,
  verified,
}) => {
  const [modal, setModal] = useState({
    open: false,
    status: false,
  });

  const filteredRoles = roles?.filter((role) => {
    if (roleType?.includes("ADMIN")) {
      // If the user is an admin, exclude stateAdmin and cityAdmin
      return role.key !== "STATE_ADMIN" && role.key !== "CITY_ADMIN";
    }
    if (roleType?.includes("STATE_ADMIN")) {
      // If the user is a stateAdmin, exclude admin and cityAdmin
      return role.key !== "ADMIN" && role.key !== "CITY_ADMIN";
    }
    if (roleType?.includes("CITY_ADMIN")) {
      // If the user is a cityAdmin, exclude admin and stateAdmin
      return role.key !== "ADMIN" && role.key !== "STATE_ADMIN";
    }
    return true;
  });

  const items = filteredRoles
    ?.filter((role) => !roleType?.includes(role.key))
    ?.map((e) => ({
      key: e.key,
      label: e.label,
    }));

  const revokeItems = roles
    ?.filter((role) => roleType?.includes(role.key))
    ?.filter((role) => role.key !== "USER")
    ?.map((e) => ({
      key: e.key,
      label: e.label,
    }));

  const onAdd = async (e) => {
    setLoading(true);
    console.log(e);
    try {
      const response = await usersApi.updateUserRole(
        { role: e.key, userEmail: email },
        true
      );
      if (response?.data?.info) {
        setLoading(false);
        success("Role updated successfully");
        getUsersfn(1);
        setPage(1);
      }
    } catch (err) {
      setLoading(false);
      error(err?.response?.data?.message);
    }
  };

  const onRevoke = async (e) => {
    setLoading(true);
    try {
      const response = await usersApi.updateUserRole(
        { role: e.key, userEmail: email },
        false
      );
      if (response?.data?.info) {
        setLoading(false);
        success("Role revoked!!");
        setPage(1);
        getUsersfn(1);
      }
    } catch (err) {
      setLoading(false);
      error(err?.response?.data?.message);
    }
  };

  const handleSendVerification = async () => {
    try {
      const response = await usersApi.sendVerificationEmail(email);
      if (response?.data?.info) {
        success("Verification link sent successfully");
      }
    } catch (err) {
      error(err?.response?.data?.message);
    }
  };

  const handleChangeStatus = (e) => setModal({ open: true, status: e });

  const onFinish = async (values) => {
    try {
      const response = await usersApi.updateUser(email, {
        activate: {
          status: modal.status,
          reason: values.reason,
        },
      });
      if (response?.data?.info) {
        success("Success");
        setPage(1);
        getUsersfn(1);
      }
      setModal({ open: false, status: false });
    } catch (err) {
      error(err?.response?.data?.message);
      setModal({ open: false, status: false });
    }
  };

  const handleVerifyUser = async () => {
    try {
      const response = await usersApi.updateUser(email, {
        verification_required: true
      });
      if (response?.data?.info) {
        success("Success");
        setPage(1);
        getUsersfn(1);
      }
    } catch (err) {
      error(err?.response?.data?.message);
    }
  }

  return (
    <Space
      size={"middle"}
      style={{ flexWrap: "wrap", justifyContent: "center" }}
    >
      <Dropdown menu={{ items, onClick: onAdd }} style={{ cursor: "pointer" }}>
        <Button iconPosition="end" icon={<DownOutlined />} size="small">
          Assign role
        </Button>
      </Dropdown>
      <Dropdown
        menu={{ items: revokeItems, onClick: onRevoke }}
        style={{ cursor: "pointer" }}
      >
        <Button iconPosition="end" icon={<DownOutlined />} size="small">
          Revoke role
        </Button>
      </Dropdown>
      {!verified && (
        <>
          <Popconfirm
            title="Are you sure to send verification link?"
            onConfirm={handleSendVerification}
          >
            <Button size="small" icon={<MailOutlined />}>
              Send verification link
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure to verify this user?"
            onConfirm={handleVerifyUser}
          >
            <Button size="small" icon={<CheckCircleFilled />}>
              Verify manually
            </Button>
          </Popconfirm>
        </>
      )}
      <Switch
        checkedChildren={"Active"}
        unCheckedChildren={"Deactivated"}
        checked={active}
        onChange={handleChangeStatus}
      />
      <CommonModal
        handleCancel={() => setModal({ open: false, status: false })}
        handleOk={() => setModal({ open: false, status: false })}
        body={() => <ActiveDeactiveBody onFinish={onFinish} />}
        isModalOpen={modal.open}
        title={`Please enter the reason for ${modal.status ? "activating" : "deactivating"
          } the account`}
      />
    </Space>
  );
};

export default UserActionList;
