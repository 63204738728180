// import React, { useEffect, useState } from "react";
// import { Layout, Menu, Space, theme } from "antd";
// import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useMediaQuery } from "react-responsive";
// import OptionsComponent from "../../Common/Components/MenuData";
// import { useRoute } from "../../context/RouteContext";

// const { Sider } = Layout;
// /**
//  * Sidebar component renders a collapsible sidebar with navigation menu items.
//  * The visibility and collapse state of the sidebar are responsive and based on the current route.
//  *
//  * @returns {JSX.Element} The rendered Sidebar component.
//  *
//  * @description
//  * - Toggles visibility based on the current route (e.g., hides on "/addAddress").
//  * - Sidebar visibility adjusts based on screen size using `react-responsive`'s `useMediaQuery`.
//  */

// const Sidebar = () => {
//   const [collapsed, setCollapsed] = useState(true);
//   const istab = useMediaQuery({ maxWidth: 768 });
//   const [displaySidebar, setDisplaySidebar] = useState(true);
//   const location = useLocation();
//   const { selectedKey, setSelectedKey } = useRoute();

//   const { useToken } = theme;
//   const { token } = useToken();
//   // useEffect(() => {
//   //   if (location.pathname === "/addAddress") {
//   //     setDisplaySidebar(false);
//   //   } else {
//   //     setDisplaySidebar(true);
//   //   }
//   // }, [location]);

//   const navigate = useNavigate();
//   const onClick = (e) => {
//     // console.log(e, 'eee');
//     setSelectedKey(e?.key);
//     localStorage.setItem("newBookAppointment", true);
//     localStorage.removeItem("bookingID");
//     navigate(`/${e?.item?.props?.path}`);
//   };
//   return (
//     <Space
//       style={{
//         display: istab ? "none" : "",
//         paddingLeft: "15px",
//         height: "max-content",
//         position: "sticky",
//         top: "100px",
//         zIndex: 100,
//       }}
//     >
//       <Sider
//         trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
//         theme="light"
//         style={{
//           boxShadow: "1px 1px 5px grey",
//           paddingTop: "15px",
//           background: token.Secondary,
//         }}
//         collapsible
//         collapsed={collapsed}
//         onCollapse={(value) => setCollapsed(value)}
//       >
//         <Menu
//           onSelect={onClick}
//           selectedKeys={[selectedKey]}
//           inlineIndent={10}
//           style={{
//             background: token.Secondary,
//             height: "76vh",
//             overflow: "scroll",
//           }}
//           mode="inline"
//           items={OptionsComponent()}
//         />
//       </Sider>
//     </Space>
//   );
// };

// export default Sidebar;

import {
  LeftCircleFilled,
  LeftCircleOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Col, Image, Layout, Menu, Row, theme, Typography } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRoute } from "../../context/RouteContext";
import OptionsComponent from "../../Common/Components/MenuData";
import { MdToken } from "react-icons/md";
import HeyhomeLogo from "../../Assets/Header/logo 16-01 1.png";
import HeyhomeText from "../../Assets/Header/Hey home.png";
import { useMediaQuery } from "react-responsive";

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

const Sidebar = ({ collapsed, setCollapsed }) => {
  // const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const { selectedKey, setSelectedKey } = useRoute();
  const { useToken } = theme;
  const { token } = useToken();
  const isMediumScreen = useMediaQuery({ maxWidth: 768 });

  const siderStyle = {
    height: "100vh",
    position: "sticky",
    insetInlineStart: 0,
    overflow: "scroll",
    top: 0,
    bottom: 0,
    scrollbarWidth: 0,
    scrollbarColor: "unset",
  };

  const onClick = (e) => {
    // console.log(e, 'eee');
    setSelectedKey(e?.key);
    localStorage.setItem("newBookAppointment", true);
    localStorage.removeItem("bookingID");
    navigate(`/${e?.item?.props?.path}`);
  };

  return (
    <Sider
      width={isMediumScreen ? 0 : 250}
      style={siderStyle}
      trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      collapsedWidth={isMediumScreen ? 0 : 80}
    >
      <Row
        onClick={() => navigate("/")}
        style={{
          marginTop: "1.2em",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
      >
        <Image
          src={HeyhomeLogo}
          height={50}
          preview={false}
          style={{ objectFit: "cover" }}
        />
        <Image
          src={HeyhomeText}
          preview={false}
          style={{
            objectFit: "cover",
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 10,
          }}
        />
      </Row>
      <Menu
        onSelect={onClick}
        selectedKeys={[selectedKey]}
        style={{
          background: token.Secondary,
          height: "100vh",
          overflow: "scroll",
        }}
        theme="light"
        mode="inline"
        items={OptionsComponent()}
      />
    </Sider>
  );
};

export default Sidebar;
