import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import useWarnings from "../../Common/Components/useWarnings";
import { crudPlansApi } from "../utils/api";
import { useRoute } from "../../context/RouteContext";

const CreateCityForm = () => {
    const [form] = Form.useForm();
    const { success, error, contextHolder } = useWarnings();
    const [laoding, setLoading] = useState(false);
    const { setSelectedKey } = useRoute();
    const [uncreatedStates, setUncreatedStates] = useState({
        states: [],
        loading: false
    })
    const [unassignedCityAdmins, setUnassignedCityAdmins] = useState({
        admins: [],
        loading: false
    })
    const onFinish = async (values) => {
        setLoading(true);
        try {
            const responseCreateState = await crudPlansApi.createCity(values);
            if (responseCreateState?.data?.info?.code)
                success("City created successfully");
            form.resetFields();
            setLoading(false);
        } catch (err) {
            error(err?.response?.data?.message);
            setLoading(false);
        }
    };

    const getUncreatedStates = async () => {
        setUncreatedStates({ loading: true, states: [] })
        try {
            const response = await crudPlansApi.getUncreatedStates(true);
            if (response?.data?.info) {
                setUncreatedStates({ loading: false, states: response.data?.info })
            }
        } catch (err) {
            setUncreatedStates({ loading: false, states: [] })
        }
    }

    const getUnassignedCityAdmins = async () => {
        setUnassignedCityAdmins({ loading: true, admins: [] });
        try {
            const response = await crudPlansApi.getUnassignedCityAdmins();
            if (response?.data?.info) {
                setUnassignedCityAdmins({ loading: false, admins: response.data?.info })
            }
        } catch (err) {
            setUnassignedCityAdmins({ loading: false, admins: [] })
        }
    }

    useEffect(() => {
        setSelectedKey("CREATE_CITY");
        getUncreatedStates();
        getUnassignedCityAdmins();
    }, [])

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            {contextHolder}
            <Row gutter={16}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="State"
                        name={"stateName"}
                        rules={[{ required: true }]}
                    >
                        <Select
                            options={uncreatedStates?.states?.map((e) => ({
                                value: e,
                                label: e,
                            }))}
                            showSearch
                            loading={uncreatedStates?.loading}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="City"
                        name={"cityName"}
                        rules={[{ required: true }]}
                    >
                        <Input />

                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="Email Id of the city admin"
                        name={"email"}
                        rules={[{ required: true }]}
                    >
                        {/* <Input type="email" /> */}
                        <Select
                            options={unassignedCityAdmins?.admins?.map((e) => ({
                                value: e.email,
                                label: e.email,
                            }))}
                            showSearch
                            loading={unassignedCityAdmins?.loading}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Button type="primary" loading={laoding} htmlType="submit" size="small">
                    Create a city
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateCityForm;
