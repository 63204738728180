import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL;

function objectToQueryParams(data) {
  // Filter out any undefined or null values
  const queryParams = Object.keys(data)
    .filter(key => data[key] !== undefined && data[key] !== null)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');

  return queryParams;
}

// get data by id, tag ==> source
const getNewConfirmedBookingDetails = (boardCenterId, data) => {
  let token = localStorage.getItem("authToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  let queryString = objectToQueryParams(data);
  let url = `${apiURL}/getConfirmedBookings/${boardCenterId}?${queryString}`;

  // let url = `${apiURL}/getConfirmedBookings/${boardCenterId}`;
  // let url = `${apiURL}/getConfirmedBookings/${boardCenterId}?page=${page}&status=${status}&search=${search}`;
  return axios.get(url, config);
};

const getAllOnProcessAppointments = (boardCenterId) => {
  let token = localStorage.getItem("authToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  let url = `${apiURL}/onProcessAppointments/${boardCenterId}`;
  return axios.get(url, config);
};

const getAllCaptainsOfBoardingCenter = (bookingId) => {
  let token = localStorage.getItem("authToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  if (bookingId) {
    return axios.get(
      `${apiURL}/getAllCaptainsOfBoardingCenter?bookingId=${bookingId}`,
      config
    );
  } else {
    return axios.get(`${apiURL}/getAllCaptainsOfBoardingCenter`, config);
  }
};

const notifyCaptain = (captianId, bookingId, data) => {
  let token = localStorage.getItem("authToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return axios.put(
    `${apiURL}/notifyCaptain/${captianId}/${bookingId}`,
    data,
    config
  );
};

const updateBookingStatus = async (bookingId, data) => {
  let token = localStorage.getItem("authToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return await axios.put(
    `${apiURL}/statusUpdate/${bookingId}`,
    { ...data },
    config
  );
};

const getCaptainsBookings = async (captainId) => {
  let token = localStorage.getItem("authToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return await axios.get(`${apiURL}/getCaptainsBookings/${captainId}`, config);
};

const getAllAvailableKennelsForBooking = (boardCenterId, bookingId) => {
  let token = localStorage.getItem("authToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(
    `${apiURL}/getAllAvailableKennelsForTheBooking/${boardCenterId}/${bookingId}`,
    config
  );
};

const assignKennelToPet = (boardCenterId, bookingId, data) => {
  let token = localStorage.getItem("authToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.put(
    `${apiURL}/assignKennelToPet/${boardCenterId}/${bookingId}`,
    data,
    config
  );
};

const releaseKennel = (bookingId) => {
  let token = localStorage.getItem("authToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.put(`${apiURL}/releasePet/${bookingId}`, null, config);
};

const removeKennel = (boardCenterId, bookingId, data) => {
  let token = localStorage.getItem("authToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.put(
    `${apiURL}/removeKennel/${boardCenterId}/${bookingId}`,
    data,
    config
  );
};

// get bookappointment details
const getBookAppointmentDetails = (id) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/bookAppointmentDetails/${id}`, config);
};

export const admingApi = {
  getAllCaptainsOfBoardingCenter,
  getAllOnProcessAppointments,
  getCaptainsBookings,
  getNewConfirmedBookingDetails,
  notifyCaptain,
  getAllAvailableKennelsForBooking,
  updateBookingStatus,
  assignKennelToPet,
  releaseKennel,
  removeKennel,
  getBookAppointmentDetails,
};
