export const roles = [
    {
        label: "Super Admin",
        key: 'SUPER_ADMIN',
    },
    {
        label: "Admin",
        key: 'ADMIN',
    },
    {
        label: "User",
        key: 'USER',
    },
    {
        label: "Captain",
        key: 'CAPTAIN',
    },
    {
        label: "State Admin",
        key: 'STATE_ADMIN',
    },
    {
        label: "City Admin",
        key: 'CITY_ADMIN',
    },
]