import React, { useEffect, useState } from "react";
import { Col, Input, Select, theme, Typography } from "antd";
const { Option } = Select;
const { Text } = Typography;

const PetInputFields = ({ Form, boardingCenterallowedPets }) => {
  const { useToken } = theme;
  const { token } = useToken();

  const [petData, setPetData] = useState({
    selectedCategory: "domestic",
    selectedPet: null,
    petTypes: [],
    breeds: [],
  });

  useEffect(() => {
    const selectedCategoryData = boardingCenterallowedPets?.find(
      (pet) => pet.category === petData.selectedCategory
    );
    setPetData((prevData) => ({
      ...prevData,
      petTypes: selectedCategoryData ? selectedCategoryData.petType : [],
      selectedPet: null,
      breeds: [],
    }));
  }, [petData.selectedCategory, boardingCenterallowedPets]);

  const handleCategoryChange = (value) => {
    setPetData((prevData) => ({
      ...prevData,
      selectedCategory: value,
      selectedPet: null,
      breeds: [],
    }));
  };

  const handlePetChange = (value) => {
    const selectedPetData = petData.petTypes?.find((pet) => pet.name === value);
    setPetData((prevData) => ({
      ...prevData,
      selectedPet: value,
      breeds: selectedPetData ? selectedPetData.breeds : [],
    }));
  };

  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item label="Pet Name" rules={[{ required: true }]} name="name">
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item
          label="Pet Category"
          rules={[{ required: true }]}
          name="category"
        >
          <Select
            placeholder="Select a category"
            onChange={handleCategoryChange}
            notFoundContent={
              <Text disabled={true} style={{ color: token.Red }}>
                No boarding Center available at your location. Please Choose another location to add your pet details.
              </Text>
            }
          >
            {boardingCenterallowedPets?.map((pet) => (
              <Option key={pet.category} value={pet.category}>
                {pet.category.charAt(0).toUpperCase() + pet.category.slice(1)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <Form.Item label="Pet Type" rules={[{ required: true }]} name="type">
          <Select
            placeholder="Select a pet"
            onChange={handlePetChange}
            disabled={!petData.selectedCategory}
            notFoundContent={
              <Text disabled={true} style={{ color: token.Red }}>
                No boarding Center available at your location. Please Choose another location to add your pet details.
              </Text>
            }
          >
            {petData.petTypes.map((pet) => (
              <Option key={pet._id} value={pet.name}>
                {pet.name.charAt(0).toUpperCase() + pet.name.slice(1)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <Form.Item label="Pet Breed" rules={[{ required: true }]} name="breed">
          <Select placeholder="Select a breed" disabled={!petData.selectedPet}>
            {petData.breeds?.map((breed) => (
              <Option key={breed?._id} value={breed?.name}>
                {breed?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <Form.Item label="Gender" name="gender">
          <Select>
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
            <Option value="other">Other</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <Form.Item label="Pet Age" name="age">
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <Form.Item label="Nature of your pet" name="nature" rules={[{ required: true }]}>
          <Select>
            <Option value="calm">Calm</Option>
            <Option value="aggressive">Aggressive</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <Form.Item label="Favorite Foods" name="favoriteFood">
          <Input />
        </Form.Item>
      </Col>
    </>
  );
};

export default PetInputFields;
