import { appconstants } from "./action-types";
export const startLoadingPetDetails = () => ({
    type: appconstants.START_LOADING_PET_DETAILS
})

export const stopLoadingPetDetails = () => ({
    type: appconstants.STOP_LOADING_PET_DETAILS
});

export const startLoadingSubBilling = () => ({
    type: appconstants.START_LOADING_BILLING_SUB
})

export const stopLoadingSubBilling = () => ({
    type: appconstants.STOP_LOADING_BILLING_SUB
})

export const startLoadingPaymentLinks = () => ({
    type: appconstants.START_LOADING_PAYMENT_LINKS
})

export const stopLoadingPaymentLinks = () => ({
    type: appconstants.STOP_LOADING_PAYMENT_LINKS
})

export const startLoadingParentDetails = () => ({
    type: appconstants.START_LOADING_PARENT_DETAILS
})

export const stopLoadingParentDetails = () => ({
    type: appconstants.STOP_LOADING_PARENT_DETAILS
})

export const startLoadingPostUpdatePetDetails = () => ({
    type: appconstants.START_LOADING_POST_UPDATE_PET_DETAILS
})

export const stopLoadingPostUpdatePetDetails = () => ({
    type: appconstants.STOP_LOADING_POST_UPDATE_PET_DETAILS
})

export const startLoadingAllUsers = () => ({
    type: appconstants.START_LOADING_ALL_USERS
})

export const stopLoadingAllUsers = () => ({
    type: appconstants.STOP_LOADING_ALL_USERS
})