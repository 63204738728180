import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FloatButton,
  Popconfirm,
  Switch,
  Table,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { petMarketingAction } from "./State/actions";
import Spinner from "../Spinner/Spinner";
import useWarnings from "../Common/Components/useWarnings";
const { Text } = Typography;

const PetBreedsForMarketing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const {
    isLoadingGetAllQuestions,
    isLoading,
    isLoadingQuestionById,
  } = useSelector((str) => str.loadingReducer);
  const { error, success, contextHolder } = useWarnings();
  const {
    allPetsMarketing,
    totalPetsMarketing,
    addPetBreed,
    addPetBreedErr,
    updatePetBreed,
    updatePetBreedErr,
  } = useSelector((str) => str.petsMarketingReducer);

  useEffect(() => {
    dispatch(petMarketingAction.getPetMarketingEvent(1));
  }, []);

  useEffect(() => {
    if (addPetBreed) {
      success("Added Successfully");
      dispatch(petMarketingAction.getPetMarketingEvent(1));
      dispatch(petMarketingAction.clearPetsMarketing());
    } else if (addPetBreedErr) {
      error(addPetBreedErr);
      dispatch(petMarketingAction.clearPetsMarketing());
    }
    if (updatePetBreed) {
      success("Updated Successfully");
      dispatch(petMarketingAction.getPetMarketingEvent(1));
      dispatch(petMarketingAction.clearPetsMarketing());
    } else if (updatePetBreedErr) {
      error(updatePetBreedErr);
      dispatch(petMarketingAction.clearPetsMarketing());
    }
  }, [addPetBreed, addPetBreedErr, updatePetBreed, updatePetBreedErr]);


  const columns = [
    {
      title: "Type of pet",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: "10%",
    },
    {
      title: "Pet Breed",
      dataIndex: "breed",
      key: "breed",
      align: "center",
      width: "10%",
    },
    {
      title: "Redirect URL",
      dataIndex: "redirectURL",
      key: "redirectURL",
      align: "center",
      width: "10%",
    },
    {
      title: "Created On",
      key: "createdAt",
      align: "center",
      width: "10%",
      render: (_, { createdAt }) => (
        <Text>{dayjs(createdAt).format("DD MMM YYYY")}</Text>
      ),
    },
    {
      title: "Show on Home page",
      key: "showOnHomePage",
      align: "center",
      width: "10%",
      render: (_, { showOnHomePage, redirectURL }) => (
        <Checkbox
          onChange={(e) =>
            dispatch(
              petMarketingAction.updatePetMarketingEvent(redirectURL, {
                showOnHomePage: e.target.checked,
              })
            )
          }
          checked={showOnHomePage}
        ></Checkbox>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "20%",
      render: (_, { redirectURL, active }) => (
        <>
          <EditOutlined
            style={{ fontSize: "1.2em", marginRight: "1.2em" }}
            onClick={() => handleEdit(redirectURL)}
          />
          <Switch
            checked={active}
            size="small"
            style={{ marginRight: "1.2em" }}
            onClick={(e) => handleSwitch(e, redirectURL)}
          />
          <Popconfirm
            title="Delete the question"
            description="Are you sure to delete this pet breed?"
            icon={<QuestionCircleOutlined />}
            onConfirm={() =>
              dispatch(
                petMarketingAction.updatePetMarketingEvent(redirectURL, {
                  isDeleted: true,
                })
              )
            }
          >
            <DeleteOutlined style={{ fontSize: "1.2em" }} />
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleSwitch = (e, id) =>
    dispatch(
      petMarketingAction.updatePetMarketingEvent(id, {
        active: e,
      })
    );

  const handleEdit = (id) => navigate(`/pet/${id}`);

  const loadMoreData = (page) => {
    if (
      !isLoadingGetAllQuestions &&
      allPetsMarketing.length < totalPetsMarketing
    ) {
      dispatch(petMarketingAction.getPetMarketingEvent(page));
    } else {
      setPage(1);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={allPetsMarketing}
        rowKey={(record) => record.petBreedUniqueId}
        bordered
        size="middle"
        loading={isLoadingGetAllQuestions || isLoading || isLoadingQuestionById}
        pagination={false}
        onScroll={(e) => {
          const { scrollTop, scrollHeight, clientHeight } = e.target;
          if (scrollTop + clientHeight >= scrollHeight - 50) {
            if (!isLoadingGetAllQuestions) {
              const nextPage = page + 1;
              setPage(nextPage);
              loadMoreData(nextPage);
            }
          }
        }}
        scroll={{ y: 500 }}
      />
      {contextHolder}
      <FloatButton
        icon={<PlusOutlined />}
        onClick={() => navigate("/addPet")}
        type="primary"
        style={{
          right: 30,
        }}
      />
    </>
  );
};

export { PetBreedsForMarketing };
