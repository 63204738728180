import { petMarketingConstants } from "./action-types";

const initialState = {
    addPetBreed: false,
    addPetBreedErr: false,
    allPetsMarketing: false,
    allPetsMarketingErr: false,
    totalPetsMarketing: 0,
    updatePetBreed: false,
    updatePetBreedErr: false,
    getPetById: false,
    getPetByIdErr: false
    //     updateBlog: false,
    //     updateBlogErr: false,
    //     getBlogById: false,
    //     getBlogByIdErr: false,
    //     totalBlogs: 0
};

const petsMarketingReducer = (state = initialState, action) => {
    switch (action.type) {
        case petMarketingConstants.ADD_PET_SUCCESS:
            return {
                ...state,
                addPetBreed: action.data,
                addPetBreedErr: false
            }
        case petMarketingConstants.ADD_PET_ERROR:
            return {
                ...state,
                addPetBreed: false,
                addPetBreedErr: action.valuesError
            }
        case petMarketingConstants.GET_ALL_PETS_SUCCESS:
            return {
                ...state,
                allPetsMarketing: action.data.currentPage === 1
                    ? action.data.docs
                    : [...state.allPetsMarketing, ...action.data.docs],
                allPetsMarketingErr: false,
                totalPetsMarketing: action.data.totalDocs
            }
        case petMarketingConstants.GET_ALL_PETS_ERROR:
            return {
                ...state,
                allPetsMarketing: false,
                allPetsMarketingErr: action.valuesError,
                totalPetsMarketing: 0
            }
        case petMarketingConstants.UPDATE_PET_SUCCESS:
            return {
                ...state,
                updatePetBreed: action.data,
                updatePetBreedErr: false
            }
        case petMarketingConstants.UPDATE_PET_ERROR:
            return {
                ...state,
                updatePetBreed: false,
                updatePetBreedErr: action.valuesError
            }
        case petMarketingConstants.GET_PET_BY_ID_SUCCESS:
            return {
                ...state,
                getPetById: action.data,
                getPetByIdErr: false
            }
        case petMarketingConstants.GET_PET_BY_ID_ERROR:
            return {
                ...state,
                getPetById: false,
                getPetByIdErr: action.valuesError
            }
        // case newsConstants.GET_ALL_BLOGS_SUCCESS:
        //     return {
        //         ...state,
        //         allBlogs: action.data.currentPage === 1
        //             ? action.data.docs
        //             : [...state.allBlogs, ...action.data.docs],
        //         allBlogsErr: false,
        //         totalBlogs: action.data.totalDocs
        //     }
        // case newsConstants.GET_ALL_BLOGS_ERROR:
        //     return {
        //         ...state,
        //         allBlogs: false,
        //         allBlogsErr: action.valuesError,
        //         totalBlogs: 0
        //     }
        // case newsConstants.UPDATE_BLOG_SUCCESS:
        //     return {
        //         ...state,
        //         updateBlog: action.data,
        //         updateBlogErr: false
        //     }
        // case newsConstants.UPDATE_BLOG_ERROR:
        //     return {
        //         ...state,
        //         updateBlog: false,
        //         updateBlogErr: action.valuesError
        //     }
        // case newsConstants.GET_BLOG_BY_ID_SUCCESS:
        //     return {
        //         ...state,
        //         getBlogById: action.data,
        //         getBlogByIdErr: false,
        //     }
        // case newsConstants.GET_BLOG_BY_ID_ERROR:
        //     return {
        //         ...state,
        //         getBlogById: false,
        //         getBlogByIdErr: action.valuesError,
        //     }
        case petMarketingConstants.CLEAR_PETS_BREEDS:
            return {
                ...state,
                addPetBreed: false,
                addPetBreedErr: false,
                updatePetBreedErr: false,
                updatePetBreed: false
            }
        default:
            return state;
    }
};
export { petsMarketingReducer };
