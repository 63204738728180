import { LeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from "../Styles/login.module.css";
const { Text } = Typography;

const ResetPasswordForm = ({ onFinish,buttonName, loading }) => {
    const [form] = Form.useForm();
    return (
        <>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    required
                    name={"password"}
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            message: "Please input your password!",
                        },
                        {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message: "Must contain atleast 1 uppercase, 1 lowercase, 1 number, and 1 special character.Minimum 8 characters.",
                        },
                    ]}
                >
                    <Input.Password
                        placeholder="Enter new password"
                        className={styles.formInput}
                    />
                </Form.Item>
                <Form.Item
                    required
                    name={"cpassword"}
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Please input your Password!",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject("Password do not match!");
                            },
                        }),
                    ]}
                >
                    <Input
                        placeholder="Confirm password"
                        className={styles.formInput}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        htmlType="submit"
                        style={{
                            width: "100%",
                            height: "45px",
                            backgroundColor: "#3A60A1",
                            color: "white",
                            fontSize: "18px",
                        }}
                        loading={loading}
                    >
                        {buttonName}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default ResetPasswordForm
