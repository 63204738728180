import { CopyOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Row, Typography } from 'antd';
import React from 'react'
const {Text} = Typography;

const InviteModal = ({ isVisible, onCancel, referralLink }) => {
    const handleCopy = () => {
      navigator.clipboard.writeText(referralLink);
      message.success("Referral link copied!");
    };
  
    return (
      <Modal
        title="Invite your friends"
        open={isVisible}
        onCancel={onCancel}
        footer={[
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="done" type="primary" onClick={onCancel}>
            Done
          </Button>,
        ]}
        width={600}
      >
        <Row gutter={[16, 16]} justify="center" style={{ padding: "10px" }}>
          <Col span={24}>
            <Text strong>Signup URL:</Text>
          </Col>
          <Col span={24} style={{ display: "flex", alignItems: "center" }}>
            <Text
              style={{
                backgroundColor: "#f0f0f0",
                padding: "8px 12px",
                borderRadius: "5px",
                width: "100%",
                whiteSpace: "normal",
                overflow: "visible",
                textOverflow: "clip",
              }}
            >
              {referralLink}
            </Text>
            <Button
              icon={<CopyOutlined />}
              onClick={handleCopy}
              style={{ marginLeft: "10px" }}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

export default InviteModal
