import { supportConstants } from "./action-types";
const initialState = {
  createTicket: false,
  createTicketErr: false,
  updateTicket: false,
  updateTicketErr: false,
  allTickets: false,
  allTicketsErr: false,
  allBookingsForUserSupport: false,
  allBookingsForUserSupportErr: false,
  totalTickets: 0
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case supportConstants.CREATE_TICKET_SUCCESS:
      return {
        ...state,
        createTicket: action.data,
        createTicketErr: false
      }
    case supportConstants.CREATE_TICKET_ERROR:
      return {
        ...state,
        createTicket: false,
        createTicketErr: action.valuesError
      }
    case supportConstants.GET_ALL_TICKETS_SUCCESS:
      return {
        ...state,
        allTickets: action.data.currentPage === 1 ? action.data.docs : [...state.allTickets, ...action.data.docs],
        allTicketsErr: false,
        totalTickets: action.data.totalDocs
      }
    case supportConstants.GET_ALL_TICKETS_ERROR:
      return {
        ...state,
        allTickets: false,
        allTicketsErr: action.valuesError,
        totalTickets: 0
      }
    case supportConstants.UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        updateTicket: action.data,
        updateTicketErr: false
      }
    case supportConstants.UPDATE_TICKET_ERROR:
      return {
        ...state,
        updateTicket: false,
        updateTicketErr: action.valuesError
      }
    case supportConstants.CLEAR_SUPPORT_DATA:
      return {
        ...state,
        updateTicket: false,
        createTicket: action.data,
        createTicketErr: false,
        updateTicketErr: false
      }
    case supportConstants.GET_ALL_BOOKINGS_OF_USER_SUCCESS:
      return {
        ...state,
        allBookingsForUserSupport: action.data,
        allBookingsForUserSupportErr: false,
      }
    case supportConstants.GET_ALL_BOOKINGS_OF_USER_ERROR:
      return {
        ...state,
        allBookingsForUserSupport: false,
        allBookingsForUserSupportErr: action.valuesError
      }
    default:
      return state;
  }
};
export { supportReducer };
