import React from "react";
import { Link } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import styles from '../Styles/header.module.css';
import { Tag, Typography } from "antd";
const { Text } = Typography;

/**
 * Generates an array of profile-related menu items for the navigation bar.
 * @returns {Array} An array of profile-related menu item objects, each with properties such as `key`, `label`, `children`, `icon`, and `onClick`.
 * 
 * @description
 * - **Menu Items**:
 *   - **Dashboard**: A link to the dashboard.
 *   - **Switch Role**: A menu item to switch roles, shown if the user has multiple roles. It displays sub-items for each role that is not the current role.
 *   - **Orders**: A link to the user's orders, visible only if the current role is "USER".
 *   - **Tickets**: A link to the user's tickets, visible only if the current role is "USER".
 *   - **Logout**: A menu item for logging out, with an icon and associated click handler.
 */


const ProfileItems = ({ userDetails, currentRole, onSwitchRole, onClickEvent }) => {

    return [
        {
            label: userDetails?.currentRole !== "USER" ? (
                <div >
                    <Tag color="blue" style={{cursor:"none"}}>
                        {userDetails?.currentRole}
                    </Tag>
                </div>
            ) : (
                ""
            )
        },

        {
            key: "1",
            label: <Link to="/">Dashboard</Link>
        },
        ...(userDetails && Object.keys(userDetails.roleAccessDetails?.[0] || {})
            .filter(key => key !== "_id" && key !== "__v")
            .length > 1
            ? [
                {
                    key: "2",
                    label: "Switch role",
                    children: Object.keys(userDetails.roleAccessDetails?.[0] || {})
                        .filter(key => key !== "_id" && key !== "__v")
                        .filter(role => role !== currentRole)
                        .map((role, index) => ({
                            key: `2-${index + 1}`,
                            label: role,
                            onClick: () => onSwitchRole(role),
                        })),
                    className: styles.visible,
                }
            ]
            : []),
        currentRole === "USER" && {
            key: "3",
            label: <Link to="/accounts/orders">Orders</Link>,
        },
        currentRole === "USER" && {
            key: "4",
            label: <Link to="/accounts/tickets">Tickets</Link>,
        },
        {
            key: "5",
            label: "Logout",
            icon: <LogoutOutlined />,
            onClick: onClickEvent,
        }
    ];
};

export default ProfileItems;
