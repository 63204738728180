// import React, { useEffect, useState } from "react";
// import { Button, Image, Modal, Upload, message } from "antd";
// import { PlusOutlined, UploadOutlined } from "@ant-design/icons";

// const beforeUpload = (file) => {
//     const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//     if (!isJpgOrPng) {
//         message.error("You can only upload JPG/PNG file!");
//     }
//     const isLt2M = file.size / 1024 / 1024 / 1024 / 1024 / 1024 / 1024 < 6;
//     if (!isLt2M) {
//         message.error("Image must smaller than 6MB!");
//     }
//     return isLt2M;
// };

// const beforeuploadpdf = (file) => {
//     const isPdf = file.type === "application/pdf";
//     if (!isPdf) {
//         message.error("You can only upload pdf file!");
//     }
//     return isPdf;
// };

// const dummyRequest = ({ file, onSuccess }) => {
//     setTimeout(() => {
//         onSuccess("ok");
//     }, 0);
// };

// function ImageUpload({
//     maxCount,
//     handleThumbnailChange,
//     origin,
//     value,
//     form,
//     type,
//     setCert,
//     // onChange,
// }) {
//     const [previewImage, setPreviewImage] = useState({
//         imageName: "",
//         imageFile: "",
//         isimageVisible: false,
//         // type: "",
//     });
//     const [imageUrl, setImageUrl] = useState();

//     const getBase64 = (img, callback) => {
//         const reader = new FileReader();
//         reader.addEventListener("load", () => callback(reader.result));
//         reader.readAsDataURL(img);
//     };

//     const onChange = (info) => {
//         if (info.file.status === "uploading") {
//             //   setLoading(true);
//             return;
//         }
//         if (info.file.status === "done" && origin !== "uploadPdf") {
//             // Get this url from response in real world.
//             getBase64(info.file.originFileObj, (url) => {
//                 // setLoading(false);
//                 setImageUrl(url);
//             });
//             form.setFieldValue("profileImage", info.file.originFileObj);
//         } else if (origin === "uploadPdf" && info.file.type === "application/pdf") {
//             form.setFieldValue("vaccineCertificate", info.file.originFileObj);
//             setCert(URL.createObjectURL(info.file.originFileObj));
//         }
//     };

//     const handlePreview = async (file) => {
//         if (!file.url && !file.preview) {
//             file.preview = await getBase64(file.originFileObj);
//         }
//         setPreviewImage({
//             ...previewImage,
//             imageName: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
//             imageFile: file.url || file.preview,
//             isimageVisible: true,
//         });
//     };

//     const handleCancel = () => {
//         setPreviewImage({
//             ...previewImage,
//             imageName: "",
//             imageFile: "",
//             isimageVisible: false,
//         });
//     };

//     return (
//         <>
//             <Upload
//                 multiple={false}
//                 showUploadList={false}
//                 listType={origin === "uploadPdf" ? "" : "picture"}
//                 onPreview={origin === "uploadPdf" ? "" : handlePreview}
//                 onChange={onChange}
//                 beforeUpload={origin === "uploadPdf" ? beforeuploadpdf : beforeUpload}
//                 style={{ cursor: "pointer", width: "100%" }}
//                 maxCount={maxCount}
//                 customRequest={dummyRequest}
//             >
//                 {type === "photo" &&
//                     (imageUrl ? (
//                         <Image
//                             src={imageUrl}
//                             preview={false}
//                             style={{
//                                 width: "200px",
//                                 height: "200px",
//                                 objectFit: "cover",
//                                 // width: "100%",
//                                 // height: "100%",
//                                 borderRadius: "50%",
//                             }}
//                             fallback="https://static.vecteezy.com/system/resources/thumbnails/005/545/335/small/user-sign-icon-person-symbol-human-avatar-isolated-on-white-backogrund-vector.jpg"
//                         />
//                     ) : // <img
//                         //     src={imageUrl}
//                         //     alt="avatar"
//                         //     style={{
//                         //         width: "200px",
//                         //         height: "200px",
//                         //         objectFit:"cover",
//                         //         // width: "100%",
//                         //         // height: "100%",
//                         //         borderRadius: "50%",
//                         //     }}
//                         // />
//                         value ? (
//                             <Image
//                                 src={value}
//                                 preview={false}
//                                 style={{
//                                     width: "200px",
//                                     height: "200px",
//                                     objectFit: "cover",
//                                     // width: "100%",
//                                     // height: "100%",
//                                     borderRadius: "50%",
//                                 }}
//                                 fallback="https://static.vecteezy.com/system/resources/thumbnails/005/545/335/small/user-sign-icon-person-symbol-human-avatar-isolated-on-white-backogrund-vector.jpg"
//                             />
//                         ) : (
//                             <button
//                                 style={{
//                                     borderColor: "black",
//                                     width: "200px",
//                                     height: "200px",
//                                     borderRadius: "50%",
//                                     // background: "none"
//                                 }}
//                                 type="button"

//                             >
//                                 <PlusOutlined />
//                                 <div style={{ marginTop: 8 }}>Upload</div>
//                             </button>
//                         ))}
//                 {type !== "photo" && <Button icon={<UploadOutlined />}>Upload</Button>}
//             </Upload>
//             <Modal
//                 open={previewImage?.isimageVisible}
//                 title={previewImage?.imageName}
//                 footer={null}
//                 onCancel={handleCancel}
//             >
//                 <img
//                     alt="example"
//                     style={{
//                         width: "100%",
//                     }}
//                     src={previewImage?.imageFile}
//                 />
//             </Modal>
//         </>
//     );
// }
// export { ImageUpload };

import React, { useEffect, useState } from "react";
import { Button, Image, Modal, Upload, message } from "antd";
import {
    PlusOutlined,
    UploadOutlined,
    DeleteOutlined,
    EditOutlined,
} from "@ant-design/icons";

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG files!");
    }
    const isLt6M = file.size / 1024 / 1024 < 6; // 6MB limit
    if (!isLt6M) {
        message.error("Image must be smaller than 6MB!");
    }
    return isJpgOrPng && isLt6M;
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => onSuccess("ok"), 0);
};

function ImageUpload({ maxCount, origin, value, form, type, setCert, formValueName }) {
    const [imageUrl, setImageUrl] = useState(value || "");
    const [previewImage, setPreviewImage] = useState({
        imageName: "",
        imageFile: "",
        isVisible: false,
    });

    const onChange = (info) => {
        if (info.file.status === "uploading") {
            //   setLoading(true);
            return;
        }
        if (info.file.status === "done" && origin !== "uploadPdf") {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                // setLoading(false);
                setImageUrl(url);
            });
            form.setFieldValue(formValueName, info.file.originFileObj);
        } else if (origin === "uploadPdf" && info.file.type === "application/pdf") {
            message.info("Uploaded pdf");
            form.setFieldValue("vaccineCertificate", info.file.originFileObj);
            setCert(URL.createObjectURL(info.file.originFileObj));
        }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleDelete = () => {
        setImageUrl(""); // Clear image
        form.setFieldValue(formValueName, null); // Reset form field}); // Reset form field
    };

    const handlePreview = async () => {
        if (!imageUrl) return;
        setPreviewImage({
            imageName: "Preview",
            imageFile: imageUrl,
            isVisible: true,
        });
    };

    useEffect(() => {
        setImageUrl(value);
    }, [value]);

    const handleCancelPreview = () =>
        setPreviewImage({ ...previewImage, isVisible: false });

    const beforeuploadpdf = (file) => {
        const isPdf = file.type === "application/pdf";
        if (!isPdf) {
            message.error("You can only upload pdf file!");
        }
        return isPdf;
    };

    return (
        <>
            <div style={{ position: "relative" }}>
                {type === "photo" &&
                    (imageUrl ? (
                        <>
                            <Image
                                src={imageUrl}
                                preview={false}
                                style={{
                                    width: "200px",
                                    height: "200px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    borderColor: "black",
                                }}
                                fallback="https://static.vecteezy.com/system/resources/thumbnails/005/545/335/small/user-sign-icon-person-symbol-human-avatar-isolated-on-white-backogrund-vector.jpg"
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    background: "rgba(0, 0, 0, 0.5)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                    opacity: 0,
                                    transition: "opacity 0.3s",
                                    cursor: "pointer",
                                }}
                                className="overlay"
                                onMouseEnter={(e) => (e.currentTarget.style.opacity = 1)}
                                onMouseLeave={(e) => (e.currentTarget.style.opacity = 0)}
                            >
                                <Upload
                                    multiple={false}
                                    showUploadList={false}
                                    listType={origin === "uploadPdf" ? "" : "picture"}
                                    onPreview={origin === "uploadPdf" ? "" : handlePreview}
                                    onChange={onChange}
                                    beforeUpload={
                                        origin === "uploadPdf" ? beforeuploadpdf : beforeUpload
                                    }
                                    style={{ cursor: "pointer", width: "100%" }}
                                    maxCount={maxCount}
                                    customRequest={dummyRequest}
                                >
                                    <Button
                                        icon={<EditOutlined />}
                                        style={{ marginRight: 10, borderRadius: "50%" }}
                                    />
                                </Upload>
                                <Button
                                    icon={<DeleteOutlined />}
                                    danger
                                    style={{ borderRadius: "50%" }}
                                    onClick={handleDelete}
                                />
                            </div>
                        </>
                    ) : (
                        <Upload
                            multiple={false}
                            showUploadList={false}
                            listType={origin === "uploadPdf" ? "" : "picture"}
                            onPreview={origin === "uploadPdf" ? "" : handlePreview}
                            onChange={onChange}
                            beforeUpload={
                                origin === "uploadPdf" ? beforeuploadpdf : beforeUpload
                            }
                            style={{ cursor: "pointer", width: "100%" }}
                            maxCount={maxCount}
                            customRequest={dummyRequest}
                        >
                            <button
                                style={{
                                    borderColor: "black",
                                    width: "200px",
                                    height: "200px",
                                    borderRadius: "50%",
                                }}
                                type="button"
                            >
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </button>
                        </Upload>
                    ))}
                {type !== "photo" && (
                    <Upload
                        multiple={false}
                        showUploadList={false}
                        listType={origin === "uploadPdf" ? "" : "picture"}
                        onPreview={origin === "uploadPdf" ? "" : handlePreview}
                        onChange={onChange}
                        beforeUpload={
                            origin === "uploadPdf" ? beforeuploadpdf : beforeUpload
                        }
                        style={{ cursor: "pointer", width: "100%" }}
                        maxCount={maxCount}
                        customRequest={dummyRequest}
                    >
                        {" "}
                        <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                )}
            </div>

            <Modal
                open={previewImage.isVisible}
                title={previewImage.imageName}
                footer={null}
                onCancel={handleCancelPreview}
            >
                <img
                    alt="Preview"
                    style={{ width: "100%" }}
                    src={previewImage.imageFile}
                />
            </Modal>
        </>
    );
}

export { ImageUpload };
