// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { navbarAction } from "../Navbar/State/actions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
const useGenerateToken = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const requestPermission = async () => {
      try {
        const permision = await Notification.requestPermission();
        console.log(permision);
        if (permision === "granted") {
          const token = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID_KEY,
          });
          const storedToken = localStorage.getItem("fcmToken");
          if (token && token !== storedToken) {
            dispatch(navbarAction.sendNotificationsEvent({ token: token }));
          }
          // const data = {
          //     token: token,
          //     message: {
          //         title: "test title",
          //         body: "test body",
          //     }
          // }
          // dispatch(navbarAction.sendNotificationsEvent(data))
        }
      } catch (error) {
        console.log(error, "error getting permission");
      }
    };
    requestPermission();
    // eslint-disable-next-line
  }, []);
};

export default useGenerateToken;
