import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { newsAction } from "./State/actions";
import {
  Checkbox,
  FloatButton,
  Popconfirm,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import useWarnings from "../Common/Components/useWarnings";
const { Text } = Typography;

const News = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoadingGetAllQuestions, isLoading } = useSelector(
    (str) => str.loadingReducer
  );
  const {
    allBlogs,
    totalBlogs,
    addBlog,
    addBlogErr,
    updateBlog,
    updateBlogErr,
  } = useSelector((str) => str.newsReducer);
  const { error, success, contextHolder } = useWarnings();
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(newsAction.getAllBlogsEvent(1));
  }, []);

  useEffect(() => {
    if (addBlog) {
      success("Added Successfully");
      dispatch(newsAction.getAllBlogsEvent(1));
      dispatch(newsAction.clearBlog());
    } else if (addBlogErr) {
      error(addBlogErr);
      dispatch(newsAction.clearBlog());
    }
    if (updateBlog) {
      success("Updated Successfully");
      dispatch(newsAction.getAllBlogsEvent(1));
      dispatch(newsAction.clearBlog());
    } else if (updateBlogErr) {
      error(updateBlogErr);
      dispatch(newsAction.clearBlog());
    }
  }, [addBlog, addBlogErr, updateBlog, updateBlogErr]);

  const onSwitch = (checked, id) => {
    dispatch(newsAction.updateBlogByIdEvent({ active: checked }, id));
  };

  const HandleSoftDelete = (id) =>
    dispatch(newsAction.updateBlogByIdEvent({ isDeleted: true }, id));

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "30%",
    },
    {
      title: "Created at",
      key: "createdAt",
      align: "center",
      width: "10%",
      render: (_, { createdAt }) => (
        <Text>{dayjs(createdAt).format("DD MMM YYYY")}</Text>
      ),
    },
    {
      title: "Redirect URL",
      dataIndex: "redirectURL",
      key: "redirectURL",
      align: "center",
      width: "20%",
    },
    {
      title: "Keywords",
      dataIndex: "keywords",
      key: "keywords",
      align: "center",
      width: "20%",
    },
    {
      title: "Show on Home page",
      key: "showOnHomePage",
      align: "center",
      width: "10%",
      render: (_, { showOnHomePage, redirectURL }) => (
        <Checkbox
          onChange={(e) =>
            dispatch(
              newsAction.updateBlogByIdEvent({
                showOnHomePage: e.target.checked,
              }, redirectURL)
            )
          }
          checked={showOnHomePage}
        ></Checkbox>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "20%",
      render: (_, { newsUniqueId, redirectURL, active }) => (
        <Space align="baseline">
          <Link to={`/addBlog/${redirectURL}`}>
            <EditOutlined
              style={{
                fontSize: "19px",
                cursor: "pointer",
              }}
            />
          </Link>
          <Switch
            size="small"
            checked={active}
            onClick={(checked) => onSwitch(checked, redirectURL)}
            style={{ width: "fit-content" }}
          />
          <Popconfirm
            title="Sure to delete temporarily?"
            onConfirm={() => HandleSoftDelete(redirectURL)}
          >
            <DeleteOutlined
              style={{
                color: "red",
                fontSize: "18px",
                cursor: "pointer",
              }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const loadMoreData = (page, key) => {
    if (!isLoadingGetAllQuestions && allBlogs.length < totalBlogs) {
      dispatch(newsAction.getAllBlogsEvent(page));
    } else {
      setPage(1);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        loading={isLoadingGetAllQuestions || isLoading}
        dataSource={allBlogs}
        rowKey={(record) => record.newsUniqueId}
        bordered
        size="middle"
        pagination={false}
        onScroll={(e) => {
          const { scrollTop, scrollHeight, clientHeight } = e.target;
          if (scrollTop + clientHeight >= scrollHeight - 50) {
            if (!isLoadingGetAllQuestions) {
              const nextPage = page + 1;
              setPage(nextPage);
              loadMoreData(nextPage);
            }
          }
        }}
        scroll={{ y: 500 }}
      />
      {contextHolder}
      <FloatButton
        icon={<PlusOutlined />}
        onClick={() => navigate("/addBlog")}
        type="primary"
        style={{
          right: 30,
        }}
      />
    </>
  );
};

export { News };
