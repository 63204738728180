import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { boardCenterAction } from "../State/actions";
/**
 * @description `useBoardingCenterEffects` is a custom React hook that manages side effects for the `AddBoardingCenter` component.
 * It handles fetching, updating, and resetting the boarding center data, as well as setting form values and managing
 * related state such as services, locations, and selected pets.
 */
const useBoardingCenterEffects = ({
  form,
  id,
  setDefaultValue,
  setInfo,
  setMaps,
  maps,
  updateSelectedPreview,
  success,
  error,
  unassignedCaptainsfn
}) => {
  const dispatch = useDispatch();
  const {
    getBoardCenterById,
    updateBoardCenter,
    updateBoardCenterErr,
  } = useSelector((str) => str.boardCenterReducer);

  useEffect(() => {
    if (getBoardCenterById) {
      const priceFields = getBoardCenterById.services.reduce((acc, service) => {
        acc[`price_${service.serviceName}`] = service.price;
        acc[`slots_${service.serviceName}`] = service.totalSlots;
        return acc;
      }, {});

      const selectedValues = getBoardCenterById.pets.flatMap((category) =>
        category.petType.flatMap((petType) =>
          petType.breeds.map((breed) => [
            category.category,
            petType.name,
            breed.name,
          ])
        )
      );

      setDefaultValue(selectedValues);
      updateSelectedPreview(selectedValues);
      setInfo((prev) => ({
        ...prev,
        initialSelectedValues: selectedValues,
        selectedServices: getBoardCenterById.services.map((e) => e.serviceName),
        allowedLocations: getBoardCenterById.allowedLocations,
      }));
      setMaps({
        ...maps,
        fullLocation: {
          ...getBoardCenterById.address.completeAddress,
          lat: getBoardCenterById.address.completeAddress?.locationCoords?.lat,
          lng: getBoardCenterById.address.completeAddress?.locationCoords?.lang,
          eLoc: getBoardCenterById.address.completeAddress?.eLoc,
        },
        location: getBoardCenterById.address.fullAddress
      });
      form.setFieldsValue({
        address: getBoardCenterById.address.fullAddress,
        boardCenterHeadEmail: getBoardCenterById.boardCenterHead,
        name: getBoardCenterById.name,
        services: getBoardCenterById.services.map((e) => e.serviceName),
        categories: selectedValues,
        // globalCapAmount: getBoardCenterById?.globalCapAmount,
        capAmount: getBoardCenterById?.capAmount,
        ...priceFields,
      });
    }
  }, [getBoardCenterById]);

  useEffect(() => {
    if (id === undefined) {
      form.resetFields();
      setDefaultValue([]);
      setInfo((prev) => ({
        ...prev,
        selectedServices: [],
        selectedPreview: [],
        allowedLocations: []
      }));
      setMaps({ show: false, location: "", fullLocation: {} });
    } else if (id) {
      dispatch(boardCenterAction.getBoardCenterByIdEvent(id));
      unassignedCaptainsfn()
    }
  }, [id]);

  // useEffect(() => {
  //   if (updateBoardCenter || updateBoardCenterErr) {
  //     dispatch(boardCenterAction.getBoardCenterByIdEvent(id));
  //   }
  // }, [updateBoardCenter, updateBoardCenterErr]);

  useEffect(() => {
    if (updateBoardCenter) {
      dispatch(boardCenterAction.getBoardCenterByIdEvent(id));
      unassignedCaptainsfn();
    }
  }, [updateBoardCenter]);

  useEffect(() => {
    return () => {
      dispatch(boardCenterAction.clearBoardCenter());
    };
  }, []);
};

export default useBoardingCenterEffects;
