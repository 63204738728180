import CryptoJS from "crypto-js";
import forge from "node-forge";
export const GlobalEncrypt = (responseData) => {
  // Encrypt function using AES (symmetric encryption)
  function encryptData(data, key) {
    const algorithm = "aes-256-ccm"; // AES with 256-bit key in CBC mode
    const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV (Initialization Vector)
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      CryptoJS.enc.Hex.parse(key),
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return {
      iv: iv.toString(CryptoJS.enc.Hex), // Convert IV to hex string and include it with the encrypted data
      encryptedData: encrypted.toString(),
    };
  }
  // Encrypt the symmetric encryption key with RSA (asymmetric encryption)
  function encryptSymmetricKey(symKey, publicKey) {
    const publicKeyPem = `-----BEGIN PUBLIC KEY-----\n${publicKey}\n-----END PUBLIC KEY-----`;
    const publickKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const encrypted = publickKey.encrypt(symKey, "RSA-OAEP");
    return forge.util.encode64(encrypted);
  }
  // Generate a random symmetric encryption key
  const symKey = CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Hex); // 256-bit key for AES-256
  // Example public key (recipient's public key)
  const publicKey = `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4mlB5InD1M7rmzNEKvOH
    4dNJxdf3YTbpk9fTXLiReaO55wfu4WjxM5FI1SpOyr9sbd5lXJoDKaAA2SkON3CG
    Tusj9GtYegEwBdwu2Qyk1oFNdqLr1ONy4cqDlsw0wM0uTH4zi8NYQP3FOlD2h7f6
    aJjKADttyuGH4GtxWbba6Otb9u3dmDrFuxkSKxIUYk6bN8j65dH2CgBCoHgIAXE6
    6sLElKEkNvwkf0KG/c8Vu1WUfdYU2gE1v4cNFObgy310PKeO0Riaw17S3OlY0HAE
    Ir9ukH5B3tVdT2fD2M1+eD4B2dO5VV0/7aeyjGSD7stVS/gCMRh0v8Ec8opblwo2
    +wIDAQAB
    -----END PUBLIC KEY-----`;
  // Encrypt the data using AES
  const encryptedData = encryptData(responseData, symKey);
  // Encrypt the symmetric encryption key using RSA
  const encryptedKey = encryptSymmetricKey(symKey, publicKey);
  const encryptedPayload = {
    iv: encryptedData.iv,
    data: encryptedData.encryptedData,
    key: encryptedKey,
  };
  // Now, you can send `encryptedPayload` to the backend.
  return encryptedPayload;
};
