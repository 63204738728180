import { LeftCircleTwoTone, RightCircleTwoTone } from '@ant-design/icons';
import styles from '../Styles/actioncenter.module.css';

export function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={styles.nextArrow}
            onClick={onClick}
        >
            <RightCircleTwoTone twoToneColor={"#B96435"} style={{ fontSize: '25px' }} />
            {/* <RightOutlined style={{ color: 'white', fontSize: "10px" }} /> */}
        </div>
    );
}

export function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={styles.prevArrow}
            onClick={onClick}
        >
            <LeftCircleTwoTone twoToneColor={"#B96435"} style={{ fontSize: '25px' }} />

            {/* <LeftOutlined style={{color:'white', fontSize:"10px"}}/> */}
        </div>
    );
}