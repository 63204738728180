import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL;

// add a pet for marketing
const addPetMarketing = (data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
        },
    };
    return axios.post(`${apiURL}/addPetForMarketing`, data, config);
}

// get all pets for marketing
const getAllPets = (page) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/petsAndBreeds?page=${page}`, config);
}

// update pet based on id
const updatePetById = (id, data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
        },
    };
    return axios.put(`${apiURL}/updatePetsBreeds/${id}`, data, config);
}

// get pet based on id
const getPetById = (id) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/petBreed/${id}`, config);
}

export const petsMarketingApi = {
    addPetMarketing,
    getAllPets,
    updatePetById,
    getPetById
};