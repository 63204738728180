import React, { useEffect } from "react";
import { FloatButton, Table } from "antd";
import {
  CaretDownFilled,
  CaretRightFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { helpAction } from "./State/actions";
import Spinner from "../Spinner/Spinner";
import useHelpColumns from "./Components/useHelpColumns";

/**
 * Help component displays a list of frequently asked questions (FAQ) and provides functionality 
 * for editing and updating the status of each question.
 * 
 * @description
 * - Fetches all questions on mount using the `getAllQuestionEvent` action.
 * - Displays a loading spinner while fetching data.
 * - Provides expandable rows in the table for detailed question descriptions.
 * - Allows updating the status of a question and editing questions.
 * - Includes a floating button to navigate to the "Add Question" page.
 * 
 * @see {@link useHelpColumns} for the hook used to manage table columns and actions.
 * 
 * @param {Object} props - The component props.
 * @param {Function} props.handleEdit - Function to handle the editing of a question.
 * @param {Function} props.handleSwitch - Function to handle the update of a question's active status.
 */

const Help = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isLoadingGetAllQuestions,
    isLoading,
    isLoadingQuestionById,
  } = useSelector((str) => str.loadingReducer);
  const { allQuestions } = useSelector((str) => str.helpReducer);

  const handleSwitch = (e, id) => {
    dispatch(
      helpAction.updateQuestionByIdEvent(id, {
        isActive: e,
      })
    );
  };

  const handleEdit = (id) => {
    navigate(`/help/${id}`);
  };

  useEffect(() => {
    dispatch(helpAction.getAllQuestionEvent());
  }, []);

  const { columns } = useHelpColumns({ handleEdit, handleSwitch });

  if (isLoadingGetAllQuestions || isLoading || isLoadingQuestionById) {
    return <Spinner />;
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={allQuestions}
        rowKey={(record) => record.questionUniqueId}
        bordered
        size="middle"
        pagination={false}
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <p
        //       key={uuidv4()}
        //       style={{
        //         margin: 0,
        //       }}
        //       dangerouslySetInnerHTML={{ __html: record.description }}
        //     />
        //   ),
        //   expandIcon: ({ expanded, onExpand, record }) =>
        //     expanded ? (
        //       <CaretDownFilled onClick={(e) => onExpand(record, e)} />
        //     ) : (
        //       <CaretRightFilled onClick={(e) => onExpand(record, e)} />
        //     ),
        // }}
      />
      <FloatButton
        icon={<PlusOutlined />}
        onClick={() => navigate("/addQuestion")}
        type="primary"
        style={{
          right: 30,
        }}
      />
    </>
  );
};

export default Help;
