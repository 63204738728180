import CryptoJS from "crypto-js";
import forge from "node-forge";

export const GlobalDecrypt = (data, iv, encryptedKey) => {
  // Decrypt function using AES (symmetric encryption)
  function decryptData(encryptedData, key, iv1) {
    try {
      const iv = CryptoJS.enc.Hex.parse(iv1);
      const daa = CryptoJS.enc.Hex.parse(encryptedData);
      const decrypted = CryptoJS.AES.decrypt(
        {
          ciphertext: daa,
        },
        CryptoJS.enc.Hex.parse(key),
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      // Convert decrypted data to UTF-8 string
      const decryptedData = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } catch (error) {
      console.log(error, "errrrrrrrrrrr");
    }
  }

  const decryptSymmetricKey = (encryptedKey, privateKeyPem) => {
    try {
      const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
      const encrypted = forge.util.decode64(encryptedKey);
      const decrypted = privateKey.decrypt(encrypted, "RSAES-PKCS1-V1_5");
      return forge.util.bytesToHex(decrypted);
      // Return base64 encoded key for CryptoJS
    } catch (error) {
      console.error("Error decrypting symmetric key:", error);
      return null;
    }
  };

  // Example usage (assuming you have privateKey)
  const privateKey = `-----BEGIN PRIVATE KEY-----
        MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCjrTjI/5mNamHi
        wksowcL62D2av/xomo+mgDqQvUQxIL/hF9HCt6AF5mX6l9c9lhf4jeOishfYisVZ
        BlTpR8yS3uZ7t4CVBloEsnC9RGGXFeRG6E1lkHzBvBNqpj9F/GIlopVy7OEGqDLl
        ZeXhUAu7c6MAV/NWw53nLCdVPsZqcJNSUDj/Bs3fnCSTbM03e8x76Mj065g1PQqQ
        gsQ4+nymDLbB8XBNhHovFJiejms1o6TEIvFSJ1NXxan0qAZHjDNpSMDkoRN7S51y
        1iGJt+/OP0E/MgecKYdEUBl+ohlbXCCZYVZ0ypQhDq6hQkE06+fbaoms7hxaRVje
        3wfglYtfAgMBAAECggEAUAOQbQI26KEG9TmSUHxYexRXGV2dYO2eKAEroDJGMdYW
        t01Tp+LogbneRKESAfNsCIAkthZl/d/pFdo4jEojQfFhrz1HyJJwUzeAUaYqJxf2
        notVur7lnjf5LfvTlr0IpelTMfmHECANsYMc3AarlZm0J6jXNqUufiJhoBSuHr3c
        0/rPfoslydnyNQxSHWNu59TxwmYLqBdxvVBERITMjpzgOpjf9+cbhjKNPEHFUxli
        6kKc/hx4yNU3OqlfOHqz5f+Ck2Q8gr1y8HRo1t+SvIIKxmqbxtCMGHYjACtKlmLm
        7kzV5smnmKqotA6zl0dgZwzexXpwUHyCGqGCcow3MQKBgQDhfe3qc8W9uKJRzp+j
        RzNtYcmCRckIYWE7tBwGemGvpJ+Jin0u4Zqp9M421ILzNaM9wA00WuNagw7flSsX
        f29e/E7KPTeS6N6RUS7uqnHMY3NUN1eReB8iqK2wNYs/28Sei1l3MZiE2WEQInay
        Y5TonEgRN3+38mzSsoK6HEh1MwKBgQC50kcD8yAr5z7kkEyu3v6uPvWqL+148RAp
        yXKiaSNIo7PKQBdOLyUXcdMJFo3MHf4FaB0oyLeSUXY+H+D1epry8Z+mcCCU5Fxp
        6KuE7Kmr2LU05MeqkCplTZTMu6OOdD/XcqIWXjkWmMOM1J404G8lnRfH2YK82mxT
        75JkE2r5JQKBgQCb7aAC+3HnIvEkDvT3TLo0TmWxLx64K9a4GXEzzZcVhYpI6zpr
        m9h+5GlhIw02r4WlotO+u4byRSSY2acFxbq2/VRip3hxn5Sg+nmw0q6EvtS2jNqC
        HoWxSuFos24Kh6dsV0JTmTk1zLhBFwPrkc8cF04CkdVA12QHQygBGKPGXwKBgE4b
        0bYbUH6j6Y/yCAnJuoxL8r/i/ZNE4WJvRzx1rVsvVLfDUQiQUKsWMXs0e849HTXw
        mbE3DdL3YxiiFdGaA9/zKQSxRUDQ7Pen0dFCGeAea60cLcHIb8m9XrrgdNPAkcVi
        bjewy5ZwCGL+TLI1VQMekYx0Jbx0o+x0W7itYN3xAoGBANFpcJBeKlP2/czn5JWN
        YNqnOkh3mpy8BQASGcVQz8PLXILiiRR5OyJiFJbPzjdbtVTY0wKM4/QcgT+jaZrL
        E3zeBzTD2ARzu3TmTJOQKqNhRefZHGLB5t3LDkoa/Nlp/M7euwLkQeByh9co44Rn
        2+Ca3bLQptTKs4OZ215linjY
        -----END PRIVATE KEY-----`;
  const decryptedSymmetricKey = decryptSymmetricKey(encryptedKey, privateKey);

  const decryptedData = decryptData(data, decryptedSymmetricKey, iv);
  return decryptedData;
};
