export const loadRazorpayScript = function () {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_RAZORPAY_SCRIPT_LINK;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};
