import { navbarConstants } from "./action-types";

const initialState = {
    userDetails: {},
    userDetailsError: null,
    logoutdata: "",
    logouterr: false,
    notificationData: false,
    notificationError: false,
    allNotifications: false,
    totalNotifications: 0,
    allNotificationsError: false,
    updatedNotification: false,
    updatedNotificationError: false,
    markNotifications: false,
    notificationCount: false,
};

const navbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case navbarConstants.GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                userDetails: action.data,
                userDetailsError: false,
            };
        case navbarConstants.GET_USER_DETAILS_ERROR:
            return {
                ...state,
                userDetails: null,
                userDetailsError: action.valuesError,
            };
        case navbarConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                logoutdata: action.data,
                logouterr: false,
            }
        case navbarConstants.LOGOUT_FAILURE:
            return {
                ...state,
                logoutdata: "",
                logouterr: action.valuesError
            }
        case navbarConstants.RESET_USER_DETAILS:
            return {
                ...state,
                userDetails: {}
            }
        case navbarConstants.SEND_NOTIFICATION_ERROR:
            return {
                ...state,
                notificationData: action.data,
                notificationError: false,
            }
        case navbarConstants.SEND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationData: false,
                notificationError: action.valuesError,
            }
        case navbarConstants.GET_NOTIFICATIONS_ERROR:
            return {
                ...state,
                allNotifications: false,
                allNotificationsError: action.valuesError,
            }
        case navbarConstants.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                allNotifications: action.data.currentPage === 1 ? action.data.docs : [...state.allNotifications, ...action.data.docs],
                allNotificationsError: false,
                totalNotifications: action.data.totalDocs
            }
        case navbarConstants.UPDATE_NOTIFICATION_ERROR:
            return {
                ...state,
                updatedNotification: false,
                updatedNotificationError: action.valuesError
            }
        case navbarConstants.UPDATE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                updatedNotification: action.data,
                updatedNotificationError: false
            }
        case navbarConstants.MARK_ALL_AS_READ_SUCCESS:
            return {
                ...state,
                markNotifications: action.data,
            }
        case navbarConstants.MARK_ALL_AS_READ_ERROR:
            return {
                ...state,
                markNotifications: action.valuesError,
            }
        case navbarConstants.GET_NOTIFICATION_COUNT_SUCCESS:
            return {
                ...state,
                notificationCount: action.data,
            }
        case navbarConstants.GET_NOTIFICATION_COUNT_ERROR:
            return {
                ...state,
                notificationCount: action.valuesError,
            }
        case navbarConstants.CLEAR_NOTIFICATIONS:
            return {
                ...state,
                allNotifications: false,
                allNotificationsError: false,
                totalNotifications: 0
            }
        case navbarConstants.CLEAR_MARK_NOTIFICATIONS:
            return {
                ...state,
                markNotifications: false,
                updatedNotification: false
            }
        default:
            return state;
    }
};
export { navbarReducer };