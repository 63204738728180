export const newsConstants = {
    ADD_BLOG_SUCCESS: "ADD_BLOG_SUCCESS",
    ADD_BLOG_ERROR: "ADD_BLOG_ERROR",

    GET_ALL_BLOGS_SUCCESS: "GET_ALL_BLOGS_SUCCESS",
    GET_ALL_BLOGS_ERROR: "GET_ALL_BLOGS_ERROR",

    UPDATE_BLOG_SUCCESS: "UPDATE_BLOG_SUCCESS",
    UPDATE_BLOG_ERROR: "UPDATE_BLOG_ERROR",

    GET_BLOG_BY_ID_SUCCESS: "GET_BLOG_BY_ID_SUCCESS",
    GET_BLOG_BY_ID_ERROR: "GET_BLOG_BY_ID_ERROR",

    CLEAR_BLOGS:"CLEAR_BLOGS"
};
