import { Button, Card, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { ImageUploadBlog } from "../../Common/Components/ImageUploadBlog";
// import { ReactQuillComponentRender } from "../../Help/Components/ReactQuillComponentRender";
import { useDispatch, useSelector } from "react-redux";
import { newsAction } from "../State/actions";
import { useNavigate, useParams } from "react-router-dom";
import { ImageUpload } from "../../Common/Components/ImageUpload";
import { ReactQuillComponentRender } from "./ReactQuillComponentRender";
const { TextArea } = Input;

const AddBlog = () => {
  const [form] = Form.useForm();
  const [language, setLanguage] = useState("en");

  const [descriptions, setDescriptions] = useState({
    en: "",
    // hin: "",
    // tel: "",
    // mar: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { getBlogById } = useSelector((str) => str.newsReducer);

  useEffect(() => {
    if (id) dispatch(newsAction.getBlogByIdEvent(id));
  }, [id]);

  console.log(descriptions, "descriptions");

  useEffect(() => {
    if (getBlogById) {
      form.setFieldsValue({
        title: getBlogById.title,
        keywords: getBlogById.keywords,
        redirectURL: getBlogById.redirectURL,
        thumbnail: getBlogById.thumbnail,
        metaDescription: getBlogById.metaDescription,
      });
      setDescriptions(getBlogById.description);
    }
  }, [getBlogById]);

  useEffect(() => {
    return () => {
      dispatch(newsAction.clearBlog());
    };
  }, []);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList[0]?.originFileObj;
  };

  const handleChange = (content) => {
    setDescriptions((prev) => ({
      ...prev,
      [language]: content,
    }));
  };

  const onFinish = (data) => {
    const { description, thumbnail, ...cleanedFormData } = data;
    // Filter out all keys that start with 'description_'
    const restFormData = Object.keys(cleanedFormData)
      .filter((key) => !key.startsWith("description_")) // Exclude description_<language> fields
      .reduce((acc, key) => {
        acc[key] = cleanedFormData[key]; // Add other fields to the result
        return acc;
      }, {});

    // Construct the descriptions object with multiple languages
    const desc = Object.keys(descriptions).reduce((acc, lang) => {
      acc[lang] = descriptions[lang]; // e.g., en, hin, tel, mar
      return acc;
    }, {});

    // Create a FormData object to handle the multipart/form-data request
    const formData = new FormData();

    // Append non-file fields to FormData
    Object.keys(restFormData).forEach((key) => {
      formData.append(key, restFormData[key]);
    });

    // Append the multilingual descriptions as a JSON string
    formData.append("description", JSON.stringify(desc));

    // Append the thumbnail (binary file) if provided
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }

    // Identify changed fields if editing an existing blogst
    const changedFields = {};
    Object.keys(restFormData).forEach((field) => {
      if (
        !getBlogById.hasOwnProperty(field) ||
        restFormData[field] !== getBlogById[field]
      ) {
        changedFields[field] = restFormData[field];
      }
    });
    // Check if description has changed
    if (JSON.stringify(desc) !== JSON.stringify(getBlogById.description)) {
      changedFields.description = JSON.stringify(desc); // Add description change to changedFields
    }

    // Determine the action: Add or update a blog
    if (id) dispatch(newsAction.updateBlogByIdEvent(changedFields, id));
    else dispatch(newsAction.addBlogEvent(formData));

    navigate("/news");
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TextArea autoSize />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label="Redirect URL"
                  name="redirectURL"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label="Thumbnail"
                  name="thumbnail"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  getValueFromEvent={normFile}
                >
                  <ImageUploadBlog origin="thumbnail" maxCount={1} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label="Key words"
                  name="keywords"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item label="Meta Description" name="metaDescription">
                  <TextArea rows={2} placeholder="Meta Description" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <ReactQuillComponentRender
                  setLanguage={setLanguage}
                  language={language}
                  description={descriptions}
                  setDescription={setDescriptions}
                  handleChange={handleChange}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Card
              title="Preview"
              hoverable
              style={{ minHeight: "70vh" }}
              extra={
                <Button htmlType="submit" size="small" type="primary">
                  Submit
                </Button>
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: descriptions[language],
                }}
              />
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddBlog;

// import { Button, Card, Col, Form, Input, Row } from "antd";
// import React, { useEffect, useState } from "react";
// import { ImageUploadBlog } from "../../Common/Components/ImageUploadBlog";
// // import { ReactQuillComponentRender } from "../../Help/Components/ReactQuillComponentRender";
// import { useDispatch, useSelector } from "react-redux";
// import { newsAction } from "../State/actions";
// import { useNavigate, useParams } from "react-router-dom";
// import { ImageUpload } from "../../Common/Components/ImageUpload";
// import { ReactQuillComponentRender } from "../../Help/Components/ReactQuillComponentRender";
// const { TextArea } = Input;

// const AddBlog = () => {
//   const [form] = Form.useForm();
//   const [language, setLanguage] = useState("en");

//   const [description, setDescription] = useState("");
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const { getBlogById } = useSelector((str) => str.newsReducer);

//   useEffect(() => {
//     if (id) dispatch(newsAction.getBlogByIdEvent(id));
//   }, [id]);

//   useEffect(() => {
//     if (getBlogById) {
//       form.setFieldsValue(getBlogById);
//     }
//   }, [getBlogById]);

//   useEffect(() => {
//     return () => {
//       dispatch(newsAction.clearBlog());
//     };
//   }, []);

//   const normFile = (e) => {
//     if (Array.isArray(e)) {
//       return e;
//     }
//     return e?.fileList[0]?.originFileObj;
//   };

//   const onFinish = (values) => {
//     const changedFields = {};
//     Object.keys(values).forEach((field) => {
//       if (values[field] !== getBlogById[field]) {
//         // Add the field to the changedFields object if the values are different or it's a new field
//         changedFields[field] = values[field];
//       }
//     });
//     // Determine the action: Add or update a blog
//     if (id && Object.keys(changedFields).length > 0)
//       dispatch(newsAction.updateBlogByIdEvent(changedFields, id));
//     else dispatch(newsAction.addBlogEvent(values));

//     navigate("/news");
//   };

//   return (
//     <>
//       <Form form={form} layout="vertical" onFinish={onFinish}>
//         <Row gutter={16}>
//           <Col xs={24} sm={24} md={24} lg={12}>
//             <Row gutter={16}>
//               <Col xs={24} sm={24} md={12} lg={12}>
//                 <Form.Item
//                   label="Title"
//                   name="title"
//                   rules={[
//                     {
//                       required: true,
//                     },
//                   ]}
//                 >
//                   <TextArea autoSize />
//                 </Form.Item>
//               </Col>
//               <Col xs={24} sm={24} md={12} lg={12}>
//                 <Form.Item
//                   label="Redirect URL"
//                   name="redirectURL"
//                   rules={[
//                     {
//                       required: true,
//                     },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col xs={24} sm={24} md={12} lg={12}>
//                 <Form.Item
//                   label="Thumbnail"
//                   name="thumbnail"
//                   rules={[
//                     {
//                       required: true,
//                     },
//                   ]}
//                   getValueFromEvent={normFile}
//                 >
//                   <ImageUploadBlog origin="thumbnail" maxCount={1} />
//                 </Form.Item>
//               </Col>
//               <Col xs={24} sm={24} md={12} lg={12}>
//                 <Form.Item
//                   label="Key words"
//                   name="keywords"
//                   rules={[
//                     {
//                       required: true,
//                     },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col xs={24} sm={24} md={24} lg={24}>
//                 <Form.Item label="Meta Description" name="metaDescription">
//                   <TextArea rows={2} placeholder="Meta Description" />
//                 </Form.Item>
//               </Col>
//               <Col span={24}>
//                 <ReactQuillComponentRender setDescription={setDescription} />
//               </Col>
//             </Row>
//           </Col>
//           <Col xs={24} sm={24} md={24} lg={12}>
//             <Card
//               title="Preview"
//               hoverable
//               style={{ minHeight: "70vh" }}
//               extra={
//                 <Button htmlType="submit" size="small" type="primary">
//                   Submit
//                 </Button>
//               }
//             >
//               <div
//                 dangerouslySetInnerHTML={{
//                   __html: description,
//                 }}
//               />
//             </Card>
//           </Col>
//         </Row>
//       </Form>
//     </>
//   );
// };

// export default AddBlog;
