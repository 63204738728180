import { ReactComponent as CustomDashboardSVG } from "../../Assets/Header/ic_round-dashboard.svg";
import { ReactComponent as CustomServicesSVG } from "../../Assets/Header/services.svg";
import { ReactComponent as CustomSettingsSVG } from "../../Assets/Header/settings.svg";
import { ReactComponent as CustomLogoutSVG } from "../../Assets/Header/logout.svg";
import { PiBookFill } from "react-icons/pi";
import styles from "../Styles/iconstyles.module.css";
import { v4 as uuidv4 } from "uuid";
import Icon, { SettingFilled } from "@ant-design/icons";
import {
  MdCalendarMonth,
  MdHelp,
  MdOutlinePayments,
  MdPets,
  MdSubscriptions,
  MdSupportAgent,
} from "react-icons/md";
import { FaNewspaper, FaUserTie } from "react-icons/fa";
import { LiaWarehouseSolid } from "react-icons/lia";
import { RiHome2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { BsHouse } from "react-icons/bs";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { LuUsers } from "react-icons/lu";
import { Typography } from "antd";
const { Text } = Typography;

const CustomDashboardIcon = (props) => (
  <Icon component={CustomDashboardSVG} {...props} />
);
const CustomServicesIcon = (props) => (
  <Icon component={CustomServicesSVG} {...props} />
);
const CustomSettingsIcon = (props) => (
  <Icon component={CustomSettingsSVG} {...props} />
);
const CustomLogoutIcon = (props) => (
  <Icon component={CustomLogoutSVG} {...props} />
);
const OptionsComponent = () => {
  const { userDetails } = useSelector((state) => state.navbarReducer);
  const role = userDetails?.currentRole;
  const access = userDetails?.roleAccessDetails?.find((access) =>
    access?.hasOwnProperty(role)
  )?.[role];

  const optionMenu = {
    USER: [
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>Dashboard</Text>
        ),
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Book Appointment
          </Text>
        ),
        key: "CRUD_BOOK_APPOINTMENT",
        icon: <MdCalendarMonth />,
        className: styles.icon,
        path: "book-appointment/step-1",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>Settings</Text>
        ),
        key: "SETTINGS",
        icon: <SettingFilled />,
        className: styles.icon,
        path: "accounts/parentProfile",
      },
    ],
    SUPPORT: [
      {
        label: "dashboard",
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      {
        label: "serviceDetails",
        key: "SERVICE_DETAILS",
        icon: <CustomServicesIcon />,
        className: styles.icon,
      },
      {
        label: "settings",
        key: "SETTINGS",
        icon: <CustomSettingsIcon />,
        className: styles.icon,
      },
      {
        label: "Logout",
        key: uuidv4(),
        icon: <CustomLogoutIcon />,
        className: styles.icon,
      },
    ],
    CAPTAIN: [
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>Dashboard</Text>
        ),
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>settings</Text>
        ),
        key: "SETTINGS",
        icon: <CustomSettingsIcon />,
        className: styles.icon,
        path: "accounts/parentProfile",
      },
    ],
    ADMIN: [
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>Dashboard</Text>
        ),
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Service Details
          </Text>
        ),
        key: "SERVICE_DETAILS",
        icon: <CustomServicesIcon />,
        className: styles.icon,
        path: "services/newAppointments",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Requests for cancellation of bookings
          </Text>
        ),
        key: "BOOKING_CANCELLATION_APPROVAL",
        icon: <PiBookFill />,
        className: styles.icon,
        path: "cancellationRequests",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Support tickets
          </Text>
        ),
        key: "CRUD_TICKET",
        icon: <MdSupportAgent />,
        className: styles.icon,
        path: "tickets",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Payment Links
          </Text>
        ),
        key: "PAYMENT_STATUS",
        icon: <MdOutlinePayments />,
        className: styles.icon,
        path: `paymentLinks`,
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Update Boarding center
          </Text>
        ),
        key: "UPDATE_BOARDING_CENTER",
        icon: <BsHouse />,
        className: styles.icon,
        path: `boardingCenter/${userDetails?.boardCenterUniqueId}`,
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>Captains</Text>
        ),
        key: "CRUD_CAPTAIN",
        icon: <FaUserTie />,
        className: styles.icon,
        path: "captains",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>Kennels</Text>
        ),
        key: "CREATE_KENNEL",
        icon: <LiaWarehouseSolid />,
        className: styles.icon,
        path: "kennels",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Vaccine Certificates
          </Text>
        ),
        key: "GET_UNVERIFIED_VACCINE_CERTIFICATES",
        icon: <AiOutlineSafetyCertificate />,
        className: styles.icon,
        path: "vaccineCertificates",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Subscription Plans details
          </Text>
        ),
        key: "CREATE_PLAN",
        icon: <MdSubscriptions />,
        className: styles.icon,
        path: "plans",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>Users</Text>
        ),
        key: "GET_ALL_USERS",
        icon: <LuUsers />,
        className: styles.icon,
        path: "users",
      },
    ],
    SUPER_ADMIN: [
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>Dashboard</Text>
        ),
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Boarding centers
          </Text>
        ),
        key: "CRUD_BOARDING_CENTER",
        icon: <RiHome2Fill />,
        className: styles.icon,
        path: "allBoardingCenters",
        children: [
          {
            key: "CRUD_BOARDING_CENTE",
            label: "Boarding center",
            path: "allBoardingCenters",
          },
          {
            key: "CREATE_STATE",
            label: "Create state",
            path: "state",
          },
          {
            key: "CREATE_CITY",
            label: "Create city",
            path: "city",
          },
        ],
      },
      {
        label: <Text style={{ fontWeight: 600, color: "white" }}>Help</Text>,
        key: "CRUD_HELP",
        icon: <MdHelp />,
        className: styles.icon,
        path: "help",
      },
      {
        label: <Text style={{ fontWeight: 600, color: "white" }}>News</Text>,
        key: "CRUD_NEWS",
        icon: <FaNewspaper />,
        className: styles.icon,
        path: "news",
      },
      {
        label: <Text style={{ fontWeight: 600, color: "white" }}>Pets</Text>,
        key: "CRUD_PET_BREED",
        icon: <MdPets />,
        className: styles.icon,
        path: "pets",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Subscription Plans details
          </Text>
        ),
        key: "CREATE_PLAN",
        icon: <MdSubscriptions />,
        className: styles.icon,
        path: "plans",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Pending Plans
          </Text>
        ),
        key: "GET_ALL_PENDING_PLANS",
        icon: <MdSubscriptions />,
        className: styles.icon,
        path: "pendingPlans",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>Users</Text>
        ),
        key: "GET_ALL_USERS",
        icon: <LuUsers />,
        className: styles.icon,
        path: "users",
      },
    ],
    STATE_ADMIN: [
      {
        label: "Dashboard",
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Subscription Plans details
          </Text>
        ),
        key: "CREATE_PLAN",
        icon: <MdSubscriptions />,
        className: styles.icon,
        path: "plans",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Create city
          </Text>
        ),
        key: "CREATE_CITY",
        icon: <RiHome2Fill />,
        className: styles.icon,
        path: "city",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>Users</Text>
        ),
        key: "GET_ALL_USERS",
        icon: <LuUsers />,
        className: styles.icon,
        path: "users",
      },
    ],
    CITY_ADMIN: [
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>dashboard</Text>
        ),
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>
            Subscription Plans details
          </Text>
        ),
        key: "CREATE_PLAN",
        icon: <MdSubscriptions />,
        className: styles.icon,
        path: "plans",
      },
      {
        label: (
          <Text style={{ fontWeight: 600, color: "white" }}>Users</Text>
        ),
        key: "GET_ALL_USERS",
        icon: <LuUsers />,
        className: styles.icon,
        path: "users",
      },
    ],
  };

  const options = (role, access) => {
    if (!access) {
      return [];
    }

    const updatedOptionMenu = optionMenu[role]?.filter((e) => {
      return e?.key && access[e.key];
    });

    return updatedOptionMenu || [];
  };

  const menuOptions = options(role, access);

  return menuOptions.map((e) => {
    return {
      key: e.key,
      label: e.label,
      path: e.path,
      icon: e.icon,
      className: e.className,
      children: e.children,
    };
  });
};

export default OptionsComponent;
