export const bookAppointmentConstants = {
  MAP_TOKEN_SUCCESS: "MAP_TOKEN_SUCCESS",
  MAP_TOKEN_ERROR: "MAP_TOKEN_ERROR",
  MAP_VALUES_SUCCESS: "MAP_VALUES_SUCCESS",
  MAP_VALUES_ERROR: "MAP_VALUES_ERROR",
  SEARCH_RESET: "SEARCH_RESET",

  SELECT_SERVICES_SUCCESS: "SELECT_SERVICES_SUCCESS",
  SELECT_SERVICES_FAILURE: "SELECT_SERVICES_FAILURE",

  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  PAYMENT_FAILURE: "PAYMENT_FAILURE",

  PAYMENT_SUCCESS_BOOKING_ID: "PAYMENT_SUCCESS_BOOKING_ID",
  PAYMENT_FAILURE_BOOKING_ID: "PAYMENT_FAILURE_BOOKING_ID",

  UPDATE_DETAILS_SUCCESS: "UPDATE_DETAILS_SUCCESS",
  UPDATE_DETAILS_FAILURE: "UPDATE_DETAILS_FAILURE",

  GET_BOOKING_DETAILS_SUCCESS: "GET_BOOKING_DETAILS_SUCCESS",
  GET_BOOKING_DETAILS_ERROR: "GET_BOOKING_DETAILS_ERROR",

  GET_BOOKING_DETAILS_BY_ID_SUCCESS: "GET_BOOKING_DETAILS_BY_ID_SUCCESS",
  GET_BOOKING_DETAILS_BY_ID_ERROR: "GET_BOOKING_DETAILS_BY_ID_ERROR",

  UPDATE_SELECT_SERVICES_SUCCESS: "UPDATE_SELECT_SERVICES_SUCCESS",
  UPDATE_SELECT_SERVICES_FAILURE: "UPDATE_SELECT_SERVICES_FAILURE",

  GET_SLOT_DETAILS_SUCCESS: "GET_SLOT_DETAILS_SUCCESS",
  GET_SLOT_DETAILS_ERROR: "GET_SLOT_DETAILS_ERROR",

  RESET_DETAILSBYID: "RESET_DETAILSBYID",
  RESET_UPDATE_DETAILS: "RESET_UPDATE_DETAILS",

  REDEEM_OR_UNREDEEM_REWARDS_SUCCESS: "REDEEM_OR_UNREDEEM_REWARDS_SUCCESS",
  REDEEM_OR_UNREDEEM_REWARDS_ERROR: "REDEEM_OR_UNREDEEM_REWARDS_ERROR",
};
