import { Col, Collapse, Form, Row, Typography } from "antd";
import React, { useEffect } from "react";
import SearchComponent from "./SearchComponent";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Mapss from "../../Navbar/Components/Mapss";
import { useDispatch, useSelector } from "react-redux";
import AddressForm from "./AddressForm";
import { addressAction } from "../../Common/State/adressactions";
import { resetDrawer } from "../../state/actions";
import styles from "../Styles/AddressDrawer.module.css";
const { Text } = Typography;

const AddressDrawerBody = ({ handleCard, info, setInfo }) => {
  const { tokendata } = useSelector((str) => str.appReducer);
  const { option, editAddress } = useSelector((str) => str.loadingReducer);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { address, selectedAddress, showForm } = info;

  const handleAddressSelect = (item) => {
    if (item?.placeAddress) {
      let splitt_place_address = item?.placeAddress?.split(", ");
      if (item?.type) {
        setInfo((prev) => ({
          ...prev,
          selectedAddress: item?.placeAddress,
          address: {
            pincode:
              item?.type === "PINCODE"
                ? item?.placeName
                : item?.type === "CITY"
                ? ""
                : splitt_place_address[splitt_place_address.length - 1],
            eLoc: item?.eLoc,
            state:
              item?.type === "PINCODE"
                ? splitt_place_address[splitt_place_address.length - 1]
                : item?.type === "CITY"
                ? splitt_place_address[splitt_place_address.length - 1]
                : splitt_place_address[splitt_place_address.length - 2],
            city:
              item?.type === "CITY"
                ? item?.placeName
                : splitt_place_address[splitt_place_address.length - 3],
          },
        }));
      } else if (item.type === "" || !item?.type) {
        setInfo((prev) => ({
          ...prev,
          selectedAddress: item?.placeAddress,
          address: {
            pincode: item?.placeAddress.match(/\b\d{6}\b/)[0],
            eLoc: item?.eLoc,
          },
        }));
      }
    } else if (item?.data) {
      setInfo((prev) => ({
        ...prev,
        selectedAddress: item?.data?.formatted_address,
        address: item?.data,
      }));
    } else {
      setInfo((prev) => ({
        ...prev,
        selectedAddress:
          item?.formatted_address || item?.data?.formatted_address,
        address: item,
      }));
    }
  };

  const onFinish = (values) => {
    if (option === "edit" && editAddress) {
      dispatch(
        addressAction.putAddressesEvent(values, editAddress?.addressUniqueId)
      );
      dispatch(resetDrawer());
    } else if (option === "add") {
      dispatch(addressAction.addAddressEvent(values));
      dispatch(resetDrawer());
    } else {
      dispatch(addressAction.addAddressEvent(values));
    }
    setInfo((prev) => ({ ...prev, showForm: false }));
  };

  useEffect(() => {
    form.setFieldsValue({
      landmark: address?.subLocality || address?.landmark,
      houseNumber: address?.houseNumber,
      street: address?.street,
      houseName: address?.houseName,
      city: address?.city,
      district: address?.district,
      state: address?.state,
      eLoc: address?.eLoc,
      pincode: address?.pincode,
      latitude: address?.lat,
      longitude: address?.lng,
    });
  }, [address]);

  return (
    <Row>
      <Col span={24}>
        {!showForm ? (
          <SearchComponent setInfo={setInfo} handleCard={handleCard} />
        ) : (
          <>
            {option !== "edit" && (
              <Text className={styles.addLocation}>
                <ArrowLeftOutlined
                  onClick={() =>
                    setInfo((prev) => ({ ...prev, showForm: false }))
                  }
                />
                &nbsp; Add Location{" "}
              </Text>
            )}
            <Mapss
              address={address}
              tokendata={tokendata}
              handleAddressSelect={handleAddressSelect}
              selectedAddress={selectedAddress}
              height={"300px"}
            />
            <Text style={{ fontWeight: 500 }}>{selectedAddress}</Text>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Collapse
                defaultActiveKey={"1"}
                style={{ fontWeight: "600" }}
                items={[
                  {
                    key: "1",
                    label: "ADD MORE DETAILS",
                    showArrow: false,
                    children: (
                      <Form layout="vertical" form={form} onFinish={onFinish}>
                        <AddressForm />
                      </Form>
                    ),
                  },
                ]}
              />
            </div>
          </>
        )}
      </Col>
    </Row>
  );
};

export default AddressDrawerBody;
