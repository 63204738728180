import { newsConstants } from "./action-types";

const initialState = {
    addBlog: false,
    addBlogErr: false,
    allBlogs: false,
    allBlogsErr: false,
    updateBlog: false,
    updateBlogErr: false,
    getBlogById: false,
    getBlogByIdErr: false,
    totalBlogs: 0
};

const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case newsConstants.ADD_BLOG_SUCCESS:
            return {
                ...state,
                addBlog: action.data,
                addBlogErr: false
            }
        case newsConstants.ADD_BLOG_ERROR:
            return {
                ...state,
                addBlog: false,
                addBlogErr: action.valuesError
            }
        case newsConstants.GET_ALL_BLOGS_SUCCESS:
            return {
                ...state,
                allBlogs: action.data.currentPage === 1
                    ? action.data.docs
                    : [...state.allBlogs, ...action.data.docs],
                allBlogsErr: false,
                totalBlogs: action.data.totalDocs
            }
        case newsConstants.GET_ALL_BLOGS_ERROR:
            return {
                ...state,
                allBlogs: false,
                allBlogsErr: action.valuesError,
                totalBlogs: 0
            }
        case newsConstants.UPDATE_BLOG_SUCCESS:
            return {
                ...state,
                updateBlog: action.data,
                updateBlogErr: false
            }
        case newsConstants.UPDATE_BLOG_ERROR:
            return {
                ...state,
                updateBlog: false,
                updateBlogErr: action.valuesError
            }
        case newsConstants.GET_BLOG_BY_ID_SUCCESS:
            return {
                ...state,
                getBlogById: action.data,
                getBlogByIdErr: false,
            }
        case newsConstants.GET_BLOG_BY_ID_ERROR:
            return {
                ...state,
                getBlogById: false,
                getBlogByIdErr: action.valuesError,
            }
        case newsConstants.CLEAR_BLOGS:
            return {
                ...state,
                addBlog: false,
                addBlogErr: false,
                updateBlog: false,
                updateBlogErr: false,
                getBlogById: false,
                getBlogByIdErr: false,
            }
        default:
            return state;
    }
};
export { newsReducer };
