export const appconstants = {
    START_LOADING: "START_LOADING",
    STOP_LOADING: "STOP_LOADING",

    START_LOADING_ACTION_CENTER: "START_LOADING_ACTION_CENTER",
    STOP_LOADING_ACTION_CENTER: "STOP_LOADING_ACTION_CENTER",

    START_LOADING_FINALBOOKAPPOINTMENT_DETAILS: "START_LOADING_FINALBOOKAPPOINTMENT_DETAILS",
    STOP_LOADING_FINALBOOKAPPOINTMENT_DETAILS: "STOP_LOADING_FINALBOOKAPPOINTMENT_DETAILS",

    START_LOADING_MAPS: "START_LOADING_MAPS",
    STOP_LOADING_MAPS: "STOP_LOADING_MAPS",

    START_LOADING_ALL_ADDRESSES: "START_LOADING_ALL_ADDRESSES",
    STOP_LOADING_ALL_ADDRESSES: "STOP_LOADING_ALL_ADDRESSES",

    START_LOADING_BOARDING_CENTER: "START_LOADING_BOARDING_CENTER",
    STOP_LOADING_BOARDING_CENTER: "STOP_LOADING_BOARDING_CENTER",

    START_LOADING_USER_DETAILS: "START_LOADING_USER_DETAILS",
    STOP_LOADING_USER_DETAILS: "STOP_LOADING_USER_DETAILS",

    SET_NAME: "SET_NAME",
    RESET_NAME: "RESET_NAME",

    SET_DRAWER_OPEN: "SET_DRAWER_OPEN",
    RESET_DRAWER: "RESET_DRAWER",

    START_LOADING_BOOKING: "START_LOADING_BOOKING",
    STOP_LOADING_BOOKING: "STOP_LOADING_BOOKING",

    START_LOADING_UPDATED_SERVICES: "START_LOADING_UPDATED_SERVICES",
    STOP_LOADING_UPDATED_SERVICES:"STOP_LOADING_UPDATED_SERVICES",

    START_LOADING_SLOTS: "START_LOADING_SLOTS",
    STOP_LOADING_SLOTS: "STOP_LOADING_SLOTS",

    START_TRACKING_LOADING: "START_TRACKING_LOADING",
    STOP_LOADING_TRACKING: "STOP_LOADING_TRACKING",

    START_LOADING_NOTIFICATIONS: "START_LOADING_NOTIFICATIONS",
    STOP_LOADING_NOTIFICATIONS: "STOP_LOADING_NOTIFICATIONS",

    START_LOADING_REQUEST_OTP: "START_LOADING_REQUEST_OTP",
    STOP_LOADING_REQUEST_OTP: "STOP_LOADING_REQUEST_OTP",

    START_LOADING_GET_ALL_QUESTIONS: "START_LOADING_GET_ALL_QUESTIONS",
    STOP_LOADING_GET_ALL_QUESTIONS: "STOP_LOADING_GET_ALL_QUESTIONS",

    START_LOADING_QUESTION_BY_ID: "START_LOADING_QUESTION_BY_ID",
    STOP_LOADING_QUESTION_BY_ID: "STOP_LOADING_QUESTION_BY_ID",

    START_LOADING_VERIFY_OTP: "START_LOADING_VERIFY_OTP",
    STOP_LOADING_VERIFY_OTP: "STOP_LOADING_VERIFY_OTP",

    START_LOADING_CAPTAIN_DETAILS_EVENT: "START_LOADING_CAPTAIN_DETAILS_EVENT",
    STOP_LOADING_CAPTAIN_DETAILS_EVENT: 'STOP_LOADING_CAPTAIN_DETAILS_EVENT',

    START_LOADING_KENNELS: "START_LOADING_KENNELS",
    STOP_LOADING_KENNELS: "STOP_LOADING_KENNELS",

    START_LOADING_PARENT_BOARDING_CENTER_DETAILS: "START_LOADING_PARENT_BOARDING_CENTER_DETAILS",
    STOP_LOADING_PARENT_BOARDING_CENTER_DETAILS: "STOP_LOADING_PARENT_BOARDING_CENTER_DETAILS",

    START_LOADING_ALL_BOARD_CENTERS: "START_LOADING_ALL_BOARD_CENTERS",
    STOP_LOADING_ALL_BOARD_CENTERS: "STOP_LOADING_ALL_BOARD_CENTERS",

    START_LOADING_ALL_BOOKINGS: "START_LOADING_ALL_BOOKINGS",
    STOP_LOADING_ALL_BOOKINGS: "STOP_LOADING_ALL_BOOKINGS",

    START_LOADING_ALL_CONFIRMED_BOOKINGS: "START_LOADING_ALL_CONFIRMED_BOOKINGS",
    STOP_LOADING_ALL_CONFIRMED_BOOKINGS: "STOP_LOADING_ALL_CONFIRMED_BOOKINGS",

    START_LOADING_ALL_BOOKINGS_SUPPORT: "START_LOADING_ALL_BOOKINGS_SUPPORT",
    STOP_LOADING_ALL_BOOKINGS_SUPPORT: "STOP_LOADING_ALL_BOOKINGS_SUPPORT",

    START_LOADING_PET_DETAILS: " START_LOADING_PET_DETAILS",
    STOP_LOADING_PET_DETAILS: " STOP_LOADING_PET_DETAILS",

    START_LOADING_BILLING_SUB: "START_LOADING_BILLING_SUB",
    STOP_LOADING_BILLING_SUB: "STOP_LOADING_BILLING_SUB",

    START_LOADING_PAYMENT_LINKS: "START_LOADING_PAYMENT_LINKS",
    STOP_LOADING_PAYMENT_LINKS: "STOP_LOADING_PAYMENT_LINKS",

    START_LOADING_PARENT_DETAILS: "START_LOADING_PARENT_DETAILS",
    STOP_LOADING_PARENT_DETAILS: "STOP_LOADING_PARENT_DETAILS",

    START_LOADING_POST_UPDATE_PET_DETAILS: "START_LOADING_POST_UPDATE_PET_DETAILS",
    STOP_LOADING_POST_UPDATE_PET_DETAILS: "STOP_LOADING_POST_UPDATE_PET_DETAILS",

    START_LOADING_ALL_USERS:"START_LOADING_ALL_USERS",
    STOP_LOADING_ALL_USERS:"STOP_LOADING_ALL_USERS",
}