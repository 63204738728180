import React from "react";
import specialforyou from "../../Assets/Dashboard/Special for u.png";
import { Image } from "antd";

const SpecialforYou = () => {
  return (
    <>
      <Image src={specialforyou} alt="Special for you" preview={false} />
    </>
  );
};

export default SpecialforYou;
