import { Button, Card, Col, Row } from "antd";
import React, { useState } from "react";
import ResetPasswordForm from "../../Signup/Components/ResetPasswordForm";
import styles from "../Styles/settings.module.css";
import { settingsApi } from "../utils/api";
import useWarnings from "../../Common/Components/useWarnings";

const Passwords = () => {
  const { error, success, contextHolder } = useWarnings();
  const [loading, setLoading] = useState(false);

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const response = await settingsApi.setPassword(values?.password);
      if (response?.data?.info?.message) {
        success("Password created successfully");
      }
      setLoading(false);
    } catch (err) {
      error(err?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <Row className={styles.password}>
      {contextHolder}
      <Col xs={24} sm={24} md={24} lg={{ span: 16 }} xl={{ span: 12 }}>
        <ResetPasswordForm
          loading={loading}
          buttonName={"Create new password"}
          onFinish={handleFinish}
        />
      </Col>
    </Row>
  );
};

export default Passwords;
