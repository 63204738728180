import {
  startLoading,
  startLoadingAllQuestions,
  startLoadingQuestionById,
  stopLoading,
  stopLoadingAllQuestions,
  stopLoadingQuestionById,
} from "../../state/actions";
import { newsApi } from "../utils/api";
import { newsConstants } from "./action-types";

const addBlogSuccess = (data) => {
  return {
    type: newsConstants.ADD_BLOG_SUCCESS,
    data: data,
  };
};

const addBlogError = (err) => {
  return {
    type: newsConstants.ADD_BLOG_ERROR,
    valuesError: err,
  };
};

const addBlogEvent = (data) => {
  console.log(data, 'data')
  return (dispatch) => {
    dispatch(startLoading());
    newsApi
      .addBlog(data)
      .then((response) => {
        dispatch(addBlogSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        console.log(err?.response?.data?.message, 'err?.response?.data?.message')
        dispatch(addBlogError(err?.response?.data?.message));
        dispatch(stopLoading());
      });
  };
};

const getAllBlogsSuccess = (data) => {
  return {
    type: newsConstants.GET_ALL_BLOGS_SUCCESS,
    data: data,
  };
};

const getAllBlogsErr = (err) => {
  return {
    type: newsConstants.GET_ALL_BLOGS_ERROR,
    valuesError: err,
  };
};

const getAllBlogsEvent = (page) => {
  return (dispatch) => {
    dispatch(startLoadingAllQuestions());
    newsApi
      .getAllBlogs(page)
      .then((response) => {
        dispatch(getAllBlogsSuccess(response.data.info));
        dispatch(stopLoadingAllQuestions());
      })
      .catch((err) => {
        dispatch(getAllBlogsErr(err));
        dispatch(stopLoadingAllQuestions());
      });
  };
};

const updateBlogSuccess = (data) => {
  return {
    type: newsConstants.UPDATE_BLOG_SUCCESS,
    data: data,
  };
};

const updateBlogErr = (err) => {
  return {
    type: newsConstants.UPDATE_BLOG_ERROR,
    valuesError: err,
  };
};

const updateBlogByIdEvent = ( data, id) => {
  return (dispatch) => {
    dispatch(startLoading());
    newsApi
      .updateBlogById( data, id)
      .then((response) => {
        dispatch(updateBlogSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(updateBlogErr(err?.response?.data?.message));
        dispatch(stopLoading());
      });
  };
};

const getBlogByIdSuccess = (data) => {
  return {
    type: newsConstants.GET_BLOG_BY_ID_SUCCESS,
    data: data,
  };
};

const getBlogByIdErr = (err) => {
  return {
    type: newsConstants.GET_BLOG_BY_ID_ERROR,
    valuesError: err,
  };
};

const getBlogByIdEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoadingQuestionById());
    newsApi
      .getBlogById(data)
      .then((response) => {
        dispatch(getBlogByIdSuccess(response.data.info));
        dispatch(stopLoadingQuestionById());
      })
      .catch((err) => {
        dispatch(getBlogByIdErr(err));
        dispatch(stopLoadingQuestionById());
      });
  };
};

const clearBlog = () => {
  return {
      type: newsConstants.CLEAR_BLOGS,
  };
}


export const newsAction = {
  addBlogEvent,
  getAllBlogsEvent,
  updateBlogByIdEvent,
  getBlogByIdEvent,
  clearBlog
};
