import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { GlobalDecrypt } from "../../Common/GlobalDecrypt";

const apiURL = process.env.REACT_APP_BACKEND_URL;

// get bookappointment details
const getBookAppointmentDetails = (id) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/finalBookAppointmentDetails/${id}`, config);
};

// post select services
const selectServices = (data) => {
  const token = localStorage.getItem("authToken");
  const id = jwtDecode(token).id;
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.post(
    `${apiURL}/bookAppointment/selectServices/${id}`,
    data,
    config
  );
};

// get bookAppointment details based on booking id
const bookAppointmentDetailsById = async (id) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  let res = await axios.get(`${apiURL}/bookAppointmentDetails/${id}`, config);
  return res;
};

// update book appointment details based on booking id
const updateDetailsById = (data) => {
  const token = localStorage.getItem("authToken");
  const id = jwtDecode(token)?.subscriptionId
    ? jwtDecode(token)?.subscriptionId
    : "undefined";
  const bookingId = localStorage.getItem("bookingID");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.put(
    `${apiURL}/bookAppointment/subscribedService/${bookingId}/${id}`,
    data,
    config
  );
};
// update selected services
const updateSelectServices = (data, id) => {
  const token = localStorage.getItem("authToken");
  // const id = jwtDecode(token)?.bookingId
  //   ? jwtDecode(token)?.bookingId
  //   : "undefined";
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.put(
    `${apiURL}/bookAppointment/updateSelectServices/${id}`,
    data,
    config
  );
};

// make payment
const makePayment = (data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${apiURL}/makePayment`, data, config);
};

// make payent based on ID
const makePaymentBooking = (id) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${apiURL}/makePayment/${id}`, null, config);
};

// get slotDetailsBymonth
const getSlotDetailsByMonth = (data) => {
  const { date, serviceName } = data;
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(
    `${apiURL}/slotDetailsByMonth/${date}/${serviceName}`,
    config
  );
};

// get slotDetailsBymonth
const redeemOrUnredeemRewards = (data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.put(
    `${apiURL}/payment/redeem-or-unredeem-rewards`,
    data,
    config
  );
};

export const bookappointmentapi = {
  getBookAppointmentDetails,
  selectServices,
  bookAppointmentDetailsById,
  updateDetailsById,
  makePayment,
  updateSelectServices,
  getSlotDetailsByMonth,
  makePaymentBooking,
  redeemOrUnredeemRewards,
};
