import Chart from 'chart.js/auto';
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from '../Styles/dashboard.module.css';
import serviceHistoryName from '../../Assets/Dashboard/Service history.png';
import { Image, Card } from 'antd';
const ServiceHistoryLine = () => {
    const [mode, setMode] = useState("Yearly");
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState("Jan");
    const [serviceData, setServiceData] = useState([]);
    const [xValues, setXValues] = useState([]);
    const [selectedMonthlyYear, setSelectedMonthlyYear] = useState(new Date().getFullYear());
    const chartRef = useRef(null);

    useEffect(() => {
        if (mode === "Yearly") {
            setXValues(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);
        } else {
            const daysInMonth = {
                Jan: 31,
                Feb: selectedMonthlyYear % 4 === 0 && (selectedMonthlyYear % 100 !== 0 || selectedMonthlyYear % 400 === 0) ? 29 : 28,
                Mar: 31,
                Apr: 30,
                May: 31,
                Jun: 30,
                Jul: 31,
                Aug: 31,
                Sep: 30,
                Oct: 31,
                Nov: 30,
                Dec: 31
            };
            setXValues(Array.from({ length: daysInMonth[selectedMonth] }, (_, i) => i + 1));
        }
    }, [mode, selectedMonth, selectedMonthlyYear]);

    const configuration = useMemo(() => ({
        type: "line",
        data: {
            labels: xValues,
            datasets: [{
                fill: true,
                label: "Services",
                tension: 0,
                // backgroundColor: "rgb(255,126,0,0.3)",
                // borderColor: "rgba(0,0,255,0.1)",
                data: serviceData,
            }]
        }
    }), [xValues, serviceData]);

    useEffect(() => {
        // Replace this with actual API call to fetch service data
        const fetchServiceData = async () => {
            const mockServiceData = Array.from({ length: xValues.length }, () => Math.floor(Math.random() * 10));
            setServiceData(mockServiceData);
        };
        fetchServiceData();
    }, [mode, selectedMonth, selectedMonthlyYear, xValues.length]);

    useEffect(() => {
        if (chartRef.current !== null) {
            chartRef.current.destroy();
        }
        const ctx = document.getElementById("myChart").getContext("2d");
        chartRef.current = new Chart(ctx, configuration);
    }, [configuration]);

    const handleModeChange = (event) => {
        setMode(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(parseInt(event.target.value));
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleMonthlyYearChange = (event) => {
        setSelectedMonthlyYear(parseInt(event.target.value));
    };

    return (
        <Card hoverable className={styles.entireLinePage}>
            <div style={{justifyContent:"flex-end",display:"flex"}}>
                <div>
                    <select className={styles.select} id="mode" value={mode} onChange={handleModeChange}>
                        <option value="Yearly">Yearly</option>
                        <option value="Monthly">Monthly</option>
                    </select> &nbsp;
                </div>
                {mode === "Yearly" ? (
                    <div>
                        <select className={styles.select} id="year" value={selectedYear} onChange={handleYearChange}>
                            {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </select>&nbsp;
                    </div>
                ) : (
                    <div>
                        <select className={styles.select} id="monthlyYear" value={selectedMonthlyYear} onChange={handleMonthlyYearChange}>
                            {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </select>&nbsp;
                        <select className={styles.select} id="month" value={selectedMonth} onChange={handleMonthChange}>
                            {["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].map(month => (
                                <option key={month} value={month}>{month}</option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
            <canvas id="myChart" />
            <div className={styles.serviceHistoryName}>
                <Image src={serviceHistoryName} width={150} alt='Service History' preview={false} />
            </div>
        </Card>
    );
};

export default ServiceHistoryLine;
