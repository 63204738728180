import { Col, Empty, Row, theme, Typography } from "antd";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Slider from "react-slick";
import ActionCenterCard from "./ActionCenterCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import OtherActionCard from "./OtherActionCard";
import styles from "../Styles/actioncenter.module.css";
import {
  CaretLeftFilled,
  CaretRightFilled,
  LeftCircleTwoTone,
  LeftOutlined,
  RightCircleTwoTone,
  RightOutlined,
} from "@ant-design/icons";
import { SampleNextArrow, SamplePrevArrow } from "./Arrows";
const { Title } = Typography;

/**
 * @description
 * The `ActionCenter` component renders list of all the cards where the pending or continuation actions to be done
 * @param - no params needed
 */
const Actioncentre = memo(() => {
  const { actionCenterDetails } = useSelector((str) => str.dashboardReducer);
  const { t } = useTranslation("home");
  const { useToken } = theme;
  const { token } = useToken();

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 2.5,
    centerMode: true,
    autoplay: false,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    useCSS: true,
    // adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          centerMode: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1.2,
          centerMode: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    // <Card
    //   style={{ marginTop: "1.2em", marginBottom: "1.2em" }}
    //   id="actionCenter"
    // >
    <div style={{ marginBottom: "3em" }}>
      <Title level={3}>{t("action_center")}</Title>
      <Row gutter={{ xs: 0, sm: 0, md: [16, 0], lg: [16, 0], xl: [16, 0] }}>
        {actionCenterDetails?.bookings?.length === 0 &&
        !actionCenterDetails?.userAction ? (
          <Empty
            style={{ marginTop: "100px" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : actionCenterDetails === undefined ? (
          <Empty
            style={{ marginTop: "100px" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : actionCenterDetails?.bookings?.length > 2 ? (
          <Col key={uuidv4()}>
            <Slider {...settings} style={{ marginBottom: "20px" }}>
              {actionCenterDetails?.userAction?.actions?.length > 0 && (
                <OtherActionCard />
              )}
              {actionCenterDetails &&
                actionCenterDetails?.bookings?.map((action) => {
                  return (
                    <ActionCenterCard action={action} key={action?.bookingID} />
                  );
                })}
            </Slider>
          </Col>
        ) : actionCenterDetails?.bookings?.length < 3 ? (
          <>
            {actionCenterDetails?.userAction?.actions?.length > 0 && (
              <Col xs={24} sm={24} md={12} lg={12} xxl={8}>
                <OtherActionCard />
              </Col>
            )}
            {actionCenterDetails &&
              actionCenterDetails?.bookings?.map((action) => {
                return (
                  <Col
                    key={action?.bookingID}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xxl={8}
                  >
                    <ActionCenterCard action={action} />
                  </Col>
                );
              })}
          </>
        ) : (
          <Empty style={{ marginTop: "100px" }} />
        )}
      </Row>
    </div>
    // </Card>
  );
});

export default Actioncentre;
