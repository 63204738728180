import axios from "axios";
const apiURL = process.env.REACT_APP_BACKEND_URL;

// create a SUBSCRIPTION PLAN
const createPlan = async (data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return await axios.post(`${apiURL}/plans`, data, config);
};

// create a state
const createState = async (data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return await axios.post(`${apiURL}/state`, data, config);
};

// create a city
const createCity = async (data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return await axios.post(`${apiURL}/city`, data, config);
};

// get pending plans
const getPendingPlans = async () => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return await axios.get(`${apiURL}/plans/all_pending_plans`, config);
};

// put plan request by SA
const updatePlanRequest = async (planId, data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return await axios.put(`${apiURL}/plans/process_a_plan/${planId}`, data, config);
};

// get uncreated states
const getUncreatedStates = async (status) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return await axios.get(`${apiURL}/states?status=${status}`, config);
};

// get unassigned state admins
const getUnassignedStateAdmins = async () => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return await axios.get(`${apiURL}/state_admins`, config);
};

// get unassigned state admins
const getUnassignedCityAdmins = async () => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return await axios.get(`${apiURL}/city_admins`, config);
};


export const crudPlansApi = {
    createPlan,
    createState,
    getPendingPlans,
    createCity,
    updatePlanRequest,
    getUnassignedStateAdmins,
    getUncreatedStates,
    getUnassignedCityAdmins
};
