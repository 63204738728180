import {
  CaretRightOutlined,
  LeftCircleTwoTone,
  RightCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Image,
  Input,
  Row,
  Typography,
  theme,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchAction } from "./State/actions";
import Spinner from "../Spinner/Spinner";
import PetCard from "./Components/PetCard";
import ParentCard from "./Components/ParentCard";
import AddressCard from "./Components/AddressCard";
import { debounceFun } from "../Common/Components/DebounceSearchFun";
import useSettingName from "../Common/Components/useSettingName";
import styles from "./Styles/petcard.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { services } from "../Common/Components/Services";
import ActionCenterCard from "../Dashboard/Components/ActionCenterCard";
import {
  SampleNextArrow,
  SamplePrevArrow,
} from "../Dashboard/Components/Arrows";
import { profileAction } from "../Common/State/actions";

const { Title, Text } = Typography;

/**
 * Search component provides a search functionality with results categorized by type.
 * It includes a search input field and displays results based on the search query.
 *
 * @returns {JSX.Element} The rendered Search component.
 *
 * @description
 * - **Search Input**: An `Input` component with a search icon for querying search results.
 * - **Debounced Search**: Uses a debounced function (`debounceFun`) to trigger search actions after a delay.
 * - **Loading State**: Shows a spinner during the loading state.
 * - **Error Handling**: Displays an error message if search results fail to load.
 * - **No Results**: Displays a message if no results are found.
 */

const Search = () => {
  const dispatch = useDispatch();
  const { isLoading, isLoadingParentBoardingCenter } = useSelector(
    (str) => str.loadingReducer
  );
  const { searchResults, searchResultsErr } = useSelector(
    (str) => str.searchReducer
  );
  const { parentBoardingDetails } = useSelector((str) => str.profileReducer);
  const [inputValue, setInputValue] = useState("");
  const { useToken } = theme;
  const { token } = useToken();
  console.log(parentBoardingDetails, "parentBoardingDetails");

  // Memoize the debounced function to avoid re-creation on every render
  const debouncedSearch = useMemo(
    () =>
      debounceFun((value) => {
        dispatch(searchAction.searchEvent(value));
      }, 1000),
    [dispatch]
  );

  // useEffect to call the debounced function only when searchText changes
  useEffect(() => {
    if (inputValue?.trim()?.length > 0) {
      debouncedSearch(inputValue);
    } else {
      dispatch(searchAction.clearSearch());
    }
  }, [inputValue, debouncedSearch, dispatch]);

  const bookingActionSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    centerMode: true,
    autoplay: false,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    useCSS: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 572,
        settings: {
          centerMode: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const renderItems = (results) => {
    return results.map((e) => ({
      key: e.questionUniqueId,
      label: (
        <Typography.Text style={{ fontWeight: 500 }}>{e.title}</Typography.Text>
      ),
      children: (
        <p
          className={styles.descImg}
          dangerouslySetInnerHTML={{ __html: e.description?.['en'] }}
        />
      ),
    }));
  };

  const renderCategory = (category, results) => {
    if (category === "help") {
      return (
        <div key={category}>
          <Title level={4} style={{ marginTop: "1em" }}>
            {category.toUpperCase()}
          </Title>
          <Collapse
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            bordered={false}
            size="small"
            items={renderItems(results)}
          />
        </div>
      );
    }
    if (category === "pet") {
      return (
        <>
          <Title level={4} style={{ marginTop: "1em" }}>
            {category.toUpperCase()}
          </Title>
          {results?.map((e) => (
            <PetCard e={e} />
          ))}
        </>
      );
    }
    if (category === "user") {
      return (
        <>
          <Title level={4} style={{ marginTop: "1em" }}>
            {category.toUpperCase()}
          </Title>
          {results?.map((e) => (
            <ParentCard e={e} />
          ))}
        </>
      );
    }
    if (category === "address") {
      return (
        <>
          <Title level={4} style={{ marginTop: "1em" }}>
            {category.toUpperCase()}
          </Title>
          <AddressCard e={results} />
        </>
      );
    }
    if (category === "bookings") {
      return (
        <>
          <Title level={4} style={{ marginTop: "1em" }}>
            {category.toUpperCase()}
          </Title>
          {results?.length > 1 ? (
            <Slider {...bookingActionSettings} style={{ marginBottom: "20px" }}>
              {results?.map((action) => (
                <ActionCenterCard action={action} key={action?.bookingID} />
              ))}
            </Slider>
          ) : (
            results?.map((action) => <ActionCenterCard action={action} />)
          )}
        </>
      );
    }
  };

  useSettingName("Search");

  useEffect(() => {
    return () => {
      dispatch(searchAction.clearSearch());
    };
  }, []);

  useEffect(() => {
    dispatch(profileAction.parentBoardingDetailsEvent());
  }, []);

  const handleClick = (service) => setInputValue(service.name);

  if (isLoadingParentBoardingCenter) return <Spinner />;

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: parentBoardingDetails?.services?.length < 7 ? parentBoardingDetails?.services?.length : 6,
    centerMode: parentBoardingDetails?.services?.length < 7 ? false : true,
    autoplay: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    useCSS: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
        breakpoint: 768,
        settings: {
          slidesToShow: 6,
        },
        breakpoint: 640,
        settings: {
          slidesToShow: 5,
        },
        breakpoint: 585,
        settings: {
          slidesToShow: 4,
        },
        breakpoint: 460,
        settings: {
          slidesToShow: 3,
        },
        // breakpoint: 375,
        // settings: {
        //     slidesToShow: 2,
        //     centerMode: true,
        // },
      },
    ],
  };

  return (
    <Row>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 20, offset: 2 }}
        lg={{ span: 18, offset: 3 }}
        xl={{ span: 12, offset: 6 }}
      >
        <Input
          prefix={<SearchOutlined style={{ color: token.GreyShadeLight }} />}
          allowClear
          onClear={() => dispatch(searchAction.clearSearch())}
          autoFocus
          size="large"
          placeholder="How can we help?"
          value={inputValue} // Bind input to inputValue state
          onChange={(e) => setInputValue(e.target.value)}
        />
      </Col>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 20, offset: 2 }}
        lg={{ span: 18, offset: 3 }}
        xl={{ span: 12, offset: 6 }}
        style={{
          marginTop: "1.2em",
          display:
            inputValue || (searchResults && searchResults?.length > 0)
              ? "none"
              : "",
        }}
      >
        <Title style={{ fontWeight: 600, fontSize: `clamp(1em, 3vw, 1.8em` }}>
          Popular Services
        </Title>
        <Slider {...settings}>
          {parentBoardingDetails?.services?.length > 0
            ? parentBoardingDetails?.services?.map((e) => {
              const findservice = services?.find(ser => ser.key === e?.serviceName)
              return (
                <>
                  <div
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={() => handleClick(findservice)}
                  >
                    <Image
                      width={75}
                      preview={false}
                      src={findservice.image}
                      alt={findservice?.name}
                    />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Text
                      style={{
                        // display: "block",
                        fontSize: `clamp(0.6em, 3vw, 0.7em)`,
                        fontWeight: 500,
                      }}
                    >
                      {findservice?.name}
                    </Text>
                  </div>
                </>
              );
            })
            : services?.map((e) => (
              <>
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => handleClick(e)}
                >
                  <Image
                    width={75}
                    preview={false}
                    src={e.image}
                    alt={e?.name}
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{
                      // display: "block",
                      fontSize: `clamp(0.6em, 3vw, 0.7em)`,
                      fontWeight: 500,
                    }}
                  >
                    {e?.name}
                  </Text>
                </div>
              </>
            ))}
        </Slider>
      </Col>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 20, offset: 2 }}
        lg={{ span: 18, offset: 3 }}
        xl={{ span: 12, offset: 6 }}
        style={{ height: window.innerHeight - 160, overflow: "scroll" }}
      >
        {isLoading ? (
          <Spinner />
        ) : searchResults && searchResults?.length > 0 ? (
          searchResults?.map((result) => {
            const [category, results] = Object.entries(result)[0];
            return renderCategory(category, results);
          })
        ) : searchResultsErr ? (
          <Title level={3}>{searchResultsErr}</Title>
        ) : searchResults && searchResults.length === 0 ? (
          <Text>No results found!!</Text>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};

export { Search };
