import { Spin } from "antd";
import React from "react";
import Lottie from "lottie-react";
// import loaderAnimation from './loader.json';

const Spinner = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70vh",
      }}
    >
      <Spin size="small" />
      {/* <Lottie animationData={loaderAnimation} style={{ width: 150, height: 150 }} autoPlay loop={true} /> */}
    </div>

  );
};

export default Spinner;
