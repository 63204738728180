import React, { useEffect, useState } from "react";
import { Col, FloatButton, Row } from "antd";
import ServiceHistoryBarChart from "./Components/ServiceHistoryBarChart";
import TodayHistory from "./Components/TodayHistory";
import RecentServices from "./Components/RecentServices";
import BoardcenterDetails from "./Components/BoardcenterDetails";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CaptainDetail from "./Components/CaptainDetail";
import { useRoute } from "../context/RouteContext";
import { CustomerServiceOutlined } from "@ant-design/icons";
import SupportForm from "../Support/Components/SupportForm";
import CommonModal from "../CommonModal/CommonModal";
import { supportAction } from "../Support/State/actions";
import UserHome from "./Components/UserHome";
import AdminHome from "./Components/AdminHome";
import Spinner from "../Spinner/Spinner";
import { dashboardAction } from "./State/actions";
import DecodeToken from "../Common/Components/DecodeToken";
import useWarnings from "../Common/Components/useWarnings";

const Dashboard = () => {
  const { alladdress } = useSelector((str) => str.addressReducer);
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const {error, contextHolder} = useWarnings()
  const {
    isLoadingAddresses,
    isLoadingUserDetails,
    isLoadingActionCenter,
  } = useSelector((str) => str.loadingReducer);
  const navigate = useNavigate();
  const { updatedboardingcenter } = useSelector((str) => str.addressReducer);
  const { setRouteKey, setSelectedKey } = useRoute();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { createTicket, createTicketErr } = useSelector(
    (str) => str.supportReducer
  );

  const handleModalOpen = () => {
    setVisible(true);
    dispatch(supportAction.getAllBookingsOfUserEvent());
  };
  const handleModalClose = () => setVisible(false);

  useEffect(() => {
    setRouteKey("home");
    setSelectedKey("DASHBOARD");
  }, []);

  useEffect(() => {
    if (!isLoadingAddresses && alladdress === false) {
      navigate("/addAddress");
    }
  }, [alladdress, isLoadingAddresses]);

  useEffect(() => {
    if (DecodeToken(localStorage.getItem("authToken")) === "USER")
      dispatch(dashboardAction.getActionCenterDetailsEvent());
    // eslint-disable-next-line
  }, [updatedboardingcenter]);

  useEffect(() => {
    if (createTicketErr) {
      error(createTicketErr);
      setVisible(false);
      dispatch(supportAction.clearData());
    } else if (createTicket) {
      setVisible(false);
      dispatch(supportAction.clearData());
    }
  }, [createTicket, createTicketErr]);

  if (isLoadingUserDetails || isLoadingActionCenter) return <Spinner />;

  return (
    <>
      {userDetails?.currentRole === "USER" && (
        <>
        {contextHolder}
          <UserHome />
          <FloatButton.Group
            icon={<CustomerServiceOutlined />}
            trigger="click"
            onClick={handleModalOpen}
          />
          {visible && (
            <CommonModal
              isModalOpen={visible}
              width={700}
              height={window.innerHeight * 0.8}
              handleCancel={handleModalClose}
              body={() => <SupportForm setVisible={setVisible} />}
              closeIcon={true}
            />
          )}
        </>
      )}
      {(userDetails?.currentRole === "ADMIN" ||
        userDetails?.currentRole === "SUPER_ADMIN") && <AdminHome />}
      {userDetails?.currentRole === "SUPPORT" && (
        <>
          <Row gutter={[32, 16]}>
            <Col xs={24} sm={24} md={24} lg={14} xl={16}>
              <ServiceHistoryBarChart />
            </Col>
            <Col xs={24} sm={24} md={12} lg={10} xl={8}>
              <TodayHistory />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <RecentServices />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <BoardcenterDetails />
            </Col>
          </Row>
        </>
      )}
      {userDetails?.currentRole === "CAPTAIN" && (
        <>
          <Row gutter={[32, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <CaptainDetail />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export { Dashboard };
